import { Link, Stack, styled, Typography } from '@mui/material';

import { type FunctionRouter, useFunctionsClient } from '@@api/services/functions/client';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Image from '@@components/Image';
import Loader from '@@components/Loader';
import Spacer from '@@components/Spacer';
import { PREVIEW_IMAGE_WIDTH } from '@@containers/ContentItem/styles';

const LoaderWrapper = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const StyledImage = styled(Image)(({ theme }) => ({
    width: `${PREVIEW_IMAGE_WIDTH}px`,
    objectFit: 'cover',
    marginRight: theme.spacing(2),
}));

type Props = {
    url: string;
};

const ExternalLinkPreview: React.FC<Props> = ({ url }) => {
    const { client: functionsClient, queryKeys: functionsKeys } = useFunctionsClient();

    const query = getQueryParams<FunctionRouter['checkUrlPreview']>({
        url,
    });
    const { data: urlPreviewData, isLoading: isExternalLinkDataLoading } =
        functionsClient.checkUrlPreview.useQuery({
            queryKey: functionsKeys.checkUrlPreview({ query }),
            queryData: { query },
            enabled: Boolean(url),
        });

    const externalLinkData = urlPreviewData?.body;

    if (isExternalLinkDataLoading) {
        return (
            <LoaderWrapper justifyContent="center" alignItems="center">
                <Loader />
            </LoaderWrapper>
        );
    }

    if (url && externalLinkData) {
        const { title, previewImageUrl } = externalLinkData;

        return (
            <Stack direction="row">
                <StyledImage src={previewImageUrl} />

                <Stack>
                    <Link
                        href={url}
                        target="_blank"
                        color="text.secondary"
                        underline="always"
                        variant="small"
                        display="block"
                        rel="noreferrer"
                        // link does not work correctly without it
                        component="a"
                    >
                        {url}
                    </Link>

                    <Spacer v xs />

                    <Typography variant="small" color="text.primary" component="p">
                        {title}
                    </Typography>

                    <Spacer v md />
                </Stack>
            </Stack>
        );
    }
};

export default ExternalLinkPreview;
