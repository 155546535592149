import { Stack, Typography } from '@mui/material';

import Icon from '@@components/Icon';
import Loader from '@@components/Loader';
import Spacer from '@@components/Spacer';

type Props = {
    isLoading: boolean;
    title: string | undefined;
    label: string;
    iconName: string;
};

const BasicLinkPreview = ({ isLoading, title, label, iconName }: Props) => {
    if (isLoading) {
        return (
            <>
                <Spacer v xs />
                <Loader />
                <Spacer v xs />
            </>
        );
    }

    return (
        <>
            <Typography fontWeight="bold" color="text.primary">
                {title}
            </Typography>

            <Spacer v xs />

            <Stack direction="row" alignItems="center">
                <Icon name={iconName} color="text.secondary" />
                <Spacer h sm />
                <Typography color="text.secondary">{label}</Typography>
            </Stack>
        </>
    );
};

export default BasicLinkPreview;
