import { styled } from '@mui/material';
import { type ReactNode } from 'react';

import { type Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type EmbedElement } from '@@editor/helpers/Element';

import { getInputPlaceholderStyle } from '../styles';

type Props = {
    attributes: ElementAttributes;
    children: ReactNode;
    editor: Editor;
    element: EmbedElement;
};

type WrapperProps = {
    $templateElement: boolean;
    $placeholderContent: string;
    $readOnly: boolean;
};

const Wrapper = styled('div')<WrapperProps>(
    ({ $templateElement, $placeholderContent, $readOnly, theme }) => ({
        ...theme.fixed.editor.summary.font,
        paddingRight: theme.spacing(3),
        ...($templateElement && {
            ...getInputPlaceholderStyle({
                theme,
                placeholderText: $placeholderContent,
                readOnly: $readOnly,
            }),
            '&&::before': {
                whiteSpace: 'pre',
                paddingLeft: theme.spacing(3),
            },
            '> ul': {
                listStyle: 'none',
                height: '100px',
            },
        }),
    }),
);

const placeholderListItems = [
    'Lorem ipsum dolor sit amet.',
    'Praesent mattis at libero non convallis.',
    'Sed tempor enim et efficitur egestas.',
];

const placeholderContent = placeholderListItems
    .map((item) => `\u00A0•\u00A0\u00A0\u00A0${item}`)
    .join('\\A');

const SummaryList = (props: Props) => {
    const { attributes, children, editor, element } = props;
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);
    const readOnly = ReactEditor.isReadOnly(editor);
    const templateElement =
        Element.isTemplateElement(parentNode) || Element.isEmptySummaryListSummaryElement(element);

    return (
        <Wrapper
            {...attributes}
            $templateElement={templateElement}
            $placeholderContent={placeholderContent}
            $readOnly={readOnly}
        >
            {children}
        </Wrapper>
    );
};

export default SummaryList;
