import { mapValues } from 'lodash-es';

import { setArrayParam } from '@@router/urlSearchParamsUtils';
import { getFiltersSetting } from '@@settings/settingsSlice';
import { useSelector } from '@@store/hooks';

import { type SearchNamespace } from './types';

const useSavedFiltersSearchParams = (searchNamespace: SearchNamespace) => {
    const storedSearchParams = useSelector((state) => getFiltersSetting(state, searchNamespace));

    const searchParams = new URLSearchParams();

    mapValues(storedSearchParams, (value, key) => {
        if (Array.isArray(value)) {
            setArrayParam(searchParams, key, value.map(String), 'append');
        } else if (value) {
            searchParams.append(key, value);
        }
    });

    return searchParams;
};

export default useSavedFiltersSearchParams;
