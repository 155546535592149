import { useContext } from 'react';

import { CONTENT_LANGUAGE_HEADER } from '@@api/constants/headers';
import { type TenantRouter, useTenantClient } from '@@api/services/tenant/client';
import { type Tenant } from '@@api/services/tenant/schemas';
import { type RequestQuery } from '@@api/types';
import ContentLocaleContext from '@@containers/contentLocale/ContentLocaleContext';
import { sunsettingTenants } from '@@containers/TenantSpecific/constants';
import { TenantsParamsContext } from '@@containers/TenantSpecific/TenantsParamsContext';
import { getContentLocaleSetting } from '@@scripts/settings/settingsSlice';
import { useSelector } from '@@scripts/store/hooks';
import { AUDIENCE_TYPE } from '@@utils/enums/tenants';

export type Props = {
    params?: RequestQuery<TenantRouter['tenant']['getAll']>;
    isFullList?: boolean;
    excludeFreeMedia?: boolean;
    excludeSunsettingTenants?: boolean;
};

export const useTenants = ({
    params,
    isFullList,
    excludeFreeMedia = false,
    excludeSunsettingTenants = false,
}: Props = {}) => {
    const tenantParams = useContext(TenantsParamsContext);
    const contentLocaleContext = useContext(ContentLocaleContext);
    const contentLocaleSetting = useSelector(getContentLocaleSetting);
    const { client: tenantClient, queryKeys: tenantKeys } = useTenantClient();

    const query = isFullList ? params : { ...tenantParams, ...params };
    const headers = {
        [CONTENT_LANGUAGE_HEADER]:
            query?.contentLocale || contentLocaleContext || contentLocaleSetting,
    };

    const restQuery = tenantClient.tenant.getAll.useQuery({
        queryKey: tenantKeys.tenant.getAll({ query: query || {}, headers }),
        queryData: { query, headers },
        staleTime: Infinity,
    });

    const data = restQuery.data?.body;

    const queryInfo = {
        ...restQuery,
        data: data?.filter((tenant) => {
            if (excludeFreeMedia && tenant.audienceType === AUDIENCE_TYPE.FREE) {
                return false;
            }
            if (excludeSunsettingTenants && sunsettingTenants.includes(tenant.id)) {
                return false;
            }
            return true;
        }),
    };

    const { data: tenants } = queryInfo;

    const ids: Tenant['id'][] | undefined = tenants?.map((tenant) => tenant.id);

    const isFreeMedia = (id) =>
        tenants?.find((tenant) => tenant.id === id)?.audienceType === AUDIENCE_TYPE.FREE;

    return { ...queryInfo, ids, isFreeMedia };
};

export default useTenants;
