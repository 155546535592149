import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import RelativeBadge from '@@components/RelativeBadge';
import Spacer from '@@components/Spacer';

export const TAB_NAME = {
    ACTIVE: 'active',
    ALL: 'all',
    DESKNET: 'syncedWithDesknet',
} as const;

export type TabName = (typeof TAB_NAME)[keyof typeof TAB_NAME];

type Props = {
    selectedTab: TabName;
    setSelectedTab: (tab: TabName) => void;
    countPerTab: Record<TabName, number | string>;
};

const CategoryTabs = ({ selectedTab, setSelectedTab, countPerTab }: Props) => {
    const { t } = useTranslation();

    return (
        <Tabs value={selectedTab}>
            {Object.values(TAB_NAME).map((tab) => (
                <Tab
                    key={tab}
                    value={tab}
                    label={
                        <>
                            {/* 
                                t('category.list.tab.active')
                                t('category.list.tab.all')
                                t('category.list.tab.syncedWithDesknet')
                             */}
                            {t(`category.list.tab.${tab}`)}
                            <Spacer sm h />
                            <RelativeBadge
                                badgeContent={countPerTab[tab]}
                                color="primary"
                                max={99999}
                            />
                        </>
                    }
                    onClick={() => setSelectedTab(tab)}
                    iconPosition="end"
                />
            ))}
        </Tabs>
    );
};

export default CategoryTabs;
