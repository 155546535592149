import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { type Metadata } from '@@api/services/metadata/schemas';
import Spacer from '@@components/Spacer';
import config from '@@config';
import {
    ContentWrapper,
    InfoBar,
    StyledIcon,
    Title,
    TitleHeader,
} from '@@containers/ContentItem/styles';
import { type Teaser } from '@@containers/Teaser/types';
import DateTime from '@@lib/dateTime/DateTime';

const defaultGetDate = (entity) => entity.updatedAt || entity.createdAt;

type Props = {
    entity: Metadata & Teaser;
    datePrefix?: string;
    reducedOpacity?: boolean;
    date?: string;
    className?: string;
    renderDate?: () => ReactNode;
    small?: boolean;
};

const ContentDetails = ({
    entity,
    reducedOpacity,
    date,
    datePrefix,
    renderDate,
    className,
    small,
}: Props) => {
    const { t } = useTranslation();

    const iconName = entity.resourceName ? config.contentTypeToIconName[entity.resourceName] : null;

    return (
        <ContentWrapper className={className}>
            <TitleHeader>{entity.titleHeader}</TitleHeader>

            <Title $small={small} $reducedOpacity={reducedOpacity}>
                {/* We use \u00A0 as a spacing so that the height stays when the string is empty */}
                {entity.title || '\u00A0'}
            </Title>

            {small ? <Spacer xs v /> : <Spacer sm v />}

            <InfoBar>
                {iconName && <StyledIcon name={iconName} />}

                {renderDate ? (
                    renderDate()
                ) : (
                    <>
                        <span>{datePrefix || t('contentitem.datePrefix.default')}&nbsp;</span>
                        <DateTime
                            date={date || defaultGetDate(entity)}
                            type="past"
                            titlePrefix={t('contentitem.publishedTimeago')}
                            fullDatePrefix={small ? '' : t('contentitem.fullDatePrefix.default')}
                        />
                    </>
                )}
            </InfoBar>
        </ContentWrapper>
    );
};

export default ContentDetails;
