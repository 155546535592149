import { useRtElementsClient } from '@@api/services/rtelements/client';
import { type Editor, ReactEditor } from '@@editor/helpers';
import { type EmbeddedSnippetElement, MIME_TYPES } from '@@editor/helpers/Element';
import Snippet from '@@editor/plugins/serializable/snippet/Snippet';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import EmbedWrapper from '../../components/EmbedWrapper';
import { TYPE } from '../constants';
import { getEmbeddedComponentId } from '../utils';

type Props = {
    editor: Editor;
    element: EmbeddedSnippetElement;
};

const Component = (props) => {
    const id = getEmbeddedComponentId(props.element.data);
    const params = { id };
    const { client: rtElementsClient, queryKeys: rtElementsKeys } = useRtElementsClient();
    const { data: rtElementData } = rtElementsClient.rtElements.get.useQuery({
        queryKey: rtElementsKeys.rtElements.get({ params }),
        queryData: { params },
        enabled: Boolean(id),
    });

    const element = rtElementData?.body?.element[0];

    return element ? <Snippet {...props} element={element} /> : null;
};

const mimetypeConfigs = {
    [MIME_TYPES.EMBEDDED_SNIPPET]: {
        Component,
    },
};

const EmbeddedSnippetNode = (props: Props) => {
    const { editor, element } = props;
    const path = ReactEditor.findPath(editor, element);

    return (
        <EmbedWrapper
            {...props}
            type={TYPE}
            mimetypeConfigs={mimetypeConfigs}
            toolbarConfig={{
                infos: {
                    iconName: PLUGIN_ICON_NAMES[TYPE],
                    title: props.editor.t('editor.plugin.snippet.frame.title'),
                },
                actions: [
                    {
                        type: 'edit',
                        iconName: 'gear',
                        onClick: (e) => {
                            e.preventDefault();

                            requestAnimationFrame(() =>
                                editor.showEmbedModal(
                                    PLUGIN_NAMES.EMBEDDED_COMPONENT,
                                    element,
                                    path,
                                ),
                            );
                        },
                    },
                    'delete',
                ],
            }}
        />
    );
};

export default EmbeddedSnippetNode;
