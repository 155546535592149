import { useContext } from 'react';
import { UNSAFE_RouteContext as RouteContext } from 'react-router';

export const useMatchUrl = () => {
    const routeContext = useContext(RouteContext);

    const { matches } = routeContext;

    const match = matches[matches.length - 1];

    return match ? match.pathnameBase : '';
};
