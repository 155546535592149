/*
 * HOW-TO ADD ICONS
 *
 * Important! Read this before adding an icon!
 *
 * 1. We want to avoid having a lot of custom icons (use what fontawesome offers; also consider combining
 *    fontawesome icons by creating stacked icons!)
 *
 * 2. We want to avoid having duplicate icons (check if an icon is already imported)
 *
 * 3. We want to avoid using too many variations for the same icon (check what styles/families of an icon are
 *    already imported and ask yourself if we can re-use those styles! the value of having less variations might
 *    be higher than having THE perfect icon, but of course it still should look good)
 *
 * 4. We want to avoid import unused icons (delete unused icons proactively)
 *
 *
 * Adding fontawesome icons
 *
 * Just add another require statement to the `FONTAWESOME_ICONS` array. That's it! You can already use your icon (by
 * making use of the `Icon` component).
 *
 *
 * Adding custom icons
 *
 * We want to have as little as possible custom icons. Challenge the idea of adding another custom icon before
 * doing so. Also consider combining fontawesome icons by creating stacked icons! If you are still convinced,
 * you need to add another custom icon, make sure to simultaneously file an icon request on the fontawesome
 * github repository (in order the get this icon added to the fontawesome library, so we can remove that custom
 * icon again!).
 *
 * To add a custom icon, just drop the svg file into the `app/images/icons` folder. You'll be able to import it by
 * adding it to the `FONTAWESOME_ICONS` array, using the `requireCustomIcon` function.
 *
 * IMPORTANT! The fontawesome library only understands very simple icons. It will use the `viewBox`, defined on the
 * root `svg` element and the first `path` of your svg file. Nothing else! Therefore custom svg icons must only
 * contain 1 element called `path` (besides the root `svg` element). Take an example of already existing custom icons!
 * If you have more complex svg files, you can easily merge different layers and paths with very basic svg tools
 * like `Inkscape` or ask the designer to do so.
 *
 *
 * Naming *
 *
 * Please study the fontawesome docs to understand how icons are named. The names of custom icons must follow the
 * same naming principles!
 *
 */

import {
    type IconDefinition,
    type IconLookup,
    type IconName,
    type IconPrefix,
} from '@fortawesome/fontawesome-svg-core';
import {
    faFacebook as freeBrandFacebook,
    faInstagram as freeBrandInstagram,
    faThreads as freeBrandThreads,
    faTiktok as freeBrandTiktok,
    faXTwitter as freeBrandXTwitter,
    faYoutube as freeBrandYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
    faFaceFrownSlight as proLightFaceFrownSlight,
    faHandFist as proLightHandFist,
    faHandPointUp as proLightHandPointUp,
} from '@fortawesome/pro-light-svg-icons';
import {
    faBasketball as proRegularBasketball,
    faCalendarDays as proRegularCalendarDays,
    faCardsBlank as proRegularCardsBlank,
    faChartNetwork as proRegularChartNetwork,
    faCircleXmark as proRegularCircleXmark,
    faClipboard as proRegularClipboard,
    faClone as proRegularClone,
    faCopy as proRegularCopy,
    faExpand as proRegularExpand,
    faEye as proRegularEye,
    faEyeSlash as proRegularEyeSlash,
    faFileCirclePlus as proRegularFileCirclePlus,
    faGrid2 as proRegularGrid2,
    faImage as proRegularImage,
    faImages as proRegularImages,
    faLanguage as proRegularLanguage,
    faMagnifyingGlassPlus as proRegularMagnifyingGlassPlus,
    faMessage as proRegularMessage,
    faNoteSticky as proRegularNoteSticky,
    faPen as proRegularPen,
    faRectangle as proRegularRectangle,
    faRobot as proRegularRobot,
    faSquare as proRegularSquare,
    faSquarePlus as proRegularSquarePlus,
} from '@fortawesome/pro-regular-svg-icons';
import {
    fa1 as proSolid1,
    faAlignLeft as proSolidAlignLeft,
    faArrowsDownToLine as proSolidArrowsDownToLine,
    faArrowUpRightFromSquare as proSolidArrowUpRightFromSquare,
    faBan as proSolidBan,
    faBoltLightning as proSolidBoltLightning,
    faCheck as proSolidCheck,
    faCircle as proSolidCircle,
    faCircleInfo as proSolidCircleInfo,
    faClipboardList as proSolidClipboardList,
    faCode as proSolidCode,
    faCurlingStone as proSolidCurlingStone,
    faEllipsis as proSolidEllipsis,
    faEllipsisVertical as proSolidEllipsisVertical,
    faEnvelope as proSolidEnvelope,
    faExclamation as proSolidExclamation,
    faEye as proSolidEye,
    faEyeSlash as proSolidEyeSlash,
    faF as proSolidF,
    faFaceFrownSlight as proSolidFaceFrownSlight,
    faFaceSadCry as proSolidFaceSadCry,
    faFaceSadTear as proSolidFaceSadTear,
    faFaceSmile as proSolidFaceSmile,
    faFlag as proSolidFlag,
    faFutbol as proSolidFutbol,
    faGear as proSolidGear,
    faGoalNet as proSolidGoalNet,
    faGolfFlagHole as proSolidGolfFlagHole,
    faGripDotsVertical as proSolidGripDotsVertical,
    faGripLines as proSolidGripLines,
    faHighlighter as proSolidHighlighter,
    faHockeySticks as proSolidHockeySticks,
    faHorse as proSolidHorse,
    faHourglassHalf as proSolidHourglassHalf,
    faInbox as proSolidInbox,
    faLock as proSolidLock,
    faMagnifyingGlass as proSolidMagnifyingGlass,
    faMinus as proSolidMinus,
    faPaperclip as proSolidPaperclip,
    faPaperPlane as proSolidPaperPlane,
    faPen as proSolidPen,
    faPenClip as proSolidPenClip,
    faPersonBiking as proSolidPersonBiking,
    faPersonRunning as proSolidPersonRunning,
    faPersonSkating as proSolidPersonSkating,
    faPersonSkiing as proSolidPersonSkiing,
    faPersonSkiingNordic as proSolidPersonSkiingNordic,
    faPersonSnowboarding as proSolidPersonSnowboarding,
    faPersonSwimming as proSolidPersonSwimming,
    faPlay as proSolidPlay,
    faPlug as proSolidPlug,
    faPuzzlePiece as proSolidPuzzlePiece,
    faPuzzlePieceSimple as proSolidPuzzlePieceSimple,
    faQuoteRight as proSolidQuoteRight,
    faRacquet as proSolidRacquet,
    faRotate as proSolidRotate,
    faScrewdriverWrench as proSolidScrewdriverWrench,
    faSitemap as proSolidSitemap,
    faSpellCheck as proSolidSpellCheck,
    faSquareInfo as proSolidSquareInfo,
    faSquarePollVertical as proSolidSquarePollVertical,
    faStar as proSolidStar,
    faSwords as proSolidSwords,
    faTag as proSolidTag,
    faTags as proSolidTags,
    faTennisBall as proSolidTennisBall,
    faThumbsDown as proSolidThumbsDown,
    faThumbsUp as proSolidThumbsUp,
    faThumbtack as proSolidThumbtack,
    faTrashCan as proSolidTrashCan,
    faTriangleExclamation as proSolidTriangleExclamation,
    faTrophy as proSolidTrophy,
    faUpRightAndDownLeftFromCenter as proSolidUpRightAndDownLeftFromCenter,
    faUser as proSolidUser,
    faUsers as proSolidUsers,
    faUsersGear as proSolidUsersGear,
    faVolleyball as proSolidVolleyball,
    faWater as proSolidWater,
    faWindowMinimize as proSolidWindowMinimize,
    faXmark as proSolidXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faCopy as sharpLightCopy } from '@fortawesome/sharp-light-svg-icons';
import {
    faArrowsRepeat as sharpRegularArrowsRepeat,
    faBold as sharpRegularBold,
    faCheck as sharpRegularCheck,
    faChevronRight as sharpRegularChevronRight,
    faClock as sharpRegularClock,
    faCode as sharpRegularCode,
    faCommentLines as sharpRegularCommentLines,
    faDollarSign as sharpRegularDollarSign,
    faHyphen as sharpRegularHyphen,
    faItalic as sharpRegularItalic,
    faLinkSimple as sharpRegularLinkSimple,
    faLinkSimpleSlash as sharpRegularLinkSimpleSlash,
    faListCheck as sharpRegularListCheck,
    faListOl as sharpRegularListOl,
    faListUl as sharpRegularListUl,
    faSubscript as sharpRegularSubscript,
    faSuperscript as sharpRegularSuperscript,
    faUnderline as sharpRegularUnderline,
} from '@fortawesome/sharp-regular-svg-icons';
import {
    faAngleLeft as sharpSolidAngleLeft,
    faAngleRight as sharpSolidAngleRight,
    faArrowRightArrowLeft as sharpSolidArrowRightArrowLeft,
    faCheck as sharpSolidCheck,
    faChevronDown as sharpSolidChevronDown,
    faChevronLeft as sharpSolidChevronLeft,
    faChevronRight as sharpSolidChevronRight,
    faChevronsLeft as sharpSolidChevronsLeft,
    faChevronsRight as sharpSolidChevronsRight,
    faChevronUp as sharpSolidChevronUp,
    faCircleCheck as sharpSolidCircleCheck,
    faCircleExclamation as sharpSolidCircleExclamation,
    faCircleQuestion as sharpSolidCircleQuestion,
    faClock as sharpSolidClock,
    faCopy as sharpSolidCopy,
    faCrosshairs as sharpSolidCrosshairs,
    faDash as sharpSolidDash,
    faImageLandscape as sharpSolidImageLandscape,
    faMinus as sharpSolidMinus,
    faPlay as sharpSolidPlay,
    faPlus as sharpSolidPlus,
    faRectangleList as sharpSolidRectangleList,
    faRotate as sharpSolidRotate,
    faRotateLeft as sharpSolidRotateLeft,
    faSortDown as sharpSolidSortDown,
    faSortUp as sharpSolidSortUp,
    faSquareCheck as sharpSolidSquareCheck,
    faXmark as sharpSolidXmark,
    faXmarkLarge as sharpSolidXmarkLarge,
} from '@fortawesome/sharp-solid-svg-icons';
import validator from 'validator';

import Bernand from '@@images/icons/bernand.svg?inline';
import CheckSlash from '@@images/icons/check-slash.svg?inline';
import DocumentMedia from '@@images/icons/document-media.svg?inline';
import DocumentStar from '@@images/icons/document-star.svg?inline';
import SpellCheckSuggest from '@@images/icons/spellcheck-suggest.svg?inline';
import Subscript2SharpRegular from '@@images/icons/subscript-2-sharp-regular.svg?inline';
import Superscript2SharpRegular from '@@images/icons/superscript-2-sharp-regular.svg?inline';
import Superscript3SharpRegular from '@@images/icons/superscript-3-sharp-regular.svg?inline';
import ZattooBrands from '@@images/icons/zattoo-brands.svg?inline';
import { splitDataUri } from '@@utils/URL';

type UnityIconName = string;

type IconStackIcon = { icon: IconDefinition; params?: Omit<FontAwesomeIconProps, 'icon'> };
type IconStackOptions = { squareViewBox?: boolean };
type IconStack = [UnityIconName, IconStackIcon[], IconStackOptions?];
type FontawesomeIcons = Array<IconDefinition | IconStack>;

const isIconDefinition = (value: unknown): value is IconDefinition => {
    if (!value || typeof value !== 'object') {
        return false;
    }

    if (!('prefix' in value) || typeof value.prefix !== 'string') {
        return false;
    }

    if (!('iconName' in value) || typeof value.iconName !== 'string') {
        return false;
    }

    if (!('icon' in value) || !Array.isArray(value.icon) || value.icon.length !== 5) {
        return false;
    }

    return true;
};

const isIconStackIcon = (value: unknown): value is IconStackIcon => {
    if (!value || typeof value !== 'object') {
        return false;
    }

    if (!('icon' in value) || !isIconDefinition(value.icon)) {
        return false;
    }

    if ('params' in value && typeof value.params !== 'object') {
        return false;
    }

    return true;
};

const isIconStack = (value: unknown): value is IconStack => {
    if (!value || !Array.isArray(value) || value.length !== 3) {
        return false;
    }

    if (typeof value[0] !== 'string') {
        return false;
    }

    if (!Array.isArray(value[1]) || !value[1].every(isIconStackIcon || isIconDefinition)) {
        return false;
    }

    if (value[2] !== undefined && typeof value[2] !== 'object') {
        return false;
    }

    return true;
};

// `solid` style and `classic` family is the default, that's why they do not appear in the unity icon name.
// `unityIconNameToFaIconName` relies on the order of this map to work correctly. Look at it and you will
// understand why.
const faPrefixToUnitySuffix = new Map<IconPrefix, string>([
    ['far', '-regular'],
    ['fal', '-light'],
    ['fat', '-thin'],
    ['fad', '-duotone'],
    ['fab', '-brands'],
    ['fak', '-kit'],
    ['fass', '-sharp'],
    ['fasr', '-sharp-regular'],
    ['fasl', '-sharp-light'],
]);

const unityIconNameToFaIconName = (unityIconName: UnityIconName): IconLookup => {
    let prefix: IconPrefix = 'fas';
    let iconName = unityIconName;

    faPrefixToUnitySuffix.forEach((unitySuffix, faPrefix) => {
        if (unityIconName.endsWith(unitySuffix)) {
            prefix = faPrefix;
            iconName = unityIconName.substring(0, unityIconName.length - unitySuffix.length);
        }
    });

    return { prefix, iconName: iconName as IconName };
};

const faIconNameToUnityIconName = ({ prefix, iconName }: IconLookup): UnityIconName => {
    const suffix = faPrefixToUnitySuffix.get(prefix) ?? '';

    return iconName + suffix;
};

export const resolveIcon = (value: FontawesomeIcons[number]) => {
    let unityIconName: UnityIconName;

    if (isIconDefinition(value)) {
        unityIconName = faIconNameToUnityIconName(value);
    } else if (isIconStack(value)) {
        unityIconName = value[0];
    } else {
        unityIconName = value;
    }

    const faIconName = unityIconNameToFaIconName(unityIconName);

    return { ...faIconName, id: `icon-${unityIconName}` };
};

export const calculateViewBox = (
    iconStackIcons: IconStackIcon[],
    options: IconStackOptions = {},
) => {
    const { squareViewBox = false } = options;

    let maxWidth = 0;
    let maxHeight = 0;

    iconStackIcons.forEach(
        ({
            icon: {
                icon: [width, height],
            },
        }) => {
            maxWidth = Math.max(width, maxWidth);
            maxHeight = Math.max(height, maxHeight);
        },
    );

    if (squareViewBox) {
        maxWidth = maxHeight = Math.max(maxWidth, maxHeight);
    }

    return [0, 0, maxWidth, maxHeight].join(' ');
};

const getCustomIcon = (unityIconName: UnityIconName): IconDefinition => {
    const imports = {
        bernand: Bernand,
        'document-media': DocumentMedia,
        'document-star': DocumentStar,
        'spellcheck-suggest': SpellCheckSuggest,
        'subscript-2-sharp-regular': Subscript2SharpRegular,
        'superscript-2-sharp-regular': Superscript2SharpRegular,
        'superscript-3-sharp-regular': Superscript3SharpRegular,
        'zattoo-brands': ZattooBrands,
        'check-slash': CheckSlash,
    };

    const svgDataUri = imports[unityIconName];

    if (validator.isDataURI(svgDataUri)) {
        const { data, base64 } = splitDataUri(svgDataUri);

        const svgXml = base64 ? window.atob(data) : decodeURIComponent(data);

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(svgXml, 'image/svg+xml');

        const viewBox = xmlDoc.querySelector('svg')?.getAttribute('viewBox');

        const path = xmlDoc.querySelector('path')?.getAttribute('d');

        if (viewBox && path) {
            const { prefix, iconName } = unityIconNameToFaIconName(unityIconName);
            const [, , width, height] = viewBox.split(' ').map(parseFloat);
            const aliases = [];
            const unicode = '';

            const definition = {
                prefix,
                iconName,
                icon: [width, height, aliases, unicode, path],
            } as IconDefinition;

            return definition;
        }
    }

    throw new Error(`Was not able to load custom icon "${unityIconName}"!`);
};

const createStackedIcon = (
    unityIconName: UnityIconName,
    stack: (IconDefinition | IconStackIcon)[],
    options?: IconStackOptions,
): IconStack => [
    unityIconName,
    stack.map((icon) => (isIconStackIcon(icon) ? icon : { icon })),
    options,
];

export const FONTAWESOME_ICONS: FontawesomeIcons = [
    getCustomIcon('document-media'),
    getCustomIcon('document-star'),
    sharpSolidPlus,
    sharpSolidXmark,
    sharpSolidMinus,
    sharpSolidCheck,
    proSolidEye,
    proSolidEyeSlash,
    proRegularEye,
    proRegularEyeSlash,
    sharpSolidCircleCheck,
    sharpSolidCircleExclamation,
    sharpSolidCircleQuestion,
    proRegularCircleXmark,
    proSolidGear,
    proSolidArrowUpRightFromSquare,
    proSolidQuoteRight,
    proSolidTag,
    proSolidTags,
    proSolidStar,
    proSolidSitemap,
    proSolidUser,
    proSolidUsers,
    proSolidUsersGear,
    sharpSolidRotate,
    sharpSolidRotateLeft,
    sharpSolidChevronUp,
    sharpSolidChevronDown,
    sharpSolidChevronLeft,
    sharpSolidChevronRight,
    sharpRegularChevronRight,
    sharpRegularCheck,
    proSolidEllipsis,
    proSolidEllipsisVertical,
    proRegularPen,
    proSolidXmark,
    proSolidTrashCan,
    proSolidMagnifyingGlass,
    proRegularMagnifyingGlassPlus,
    sharpRegularBold,
    sharpRegularItalic,
    sharpRegularUnderline,
    sharpRegularCode,
    sharpRegularSubscript,
    sharpRegularSuperscript,
    sharpRegularDollarSign,
    createStackedIcon('chevron-left-chevron-right-sharp', [
        {
            icon: sharpSolidChevronLeft,
            params: {
                transform: 'shrink-7 left-5',
            },
        },
        {
            icon: sharpSolidChevronRight,
            params: {
                transform: 'shrink-7 right-5',
            },
        },
    ]),
    createStackedIcon('chevrons-left-chevrons-right-sharp', [
        {
            icon: sharpSolidChevronsLeft,
            params: {
                transform: 'shrink-9 left-4.5',
            },
        },
        {
            icon: sharpSolidChevronsRight,
            params: {
                transform: 'shrink-9 right-4.5',
            },
        },
    ]),
    sharpSolidDash,
    getCustomIcon('subscript-2-sharp-regular'),
    getCustomIcon('superscript-2-sharp-regular'),
    getCustomIcon('superscript-3-sharp-regular'),
    sharpRegularLinkSimple,
    sharpRegularLinkSimpleSlash,
    sharpRegularHyphen,
    sharpRegularListOl,
    sharpRegularListUl,
    sharpRegularListCheck,
    proRegularCalendarDays,
    proSolidCircle,
    sharpSolidCrosshairs,
    sharpSolidClock,
    sharpRegularClock,
    proSolidFutbol,
    proSolidHourglassHalf,
    proSolidPersonSwimming,
    proLightHandFist,
    sharpSolidXmarkLarge,
    sharpSolidSquareCheck,
    sharpSolidCopy,
    sharpLightCopy,
    proSolidCurlingStone,
    createStackedIcon('f-1', [
        {
            icon: proSolidF,
            params: {
                transform: 'shrink-4 left-2.5',
            },
        },
        {
            icon: proSolid1,
            params: {
                transform: 'shrink-4 right-4.5',
            },
        },
    ]),
    createStackedIcon('goal-net-futbol', [
        proSolidGoalNet,
        {
            icon: proSolidCircle,
            params: {
                color: 'white',
                transform: 'shrink-9 down-6',
            },
        },
        {
            icon: proSolidFutbol,
            params: {
                transform: 'shrink-9 down-6',
            },
        },
    ]),
    proSolidVolleyball,
    proSolidPersonSnowboarding,
    proSolidPersonSkiing,
    proRegularBasketball,
    proSolidHorse,
    proRegularCardsBlank,
    proSolidSwords,
    proSolidHockeySticks,
    proSolidPersonBiking,
    proSolidPersonSkating,
    proSolidPersonSkiingNordic,
    createStackedIcon('beach-volleyball', [
        {
            icon: proSolidVolleyball,
            params: { transform: 'shrink-3 up-2' },
        },
        {
            icon: proSolidWater,
            params: { transform: 'shrink-2 down-11' },
        },
    ]),
    proSolidTrophy,
    createStackedIcon('racquet-tennis-ball', [
        {
            icon: proSolidRacquet,
            params: { transform: 'shrink-3 left-1 up-1' },
        },
        {
            icon: proSolidTennisBall,
            params: { transform: 'shrink-11.5 right-4 down-4.5' },
        },
    ]),
    proSolidPersonRunning,
    proSolidGolfFlagHole,
    proLightHandPointUp,
    sharpRegularArrowsRepeat,
    freeBrandYoutube,
    freeBrandFacebook,
    freeBrandXTwitter,
    freeBrandInstagram,
    freeBrandTiktok,
    freeBrandThreads,
    getCustomIcon('zattoo-brands'),
    proRegularImage,
    proSolidThumbtack,
    proSolidPaperPlane,
    proRegularChartNetwork,
    proRegularGrid2,
    proSolidBoltLightning,
    createStackedIcon('rectangle-regular-play', [
        proRegularRectangle,
        {
            icon: proSolidPlay,
            params: { transform: 'shrink-10 right-0.5' },
        },
    ]),
    proRegularImages,
    proSolidCode,
    proSolidInbox,
    proSolidFlag,
    proSolidPlug,
    proSolidFaceSmile,
    proSolidFaceFrownSlight,
    proSolidFaceSadTear,
    proSolidFaceSadCry,
    proSolidWindowMinimize,
    proRegularSquarePlus,
    proRegularClipboard,
    proRegularCopy,
    proRegularClone,
    sharpSolidArrowRightArrowLeft,
    proSolidMinus,
    proSolidPuzzlePiece,
    proSolidClipboardList,
    proSolidPuzzlePieceSimple,
    sharpSolidSortUp,
    sharpSolidSortDown,
    proLightFaceFrownSlight,
    proSolidSquarePollVertical,
    proRegularMessage,
    proSolidThumbsUp,
    proSolidThumbsDown,
    proSolidRotate,
    createStackedIcon('embed-sharp', [
        {
            icon: sharpSolidAngleLeft,
            params: {
                transform: 'shrink-6 left-4',
            },
        },
        {
            icon: sharpSolidAngleRight,
            params: {
                transform: 'shrink-6 right-4',
            },
        },
    ]),
    createStackedIcon('square-regular-play', [
        proRegularSquare,
        {
            icon: sharpSolidPlay,
            params: { transform: 'shrink-10 right-0.5' },
        },
    ]),
    proSolidSquareInfo,
    proSolidGripDotsVertical,
    proSolidAlignLeft,
    sharpRegularCommentLines,
    createStackedIcon('aspect-ratio', [
        proRegularExpand,
        {
            icon: proSolidUpRightAndDownLeftFromCenter,
            params: { transform: 'shrink-7 rotate-90' },
        },
    ]),
    proSolidScrewdriverWrench,
    proSolidPaperclip,
    createStackedIcon('content-status-draft', [
        proSolidCircle,
        {
            icon: proSolidPen,
            params: { color: 'white', transform: 'shrink-6' },
        },
    ]),
    createStackedIcon(
        'content-status-review-1',
        [
            proSolidCircle,
            {
                icon: proSolidEye,
                params: { color: 'white', transform: 'shrink-4' },
            },
        ],
        { squareViewBox: true },
    ),
    createStackedIcon(
        'content-status-seo',
        [
            proSolidCircle,
            {
                icon: sharpRegularListCheck,
                params: { color: 'white', transform: 'shrink-6' },
            },
        ],
        { squareViewBox: true },
    ),
    createStackedIcon(
        'content-status-review-2',
        [
            proSolidCircle,
            {
                icon: proSolidEye,
                params: { color: 'white', transform: 'shrink-8 left-2.5 up-2.5' },
            },
            {
                icon: proSolidPen,
                params: { color: 'white', transform: 'shrink-6 right-2 down-2' },
            },
        ],
        { squareViewBox: true },
    ),
    createStackedIcon('content-status-proofread', [
        proSolidCircle,
        {
            icon: proSolidMagnifyingGlass,
            params: { color: 'white', transform: 'shrink-5' },
        },
    ]),
    createStackedIcon('content-status-required-rework', [
        proSolidCircle,
        {
            icon: proSolidExclamation,
            params: { color: 'white', transform: 'shrink-3.5' },
        },
    ]),
    createStackedIcon('content-status-ready-to-publish', [
        proSolidCircle,
        {
            icon: proSolidCheck,
            params: { color: 'white', transform: 'shrink-3.5 down-0.5' },
        },
    ]),
    createStackedIcon('publication-status-not-published', [
        proSolidCircle,
        {
            icon: proSolidXmark,
            params: { color: 'white', transform: 'shrink-3.5' },
        },
    ]),
    createStackedIcon('publication-status-published', [
        proSolidCircle,
        {
            icon: proSolidCheck,
            params: { color: 'white', transform: 'shrink-3.5 down-0.5' },
        },
    ]),
    createStackedIcon('publication-status-unpublished', [
        proSolidCircle,
        {
            icon: getCustomIcon('check-slash'),
            params: { color: 'white', transform: 'shrink-3.5 down-0.5' },
        },
    ]),
    sharpSolidImageLandscape,
    proSolidLock,
    proSolidBan,
    proSolidPenClip,
    proSolidSpellCheck,
    proRegularNoteSticky,
    proSolidArrowsDownToLine,
    proSolidGripLines,
    proSolidHighlighter,
    proSolidCircleInfo,
    getCustomIcon('spellcheck-suggest'),
    proRegularFileCirclePlus,
    proRegularRobot,
    proSolidEnvelope,
    proSolidTriangleExclamation,
    proRegularLanguage,
    getCustomIcon('bernand'),
    sharpSolidRectangleList,
];

if (import.meta.env.MODE === 'development') {
    const duplicatesCache = {};

    FONTAWESOME_ICONS.forEach((icon) => {
        const { id } = resolveIcon(icon);

        if (duplicatesCache[id]) {
            throw new Error(`You have defined "${id}" twice, which is not allowed!`);
        }

        duplicatesCache[id] = true;
    });
}

const Icons = ({ children }) => (
    <>
        {FONTAWESOME_ICONS.map((icon) => {
            const { id } = resolveIcon(icon);

            if (isIconDefinition(icon)) {
                return <FontAwesomeIcon key={id} symbol={id} icon={icon} />;
            } else if (isIconStack(icon)) {
                const [, stack, options] = icon;

                const viewBox = calculateViewBox(stack, options);

                return (
                    <svg key={id} style={{ display: 'none' }}>
                        <symbol id={id} viewBox={viewBox}>
                            {stack.map(({ icon, params }, index) => (
                                <FontAwesomeIcon key={index} icon={icon} {...params} />
                            ))}
                        </symbol>
                    </svg>
                );
            }

            throw new Error(`No icon definition found for: ${JSON.stringify(icon)}`);
        })}

        {children}
    </>
);

export default Icons;
