import { styled } from '@mui/material';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { type Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type EmbedElement } from '@@editor/helpers/Element';

import { getInputPlaceholderStyle } from '../../../styles';

type Props = {
    attributes: ElementAttributes;
    children: ReactNode;
    editor: Editor;
    element: EmbedElement;
};

type StyledQuoteTextProps = {
    $templateElement: boolean;
    $placeholderText: string;
    $readOnly: boolean;
};

const StyledQuoteText = styled('div')<StyledQuoteTextProps>(
    ({ $templateElement, $placeholderText, $readOnly, theme }) => ({
        ...theme.fixed.editor.quote.text.font,
        padding: theme.spacing(1, 2),
        textAlign: 'center',
        ...($templateElement && {
            ...getInputPlaceholderStyle({
                theme,
                placeholderText: $placeholderText,
                readOnly: $readOnly,
            }),
            '&&::before': {
                left: '50%',
                transform: 'translate(-50%, 0)',
            },
        }),
    }),
);

const QuoteText = (props: Props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);
    const readOnly = ReactEditor.isReadOnly(editor);

    return (
        <>
            <StyledQuoteText
                {...attributes}
                $templateElement={
                    Element.isTemplateElement(parentNode) ||
                    Element.isEmptyQuoteTextElement(element)
                }
                $placeholderText={t('editor.plugin.quote.quotePlaceholderText')}
                $readOnly={readOnly}
            >
                {children}
            </StyledQuoteText>
        </>
    );
};

export default QuoteText;
