import { Transforms } from 'slate';

import { type Editor, Element, ReactEditor } from '@@editor/helpers';
import { ELEMENT_TYPES, type EmbeddedContentElement, MIME_TYPES } from '@@editor/helpers/Element';
import { normalizeInlineEditableElement } from '@@editor/plugins/utils/inlineEditing';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { parseValueForUrl } from '@@form/fields/UrlIframe';

import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import EmbedWrapper from './../components/EmbedWrapper';
import { createGenerateEmbedBlock } from './../utils';
import EmbeddedContentForm, { DEFAULT_EMBEDDED_CONTENT_FORM_VALUES } from './EmbeddedContentForm';
import EmbeddedContentFrame from './EmbeddedContentFrame';
import EmbeddedContentPlaceholder from './EmbeddedContentPlaceholder';
import { generateEmbedBlockData } from './utils';

const TYPE = PLUGIN_NAMES.EMBEDDED_CONTENT;
const NODE_TYPE = ELEMENT_TYPES.EMBEDDED_CONTENT;
const INLINE_EDITABLE_CHILDREN_TYPES = [ELEMENT_TYPES.EMBED_CAPTION, ELEMENT_TYPES.EMBED_CREDIT];

const mimetypeConfigs = {
    [MIME_TYPES.EMBEDDED_CONTENT]: {
        Component: EmbeddedContentFrame,
    },
};

type Props = {
    editor: Editor;
    element: EmbeddedContentElement;
};

const EmbeddedContentNode = (props: Props) => {
    const { editor, element } = props;

    return (
        <EmbedWrapper
            {...props}
            type={TYPE}
            mimetypeConfigs={mimetypeConfigs}
            toolbarConfig={{
                infos: {
                    iconName: PLUGIN_ICON_NAMES[TYPE],
                    title: 'Embedded Content',
                },
                actions: Element.isTemplateElement(element)
                    ? ['delete']
                    : [
                          {
                              type: 'edit',
                              iconName: 'gear',
                              onClick: (e) => {
                                  e.preventDefault();

                                  return requestAnimationFrame(() =>
                                      editor.showEmbedModal(
                                          TYPE,
                                          element,
                                          ReactEditor.findPath(editor, element),
                                      ),
                                  );
                              },
                          },
                          'delete',
                      ],
            }}
            placeholderComponent={EmbeddedContentPlaceholder}
            contentEditable={false}
        />
    );
};

const generateEmbedBlock = createGenerateEmbedBlock({
    contentType: 'html',
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
    parseEmbedCode: parseValueForUrl,
});

const internalWithEmbeddedContent = (editor, options) =>
    Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formComponent={EmbeddedContentForm}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, EmbeddedContentNode]], options),
        insertEmbeddedContent: (at) => {
            const block = generateEmbedBlock(editor, DEFAULT_EMBEDDED_CONTENT_FORM_VALUES, {
                at,
            });

            Transforms.select(editor, at);
            Transforms.collapse(editor);

            return block;
        },
        normalizeNode: normalizeNode(editor, [
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.EMBEDDED_CONTENT,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
    });

export const withEmbeddedContent = (editor, options) =>
    internalWithEmbeddedContent(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withEmbeddedContent;
