import { type RouteObject } from 'react-router';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { UserSettings } = await import('@@routes/userSettings');

            return { Component: UserSettings };
        },
    },
];
