import { type HTMLAttributes, type ReactNode } from 'react';

import { HOTKEYS } from '@@editor/constants';
import { type Editor } from '@@editor/helpers';
import handleHotkey from '@@editor/plugins/utils/handleHotkey';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';
import { isPluginInvalid } from '@@editor/toolbars/utils/getToolbarConfig';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

const MARK = 'bold';

type Props = {
    attributes: HTMLAttributes<HTMLElement>;
    children: ReactNode;
};

const BoldMark = (props: Props) => <strong {...props.attributes}>{props.children}</strong>;

export const withBold = (editor: Editor): Editor =>
    Object.assign(editor, {
        renderLeaf: renderLeaf<Props>(editor, [[MARK, BoldMark]]),
        handleHotkey: handleHotkey(editor, [
            [
                HOTKEYS.BOLD,
                (editor: Editor) => {
                    if (!isPluginInvalid(editor, PLUGIN_NAMES.BOLD)) {
                        editor.toggleMark(MARK);
                    }
                },
            ],
        ]),
    });

export default withBold;
