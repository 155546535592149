import { type InputProps } from '@mui/material';
import { type ChangeEventHandler, type FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Spacer from '@@components/Spacer';
import IframeFields from '@@containers/IframeFields';
import { type Editor } from '@@editor/helpers';
import RichTextStringField from '@@form/fields/RichTextEditor/RichTextString';

type Props = {
    groupName?: string;
    validateIframeField?: Validator[];
    onIframeBlur?: FocusEventHandler<HTMLTextAreaElement>;
    onIframeChange?: ChangeEventHandler<HTMLTextAreaElement>;
    editor?: Editor;
    InputProps?: InputProps;
};

const EmbeddedContentFields = ({
    groupName,
    validateIframeField,
    onIframeBlur,
    onIframeChange,
    InputProps = {},
}: Props) => {
    const { t } = useTranslation();

    const makeName = (name) => (groupName ? `${groupName}.${name}` : name);

    return (
        <>
            <IframeFields
                InputProps={InputProps}
                validateIframeField={validateIframeField}
                onIframeBlur={onIframeBlur}
                onIframeChange={onIframeChange}
                names={{
                    url: makeName('embed.iframe'),
                    fallBackHeight: makeName('embed.fallBackHeight'),
                }}
            />

            <Spacer v md />

            <RichTextStringField
                name={makeName('embed.captionTitle')}
                label={t('embed.captionTitle')}
                displayUsedCharacters
            />
        </>
    );
};

export default EmbeddedContentFields;
