import { styled } from '@mui/material';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { type Metadata } from '@@api/services/metadata/schemas';
import { type Image } from '@@api/utils/schemas/schemas';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import { type Element } from '@@editor/helpers';
import { type UnityState } from '@@editor/typings/UnityElements';
import makeState from '@@editor/utils/makeState';
import ImageField from '@@form/fields/ImageField';

import { MAX_HEIGHT, MAX_WIDTH } from '../../constants';

const StyledReactHookForm = styled(ReactHookForm)({
    width: '640px',
});

interface IFormValues {
    title: UnityState | null;
    image: Image;
    metadataIds: Metadata['id'][];
}

type Props = {
    type: string;
    element: Element;
    className?: string;
    renderButtons: () => ReactNode;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
};

const DynamicTeaserForm = (props: Props) => {
    const { renderButtons, element, onCancel, onSubmit } = props;

    // @ts-expect-error
    const initialValues: IFormValues = element?.data || {
        title: makeState(),
        image: {
            caption: makeState(),
        },
        metadataIds: [],
    };

    const { t } = useTranslation();

    return (
        <StyledReactHookForm
            formName="LayoutDynamicTeaser"
            values={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
        >
            <ImageField
                name="image"
                label={t('editor.plugin.dynamicTeaser.form.image')}
                noDropArea
                flagAsOptional
                imageOnly
                transformations={{
                    maxWidth: MAX_WIDTH,
                    maxHeight: MAX_HEIGHT,
                }}
            />
        </StyledReactHookForm>
    );
};

export default DynamicTeaserForm;
