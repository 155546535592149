import { type ReactNode } from 'react';

import { HOTKEYS } from '@@editor/constants';
import { type Editor } from '@@editor/helpers';
import handleHotkey from '@@editor/plugins/utils/handleHotkey';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';
import { isPluginInvalid } from '@@editor/toolbars/utils/getToolbarConfig';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

const MARK = 'underlined';

type Props = {
    attributes: UnknownObject;
    children: ReactNode;
};

const UnderlinedMark = (props: Props) => <u {...props.attributes}>{props.children}</u>;

export const withUnderlined = (editor: Editor) =>
    Object.assign(editor, {
        renderLeaf: renderLeaf(editor, [[MARK, UnderlinedMark]]),
        handleHotkey: handleHotkey(editor, [
            [
                HOTKEYS.UNDERLINED,
                (editor: Editor) => {
                    if (!isPluginInvalid(editor, PLUGIN_NAMES.UNDERLINED)) {
                        editor.toggleMark(MARK);
                    }
                },
            ],
        ]),
    });

export default withUnderlined;
