import { useCallback } from 'react';

import { getUserRole } from '@@auth/authSlice';
import { getUserRoleSmartSetting } from '@@settings/settingsSlice';
import { useSelector } from '@@store/hooks';

import { ROLES, type UserRole } from '../constants';

const useAuthorization = () => {
    const currentUserRole = useSelector(getUserRoleSmartSetting);
    const authUserRole = useSelector(getUserRole);

    const userRole = currentUserRole || authUserRole;

    const isAuthorized = useCallback(
        (userRoles: UserRole[] | undefined) =>
            !userRoles ||
            (userRole && userRoles.includes(userRole)) ||
            userRoles.includes(ROLES.EDITOR),
        [userRole],
    );

    // check role authorization, authorize everyone if no authorize is supplied
    return { isAuthorized };
};

export default useAuthorization;
