import { styled } from '@mui/material';
import { Path } from 'slate';

import { Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import FloatingToolbarWrapper from '@@editor/toolbars/floatingToolbar/FloatingToolbarWrapper';
import { getToolbarSettings } from '@@editor/utils/getToolbarSettings';

import ErrorWrapper from '../serializable/components/ErrorWrapper';

const FloatingToolbarSpacer = styled('div')(({ theme }) => ({
    height: theme.fixed.editor.floatingToolbar.height,
}));

type WithoutEmptyElementsProps = {
    editor: Editor;
    element: Element;
    component: JSX.Element;
};

// This if/else needs to be inside a react component, otherwise react will throw an error if any hooks will be
// rendered in `component` and sometimes the condition is true and sometimes false. React would not understand
// why sometimes more and sometimes less hooks are rendered.
const WithoutEmptyElements = ({ editor, element, component }: WithoutEmptyElementsProps) => {
    if (
        ReactEditor.isReadOnly(editor) &&
        (Element.isTemplateElement(element) || Element.isEmptyElement(element))
    ) {
        return null;
    }

    return component;
};

const withErrorWrapper = (displayError) => (WrappedComponent, element) =>
    displayError ? (
        <ErrorWrapper element={element}>{WrappedComponent}</ErrorWrapper>
    ) : (
        WrappedComponent
    );

const withFloatingToolbar = (WrappedComponent, editor: Editor, element: Element) => {
    const isReadOnly = ReactEditor.isReadOnly(editor);
    const toolbarSettings = getToolbarSettings(editor, element);
    const isToolbarVisible =
        !isReadOnly && !editor.hideFloatingToolbar && toolbarSettings?.floatingToolbar.enabled;

    const elementTypesWithoutSpacer = [
        Element.isLinkElement,
        Element.isEmbedCaptionElement,
        Element.isEmbedCreditElement,
        Element.isInterviewSegmentQuestionElement,
        Element.isInterviewSegmentAnswerElement,
        Element.isPollQuestionElement,
        Element.isPollAnswerElement,
        Element.isQuoteCaptionElement,
        Element.isQuoteTextElement,
        Element.isListItemElement,
        Element.isSummaryListSummaryElement,
    ];

    // We don't want to show the spacer when element is inside other elements, like infobox
    const childElementTypesWithoutSpacer = [Element.isListElement];

    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);

    const hideSpacer =
        elementTypesWithoutSpacer.some((typeChecker) => typeChecker(element)) ||
        (parentNode !== editor &&
            childElementTypesWithoutSpacer.some((typeChecker) => typeChecker(element)));

    if (isToolbarVisible) {
        const path = ReactEditor.findPath(editor, element);
        const [, parentPath] = Editor.parent(editor, path);
        const [, lastNodePath] = Editor.last(editor, parentPath);
        const isLastElement = Path.compare(path, lastNodePath) === 0;
        const activePlugins =
            toolbarSettings.floatingToolbar.allowedPlugins ?? editor.defaultFloatingToolbarPlugins;

        return (
            <FloatingToolbarWrapper
                isLastElement={isLastElement}
                path={path}
                activePlugins={activePlugins}
            >
                {WrappedComponent}
            </FloatingToolbarWrapper>
        );
    } else if (editor.preserveFloatingToolbarHeight) {
        return (
            <>
                {!hideSpacer && <FloatingToolbarSpacer />}
                {WrappedComponent}
            </>
        );
    }

    return WrappedComponent;
};

const renderElement = (editor: Editor, elementsConfig, outerOptions?) => {
    const { renderElement } = editor;

    return (props) => {
        const elementConfig = elementsConfig.find(([type]) => type === props.element.type);

        if (elementConfig) {
            const { element } = props;
            const displayError = props.element?.data?.displayError;
            const [, Fn, innerOptions = {}] = elementConfig;

            // In some cases Fn is already a component
            const component =
                typeof Fn === 'function' ? (
                    Fn({
                        editor,
                        ...props,
                        ...outerOptions,
                        ...innerOptions,
                    })
                ) : (
                    <Fn editor={editor} {...props} {...outerOptions} {...innerOptions} />
                );

            const componentWithErrorWrapper = withErrorWrapper(displayError)(component, element);
            const componentWithFloatingToolbar = withFloatingToolbar(
                componentWithErrorWrapper,
                editor,
                element,
            );

            return (
                <WithoutEmptyElements
                    {...{ editor, element, component: componentWithFloatingToolbar }}
                />
            );
        }

        return renderElement(props);
    };
};

export default renderElement;
