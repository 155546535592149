import { isRegExp, noop, omit } from 'lodash-es';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import LoadingStatusManagerMessenger, {
    isCancelLoadingRequestMessageEvent,
    isLoadErrorResponseMessageEvent,
    isLoadingRequestMessageEvent,
    isLoadProgressRequestMessageEvent,
    isLoadRequestMessageEvent,
    isLoadSuccessResponseMessageEvent,
} from './LoadingStatusManagerMessenger';
import {
    type Action,
    ActionType,
    type ContextValue,
    type DispatchContextValue,
    type ReducerState,
    type Status,
    type UseLoadingStatusManagerProps,
} from './types';

const INITIAL_REDUCER_STATE: ReducerState = { listeners: [], statuses: {} };

const LoadingStatusManagerContext = createContext<ContextValue>(INITIAL_REDUCER_STATE.statuses);

const LoadingStatusManagerDispatchContext = createContext<DispatchContextValue>({
    addListener: noop,
    removeListener: noop,
});

const applyAction = (state: ReducerState, action: Action): ReducerState => {
    if (action.type !== ActionType.ADD_LISTENER && action.type !== ActionType.REMOVE_LISTENER) {
        state.listeners.forEach((listener) => {
            if (
                listener.eventName === action.type &&
                (isRegExp(listener.key)
                    ? listener.key.test(action.payload.key)
                    : action.payload.key === listener.key)
            ) {
                listener.fn(action.payload);
            }
        });
    }

    switch (action.type) {
        case ActionType.ADD_LISTENER:
            return {
                ...state,
                listeners: [...state.listeners, action.payload],
            };

        case ActionType.REMOVE_LISTENER:
            return {
                ...state,
                listeners: state.listeners.filter((listener) => listener.fn !== action.payload.fn),
            };

        case ActionType.LOAD:
            if (state.statuses[action.payload.key]) {
                return state;
            }

            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    [action.payload.key]: {
                        isLoading: true,
                    },
                },
            };

        case ActionType.LOAD_PROGRESS:
            if (!state.statuses[action.payload.key]) {
                return state;
            }

            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    [action.payload.key]: {
                        isLoading: true,
                        progress: action.payload.progress,
                    },
                },
            };

        case ActionType.LOAD_SUCCESS:
            if (!state.statuses[action.payload.key]) {
                return state;
            }

            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    [action.payload.key]: {
                        isLoading: false,
                        data: action.payload.data,
                    },
                },
            };

        case ActionType.LOAD_ERROR:
            if (!state.statuses[action.payload.key]) {
                return state;
            }

            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    [action.payload.key]: {
                        isLoading: false,
                        error: action.payload.error,
                    },
                },
            };

        case ActionType.CANCEL_LOADING: {
            const keys = Object.keys(state.statuses).filter((key) =>
                isRegExp(action.payload.key)
                    ? action.payload.key.test(key)
                    : key === action.payload.key,
            );

            if (!keys.length) {
                return state;
            }

            return {
                ...state,
                statuses: omit(state.statuses, keys),
            };
        }
    }

    return state;
};

export const LoadingStatusManagerProvider = ({ children }) => {
    const [state, dispatch] = useReducer(applyAction, INITIAL_REDUCER_STATE);

    const actions: DispatchContextValue = useMemo(
        () => ({
            addListener: (eventName, fn, key) => {
                dispatch({ type: ActionType.ADD_LISTENER, payload: { eventName, fn, key } });
            },
            removeListener: (eventName, fn) => {
                dispatch({ type: ActionType.REMOVE_LISTENER, payload: { eventName, fn } });
            },
        }),
        [],
    );

    const handleMessageEvent = useCallback(
        (event: MessageEvent) => {
            if (isLoadingRequestMessageEvent(event)) {
                const { payload } = event.data;

                return Object.keys(state.statuses).some(
                    (key) => payload.key.test(key) && state.statuses[key].isLoading,
                );
            } else if (isLoadRequestMessageEvent(event)) {
                const { payload } = event.data;

                dispatch({ type: ActionType.LOAD, payload });
            } else if (isLoadProgressRequestMessageEvent(event)) {
                const { payload } = event.data;

                dispatch({ type: ActionType.LOAD_PROGRESS, payload });
            } else if (isLoadSuccessResponseMessageEvent(event)) {
                const { payload } = event.data;

                dispatch({ type: ActionType.LOAD_SUCCESS, payload });
            } else if (isLoadErrorResponseMessageEvent(event)) {
                const { payload } = event.data;

                dispatch({ type: ActionType.LOAD_ERROR, payload });
            } else if (isCancelLoadingRequestMessageEvent(event)) {
                const { payload } = event.data;

                dispatch({ type: ActionType.CANCEL_LOADING, payload });
            }
        },
        [state.statuses],
    );

    useEffect(() => LoadingStatusManagerMessenger.listen(handleMessageEvent), [handleMessageEvent]);

    return (
        <LoadingStatusManagerContext.Provider value={state.statuses}>
            <LoadingStatusManagerDispatchContext.Provider value={actions}>
                {children}
            </LoadingStatusManagerDispatchContext.Provider>
        </LoadingStatusManagerContext.Provider>
    );
};

export const useLoadingStatusManager = <
    TData extends UnknownObject | undefined = UnknownObject | undefined,
>(
    props: UseLoadingStatusManagerProps<TData>,
) => {
    const {
        key,
        onLoad = noop,
        onLoadProgress = noop,
        onLoadSuccess = noop,
        onLoadError = noop,
    } = props;
    const state = useContext(LoadingStatusManagerContext);
    const { addListener, removeListener } = useContext(LoadingStatusManagerDispatchContext);

    useEffect(() => {
        addListener(ActionType.LOAD, onLoad, key);
        addListener(ActionType.LOAD_PROGRESS, onLoadProgress, key);
        addListener(ActionType.LOAD_SUCCESS, onLoadSuccess, key);
        addListener(ActionType.LOAD_ERROR, onLoadError, key);

        return () => {
            removeListener(ActionType.LOAD, onLoad);
            removeListener(ActionType.LOAD_PROGRESS, onLoadProgress);
            removeListener(ActionType.LOAD_SUCCESS, onLoadSuccess);
            removeListener(ActionType.LOAD_ERROR, onLoadError);
        };
    }, [key, addListener, removeListener, onLoad, onLoadProgress, onLoadSuccess, onLoadError]);

    if (isRegExp(key)) {
        return {
            isLoading: Object.keys(state).some((k) => key.test(k) && state[k].isLoading),
        } as Status;
    }

    return (state[key] ?? {
        isLoading: undefined,
    }) as Status | Undefined<Status>;
};
