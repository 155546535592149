import { z } from 'zod';

import i18n from '@@lib/i18n/i18n';

/**
 * A custom Zod schema that requires the string to not be empty.
 */
export const requiredString = z.string().min(1, i18n.t('validator.message.required'));

export const requiredStringNullable = z
    .string()
    .nullable()
    .refine((val) => val !== null && val.trim().length > 0, {
        message: i18n.t('validator.message.required'),
    });

/**
 * A custom Zod schema that allows initial value to be null but requires a number value.
 */
export const requiredNumberNullable = z
    .number()
    .nullable()
    .refine((val) => val !== null, {
        message: i18n.t('validator.message.required'),
    });
