export const HTTP_METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
    HEAD: 'head',
} as const;

export type HttpMethod = ValueOf<typeof HTTP_METHODS>;

export const HTTP_STATUS_CODES = {
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    MOVED_PERMANENTLY: 301,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    PRECONDITION_FAILED: 412,
    UNSUPPORTED_MEDIA_TYPE: 415,
    UNPROCESSABLE_CONTENT: 422,
    // This code is not a standard HTTP status code, but it's used in our API to indicate that the server did not send a response.
    // Ngnix apparently uses it for the same purpose: https://http.dev/444
    NO_RESPONSE: 444,
    INTERNAL_SERVER_ERROR: 500,
    CONNECTION_TIMED_OUT: 522,
    BANDWIDTH_LIMIT_EXCEEDED: 509,
} as const;

export const PATCH_CONTENT_TYPES = {
    // https://tools.ietf.org/html/rfc6902
    PATCH: 'application/json-patch+json',
    // https://tools.ietf.org/html/rfc7386
    MERGE_PATCH: 'application/merge-patch+json',
    // Custom content type. It's the same as `PATCH` but used for patching collections/lists.
    BULK_PATCH: 'application/bulk-json-patch+json',
    // Custom content type. It's the same as `MERGE_PATCH` but used for patching collections/lists.
    BULK_MERGE_PATCH: 'application/bulk-merge-patch+json',
};
