import { type TFunction } from 'i18next';
import { castArray, forEach, omit } from 'lodash-es';
// This is the only place in this stack where we use `Editor` directly! Use this helper
// in all other places.
/* eslint-disable no-restricted-imports */
import { type KeyboardEvent } from 'react';
import {
    Editor as SlateEditor,
    type EditorNodesOptions,
    type Location,
    type Node,
    type NodeEntry,
    Path,
    Range,
    type RangeMode,
    Span,
    Transforms,
} from 'slate';
import { type RenderElementProps, type RenderLeafProps } from 'slate-react';
/* eslint-enable no-restricted-imports */

import { type FileHead } from '@@api/hooks/useFileHead';
import { type GenerateImageAltText } from '@@api/hooks/useGenerateImageAltText';
import { type UploadFile } from '@@api/hooks/useUploadFile';
import {
    type PLUGIN_NAMES,
    type PluginConfig,
    type PluginList,
    type PluginName,
} from '@@editor/typings/UnityPlugins';
import { type LangCode } from '@@lib/i18n/constants';

import {
    type CrossheadStyle,
    Element,
    type ELEMENT_TYPES,
    type ElementType,
    type SummaryListElement,
} from './Element';
import { type HistoryEditor } from './HistoryEditor';
import { type ReactEditor } from './ReactEditor';

type Edge = 'start' | 'end' | 'focus' | 'anchor';

export type Options = EditorNodesOptions<Node> & {
    edge?: Edge;
    types?: string | string[];
    replace?: boolean;
    select?: boolean;
    hasToolbar?: boolean | undefined;
    defaultFloatingToolbarPlugins?: ValueOf<typeof PLUGIN_NAMES>[] | undefined;
};

export type InsertElementOptions = {
    at?: Location;
    replace?: boolean;
    select?: boolean;
    mode?: RangeMode;
};

type Data = AnyObject;
type ExtendedRenderLeafProps = RenderLeafProps & { renderersUsed?: number };
type RenderLeaf = (props: ExtendedRenderLeafProps) => JSX.Element;
export type HandleHotkey = (e: KeyboardEvent) => void;

type EnhancedRenderElementProps = Omit<RenderElementProps, 'attributes'> & {
    attributes: RenderElementProps['attributes'] & {
        'data-slate-node-key-id'?: string;
    };
};

export type LinkData =
    | {
          type: typeof ELEMENT_TYPES.EXTERNAL_LINK;
          url: string;
          text: string;
      }
    | {
          type: typeof ELEMENT_TYPES.INTERNAL_CONTENT_LINK;
          metadataId: number;
          text: string;
      }
    | {
          type: typeof ELEMENT_TYPES.CATEGORY_LINK;
          categoryId: number;
          text: string;
      }
    | {
          type: typeof ELEMENT_TYPES.TAG_LINK;
          tagId: number;
          text: string;
      }
    | {
          type: typeof ELEMENT_TYPES.AUTHOR_LINK;
          authorId: string;
          text: string;
      };

type WithPluginsEditor = {
    // default
    id: string;
    valueId: string;
    data: Data;
    decorate: (nodeEntry: NodeEntry) => any[];
    renderEditor: ({ children }) => any;
    renderElement: (props: EnhancedRenderElementProps) => JSX.Element;
    renderLeaf: RenderLeaf;
    handleHotkey: HandleHotkey;
    onChangeValue: VoidFunction;
    onEditorMount: VoidFunction;
    onEditorUnmount: VoidFunction;
    onAfterRemoveElement: (node: Node) => void;
    onAfterInsertElement: (node: Node) => void;
    onAfterUpdateElement: (node: Node, previousNode?: Node) => void;
    isBlock: (value) => boolean;
    toggleMark: (mark: string, single?: boolean) => void;
    insertData: (data: DataTransfer, options?: InsertElementOptions) => void;
    t: TFunction;
    debug?: boolean;
    disabled?: boolean;
    forceUpdate: VoidFunction;
    availablePlugins: PluginConfig[];
    withDnd?: boolean;
    hideFloatingToolbar?: boolean;
    defaultFloatingToolbarPlugins?: PluginName[];
    stickyToolbarButtons?: PluginList[];
    withEnhancedTextBlocks?: boolean;
    withPenIcon?: boolean;
    // data
    getData: () => Data;
    getDataIn: (path: string[]) => any;
    setData: (nextData: Data, shouldForceUpdate?: boolean) => void;
    setEditorContextData: (nextData: Data) => void;
    unsetData: (paths: string | string[], shouldForceUpdate?: boolean) => void;
    onChangeData: VoidFunction;
    // link
    insertLink: (data: LinkData, options?: Options) => void;
    hideLinkModal: (restore?: boolean) => void;
    removeLink: (options?: Options) => void;
    showLinkModal: VoidFunction;
    // list
    isUnorderedListActive: () => boolean;
    isOrderedListActive: () => boolean;
    toggleUnorderedList: VoidFunction;
    toggleOrderedList: VoidFunction;
    // embeds
    showEmbedModal: (type: string, element?: Node, at?: Location) => void;
    hideEmbedModal: (type: string) => void;
    insertDynamicTeaser: (at?: Location) => void;
    isEmbedModalVisible: (
        type: string,
    ) =>
        | { type: PluginName; element: any; at: any; isModalOpen: boolean; selection?: Location }
        | undefined;
    insertImage: (at?: Location) => void;
    isInterviewModalVisible: () =>
        | { element: any; at: any; isEditMode: boolean; path: any; isModalOpen: boolean }
        | undefined;
    isImportInterviewModalVisible: () =>
        | { element: any; at: any; isEditMode: boolean; path: any }
        | undefined;
    insertLayoutComponent: (formData: any, type: string, options: any) => void;
    insertEmbedComponent: (formData: any, type: string, options: any) => void;
    insertInfobox: (at?: Location) => void;
    insertPoll: (at?: Location) => void;
    // interview
    insertInterview: (at?: Location) => void;
    // embedded content
    insertEmbeddedContent: (at?: Location) => void;
    // interviewSegment
    showInterviewModal: (element?: Element, isEditMode?: boolean, at?: Location) => void;
    showImportInterviewModal: (element?: Element, isEditMode?: boolean, at?: Location) => void;
    hideInterviewModal: () => void;
    hideImportInterviewModal: () => void;
    // quote
    insertQuote: (at?: Location) => void;
    // separator
    insertSeparator: (at?: Location) => void;
    // summary
    insertSummary: (at?: Location) => void;
    insertSummaryListSummary: (options: {
        at: Location;
        list: string[];
        element: SummaryListElement;
    }) => void;
    // tickerSummary
    insertTickerSummary: (at?: Location) => void;
    // paragraph
    isParagraphActive: () => boolean;
    isSectionActive: () => boolean;
    isSubsectionActive: () => boolean;
    isOrderedListicleActive: () => boolean;
    isUnorderedListicleActive: () => boolean;
    isFooterActive: () => boolean;
    preserveFloatingToolbarHeight?: boolean;
    toParagraph: VoidFunction;
    toFooter: VoidFunction;
    toggleCrosshead: (style: CrossheadStyle) => void;
    // wrapText
    wrapText: (text: string | string[]) => void;
};

export type GlobalOptions = {
    contentLocale: LangCode;
    reducedUI: boolean;
    t: TFunction;
    fileHead: FileHead;
    uploadFile: UploadFile;
    generateImageAltText: GenerateImageAltText;
    tenantIds?: number[];
};

export type Editor = ReactEditor & HistoryEditor & GlobalOptions & WithPluginsEditor;

interface IEditor {
    isMarkActive: (editor: Editor, key: string) => boolean;
    toggleMark: (editor: Editor, mark: string, single?: boolean) => void;
    elements: <T extends Node>(
        editor: Editor,
        options?: Options,
    ) => Generator<NodeEntry<T>, void, undefined> | [];
    blocks: <T extends Node>(
        editor: Editor,
        options?: Options,
    ) => Generator<NodeEntry<T>, void, undefined> | [];
    containsElementOfType: (editor: Editor, types: ElementType | ElementType[]) => boolean;
    isElementActive: (editor: Editor, types: string | string[], options?: Options) => boolean;
    startElement: (editor: Editor, options?: Options) => NodeEntry | undefined;
    focusBlock: (editor: Editor, options?: Options) => NodeEntry | undefined;
    voidNodes: <T extends Node>(editor: Editor) => Generator<NodeEntry<T>, void, undefined> | [];
    insertElement: (
        editor: Editor,
        element: Element | Element[],
        options?: InsertElementOptions,
    ) => void;
    inlines: <T extends Node>(
        editor: Editor,
        options?: Options,
    ) => Generator<NodeEntry<T>, void, undefined> | [];
    getFirstInline: (editor: Editor, options?: Options) => Node | undefined;
    isInlineActive: (editor: Editor, types: string | string[]) => boolean;
    isVoid: (editor: Editor, value: any) => boolean;
    isVoidNode: (editor: Editor, at: Location | null) => boolean;
    isSelectionEmpty: (editor: Editor) => boolean;
    isSelectionOnEmptyLine: (editor: Editor) => boolean;
    isEmbedCaptionSelected: (editor: Editor) => boolean;
    isEmbedCreditSelected: (editor: Editor) => boolean;
    isInterviewSegmentQuestionSelected: (editor: Editor) => boolean;
    isInterviewSegmentAnswerSelected: (editor: Editor) => boolean;
    isLinkSelected: (editor: Editor) => boolean;
    isPollQuestionSelected: (editor: Editor) => boolean;
    isPollAnswerSelected: (editor: Editor) => boolean;
    isQuoteTextSelected: (editor: Editor) => boolean;
    isQuoteCaptionSelected: (editor: Editor) => boolean;
    isInfoboxTitleSelected: (editor: Editor) => boolean;
    isCrossheadSelected: (editor: Editor) => boolean;
    isDynamicTeaserTitleSelected: (editor: Editor) => boolean;
    isSummaryListSummarySelected: (editor: Editor) => boolean;
    isInlineElementSelected: (editor: Editor) => boolean;
    isSelectionFollowedByVoidNode: (editor: Editor) => boolean;
    isSelectionPrecededByVoidNode: (editor: Editor) => boolean;
    isVoidNodeOnlySelected: (editor: Editor) => boolean;
    isSelectionAtEndOfElement: (editor: Editor) => boolean;
    isSelectionAtStartOfElement: (editor: Editor) => boolean;
}

/** Marks **/

export const removeMarks = (editor: Editor, exclude: string[] = []): void => {
    // Exclude marks, returned from `Editor.marks`, which are defined in `exclude`
    const marks = omit(Editor.marks(editor), exclude);

    // Loop trough marks and remove any active mark
    forEach(marks, (value, key) => {
        // Only remove active marks
        if (value === true) {
            Editor.removeMark(editor, key);
        }
    });
};

export const addMark = (editor: Editor, mark: string, value: any = true, single = false): void => {
    // If only 1 mark is allowed for this node, remove all the others first
    if (single) {
        removeMarks(editor, [mark]);
    }

    Editor.addMark(editor, mark, value);
};

/** Elements **/

export const getPointByEdge = (range: Location | Span, edge?: Edge): Location | Span => {
    if (edge) {
        if (Range.isRange(range)) {
            if (edge === 'focus' || edge === 'anchor') {
                return range[edge];
            } else if (edge === 'start' || edge === 'end') {
                return Range[edge](range);
            }
        } else if (Span.isSpan(range)) {
            if (edge === 'start' || edge === 'anchor') {
                return range[0];
            } else if (edge === 'end' || edge === 'focus') {
                return range[1];
            }
        }
    }

    return range;
};

export const Editor: Omit<typeof SlateEditor, 'isVoid'> & IEditor = {
    ...SlateEditor,

    /** Marks **/

    isMarkActive: (editor, key) => {
        const marks = Editor.marks(editor);

        return Boolean(marks?.[key]);
    },

    toggleMark: (editor, mark, single) => {
        const isActive = Editor.isMarkActive(editor, mark);

        if (isActive) {
            Editor.removeMark(editor, mark);
        } else {
            addMark(editor, mark, undefined, single);
        }
    },

    /** Elements **/

    elements: (editor, options = {}) => {
        const { edge, at = editor.selection, types, ...otherOptions } = options;
        const castedTypes = castArray<string>(types);

        if (at) {
            return Editor.nodes(editor, {
                at: getPointByEdge(at, edge),
                ...otherOptions,
                match: (node: Node, path: Path) =>
                    Element.isElement(node) &&
                    (!options.match || options.match(node, path)) &&
                    (typeof types === 'undefined' || castedTypes.includes(node.type)),
            });
        }

        return [];
    },

    blocks: (editor, options = {}) =>
        Editor.elements(editor, {
            ...options,
            match: (node: Node, path: Path) =>
                Editor.isBlock(editor, node) && (!options.match || options.match(node, path)),
        }),

    containsElementOfType: (editor, types) => {
        const [firstMatch] = Editor.elements(editor, { at: [], types });

        return Boolean(firstMatch);
    },

    isElementActive: (editor, types, options) => {
        const [match] = Editor.elements(editor, {
            ...options,
            types,
        });

        return Boolean(match);
    },

    startElement: (editor, options = {}) => {
        const [firstMatch] = Editor.elements(editor, {
            ...options,
            edge: 'start',
        });

        if (firstMatch) {
            return firstMatch;
        }
    },

    focusBlock: (editor, options = {}) => {
        const [firstMatch] = Editor.blocks(editor, {
            ...options,
            edge: 'focus',
            mode: 'lowest',
        });

        if (firstMatch) {
            return firstMatch;
        }
    },

    voidNodes: (editor) =>
        Editor.elements(editor, {
            match: (node) => Editor.isVoid(editor, node),
            mode: 'highest',
        }),

    // Since slate is managing nodes internally by reference, we need to make sure to deep clone any node before
    // inserting it into the document tree. Otherwise nodes get mixed up.
    // If you insert for example a `DEFAULT_BLOCK` on the first line of the slate document and on the last,
    // they would get, internally, the same key, since it is the same object reference (`DEFAULT_BLOCK` constant,
    // located in the `@@editor/constants.js` file).
    // So always use the `Element.create` function before inserting an element!
    // Example: `editor.insertElement(Element.create(...))`
    insertElement: (editor, element, options = {}) => {
        const { at, replace = false, select = true, mode } = options;

        if (replace) {
            if (!Array.isArray(element)) {
                // Make sure normalization is not messing up our selection
                Editor.withoutNormalizing(editor, () => {
                    // `setNodes` does not set selection
                    Transforms.setNodes<Element>(editor, element, { at });

                    if (at) {
                        Transforms.select(editor, at);
                    }
                });
            } else {
                throw new Error(
                    '`insertElement` does not support replacing multiple elements at once',
                );
            }
        } else {
            // Insert node at desired location and maybe select it
            Transforms.insertNodes(editor, element, { at, select, mode });
        }
    },

    /** Inlines **/

    inlines: (editor, options = {}) => {
        const { edge, at = editor.selection, types, ...otherOptions } = options;
        const castedTypes = castArray<string>(types);

        if (at) {
            return Editor.nodes(editor, {
                at: getPointByEdge(at, edge),
                ...otherOptions,
                match: (node) =>
                    Editor.isInline(editor, node) &&
                    (typeof types === 'undefined' || castedTypes.includes(node.type)),
            });
        }

        return [];
    },

    getFirstInline: (editor, options) => {
        const [firstInlineMatch] = Editor.inlines(editor, options);

        if (firstInlineMatch) {
            return firstInlineMatch[0];
        }
    },

    isInlineActive: (editor, types) => {
        const [match] = Editor.inlines(editor, { types });

        return Boolean(match);
    },

    /** Void nodes **/

    isVoidNode: (editor, at) => {
        if (!at) {
            return false;
        }

        const selectedVoidElements = Array.from(
            Editor.elements(editor, {
                at,
                match: (node) => Editor.isVoid(editor, node),
                mode: 'highest',
            }),
        );

        // Find out if the current selection is on one single void element
        if (selectedVoidElements.length === 1) {
            const [[, path]] = selectedVoidElements;

            // Gets the range (start to end) for the selected void element
            const range = Editor.range(editor, path);

            return Range.includes(range, at);
        }

        return false;
    },

    /** Selection **/

    isSelectionEmpty: ({ selection }) => !selection || Range.isCollapsed(selection),

    isSelectionOnEmptyLine: (editor) => {
        const { selection } = editor;
        const startElement = Editor.startElement(editor);

        if (startElement) {
            const [node] = startElement;

            return Boolean(
                selection &&
                    Path.equals(selection.anchor.path, selection.focus.path) &&
                    (!node || Element.isEmptyParagraphElement(node)),
            );
        }

        return false;
    },

    isEmbedCaptionSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, { match: Element.isEmbedCaptionElement });

        return Boolean(firstMatch);
    },

    isEmbedCreditSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, { match: Element.isEmbedCreditElement });

        return Boolean(firstMatch);
    },

    isInterviewSegmentQuestionSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isInterviewSegmentQuestionElement,
        });

        return Boolean(firstMatch);
    },

    isInterviewSegmentAnswerSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isInterviewSegmentAnswerElement,
        });

        return Boolean(firstMatch);
    },

    isLinkSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isLinkElement,
        });

        return Boolean(firstMatch);
    },

    isPollQuestionSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isPollQuestionElement,
        });

        return Boolean(firstMatch);
    },

    isPollAnswerSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isPollAnswerElement,
        });

        return Boolean(firstMatch);
    },

    isQuoteTextSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isQuoteTextElement,
        });

        return Boolean(firstMatch);
    },

    isQuoteCaptionSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isQuoteCaptionElement,
        });

        return Boolean(firstMatch);
    },

    isInfoboxTitleSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isInfoboxTitleElement,
        });

        return Boolean(firstMatch);
    },

    isCrossheadSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isCrossheadElement,
        });

        return Boolean(firstMatch);
    },

    isDynamicTeaserTitleSelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isDynamicTeaserTitleElement,
        });

        return Boolean(firstMatch);
    },

    isSummaryListSummarySelected: (editor) => {
        const [firstMatch] = Editor.elements(editor, {
            match: Element.isSummaryListSummaryElement,
        });

        return Boolean(firstMatch);
    },

    isInlineElementSelected: (editor) =>
        Editor.isEmbedCaptionSelected(editor) ||
        Editor.isEmbedCreditSelected(editor) ||
        Editor.isPollQuestionSelected(editor) ||
        Editor.isPollAnswerSelected(editor) ||
        Editor.isInterviewSegmentQuestionSelected(editor) ||
        Editor.isInterviewSegmentAnswerSelected(editor) ||
        Editor.isQuoteTextSelected(editor) ||
        Editor.isQuoteCaptionSelected(editor) ||
        Editor.isInfoboxTitleSelected(editor) ||
        Editor.isDynamicTeaserTitleSelected(editor) ||
        Editor.isSummaryListSummarySelected(editor),

    isVoidNodeOnlySelected: (editor) => Editor.isVoidNode(editor, editor.selection),

    isSelectionFollowedByVoidNode: (editor) => {
        if (!editor.selection) {
            return false;
        }

        const pointAfter = Editor.after(editor, editor.selection, {
            unit: 'offset',
            voids: true,
        });

        if (pointAfter) {
            return Editor.isVoidNode(editor, pointAfter);
        }

        return false;
    },

    isSelectionPrecededByVoidNode: (editor) => {
        if (!editor.selection) {
            return false;
        }

        const pointBefore = Editor.before(editor, editor.selection, {
            unit: 'offset',
            voids: true,
        });

        if (pointBefore) {
            return Editor.isVoidNode(editor, pointBefore);
        }

        return false;
    },

    isSelectionAtEndOfElement: (editor) => {
        if (editor.selection && Range.isCollapsed(editor.selection)) {
            const selectionStart = Range.start(editor.selection).path;
            const elementNodeEntry =
                selectionStart &&
                Editor.above(editor, {
                    at: selectionStart,
                    match: Element.isTextElement,
                    mode: 'lowest',
                });

            const elementPath = elementNodeEntry?.[1];

            return Boolean(
                elementPath && Editor.isEnd(editor, editor.selection.anchor, elementPath),
            );
        }

        return false;
    },

    isSelectionAtStartOfElement: (editor) => {
        if (editor.selection && Range.isCollapsed(editor.selection)) {
            const selectionStart = Range.start(editor.selection).path;
            const elementNodeEntry =
                selectionStart &&
                Editor.above(editor, {
                    at: selectionStart,
                    match: Element.isTextElement,
                    mode: 'lowest',
                });

            const elementPath = elementNodeEntry?.[1];

            return Boolean(
                elementPath && Editor.isStart(editor, editor.selection.anchor, elementPath),
            );
        }

        return false;
    },
};
