import isPropValid from '@emotion/is-prop-valid';
import { styled } from '@mui/material';
import { type CSSProperties, forwardRef, type PropsWithChildren } from 'react';

import Loader from '@@components/Loader';
import { useLoadingStatusManager } from '@@containers/LoadingStatusManager/LoadingStatusManagerContext';
import { generateKeyForRichTextEditorLoadingStatus } from '@@containers/LoadingStatusManager/utils';
import { type Editor, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type ParagraphElement } from '@@editor/helpers/Element';

const StyledLoader = styled(Loader)(({ isLoading }) => ({
    ...(isLoading && {
        textAlign: 'center',
    }),
}));

export const P = styled('p', { shouldForwardProp: (prop: string) => isPropValid(prop) })(
    ({ theme }) => ({
        position: 'relative',
        margin: 0,
        ...theme.fixed.editor.paragraph.font,
    }),
);

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element: ParagraphElement;
    style?: CSSProperties;
}>;

const Paragraph = forwardRef<HTMLElement, Props>((props, ref) => {
    const { editor, attributes, children, style, element } = props;
    const readOnly = ReactEditor.isReadOnly(editor);

    const key = generateKeyForRichTextEditorLoadingStatus({
        editorId: editor.id,
        loadingStatusId: element.data?.loadingStatusId,
    })!;

    // If `isLoading` is undefined, the `Loader` component would use `true` as a default value,
    // which is not what we want. Therefore, we use `false` explicitly, if no loading status is available.
    const { isLoading = false } = useLoadingStatusManager({ key });

    return (
        <P {...{ ref, style, ...attributes, readOnly }}>
            <StyledLoader isLoading={isLoading} keepChildrenInDom>
                {children}
            </StyledLoader>
        </P>
    );
});

export default Paragraph;
