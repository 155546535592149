import { type Middleware } from 'redux';

import config from '@@config';
import { HTTP_STATUS_CODES } from '@@constants/http';

const checkAuthentication: Middleware = () => (next) => (action: any) => {
    if (action.error && action.payload && action.payload.error) {
        const error = action.payload.error;

        if (error.response && error.response.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
            window.location.href = config.authenticationApiUrl;
        }
    }

    return next(action);
};

export default checkAuthentication;
