import { Transforms } from 'slate';

import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import deleteBackward from '@@editor/plugins/utils/deleteBackward';
import deleteForward from '@@editor/plugins/utils/deleteForward';
import deleteFragment from '@@editor/plugins/utils/deleteFragment';
import {
    normalizeInlineEditableElement,
    preventDeleteBackward,
    preventDeleteForward,
    preventDeleteFragment,
    preventInsertBreak,
} from '@@editor/plugins/utils/inlineEditing';
import insertBreak from '@@editor/plugins/utils/insertBreak';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';
import renderElement from '@@editor/plugins/utils/renderElement';
import makeState from '@@editor/utils/makeState';

import { createGenerateLayoutBlock } from '../utils';
import QuoteCaption from './components/QuoteCaption';
import QuoteNode, { INLINE_EDITABLE_CHILDREN_TYPES, NODE_TYPE, TYPE } from './components/QuoteNode';
import QuoteText from './components/QuoteText';

const emptyQuote = {
    quote: makeState(),
    caption: makeState(),
};

const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

const withQuote = (editor, options) =>
    Object.assign(editor, {
        normalizeNode: normalizeNode(editor, [
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.QUOTE,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
        deleteForward: deleteForward(editor, [
            [preventDeleteForward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteBackward: deleteBackward(editor, [
            [preventDeleteBackward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteFragment: deleteFragment(editor, [
            [preventDeleteFragment, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        insertBreak: insertBreak(editor, [
            [preventInsertBreak, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        renderElement: renderElement(
            editor,
            [
                [NODE_TYPE, QuoteNode],
                [ELEMENT_TYPES.QUOTE_TEXT, QuoteText],
                [ELEMENT_TYPES.QUOTE_CAPTION, QuoteCaption],
            ],
            {
                ...options,
            },
        ),
        insertQuote: (at) => {
            generateLayoutBlock(editor, emptyQuote, {
                at,
                replace: false,
            });
            Transforms.select(editor, at);
            Transforms.collapse(editor);
        },
    });

export default withQuote;
