import { useTranslation } from 'react-i18next';

import { useTenantClient } from '@@api/services/tenant/client';
import { type Category } from '@@api/services/tenant/schemas';

import BasicLinkPreview from './BasicLinkPreview';

type Props = {
    id: Category['id'];
};

export const CategoryPreview: React.FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const { client: tenantClient, queryKeys: tenantKeys } = useTenantClient();

    const params = { id };

    const { data, isLoading } = tenantClient.category.get.useQuery({
        queryKey: tenantKeys.category.get({ params }),
        queryData: { params },
        enabled: Boolean(id),
    });

    if (!id) {
        return null;
    }

    const category = data?.body;

    return (
        <BasicLinkPreview
            isLoading={isLoading}
            title={category?.name}
            iconName="inbox"
            label={t('link.categoryLink.title')}
        />
    );
};

export default CategoryPreview;
