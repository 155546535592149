import { type AppRoute, type ClientInferResponses } from '@ts-rest/core';
import { isEmpty } from 'lodash-es';
import { type z, ZodError } from 'zod';

import * as ApiErrorCodes from '@@api/constants/errorCodes';
import { HTTP_STATUS_CODES } from '@@constants/http';
import i18n from '@@lib/i18n/i18n';
import { isPlacementCopySectionError, isPlacementTenantError } from '@@routes/placement/utils';

import { type commonResponses } from './commonResponses';
import { FetcherValidationError } from './FetcherValidationError';
import {
    type ApiErrorV1 as ApiErrorV1Schema,
    type ApiErrorV2 as ApiErrorV2Schema,
    type ApiErrorV3 as ApiErrorV3Schema,
} from './utils/schemas/errors';
import { showErrorMessage } from './utils/showErrorMessage';

type ApiErrorBase = ClientInferResponses<AppRoute, keyof typeof commonResponses>;
export type ApiErrorV1 = Omit<ApiErrorBase, 'body'> & { body: z.infer<typeof ApiErrorV1Schema> };
export type ApiErrorV2 = Omit<ApiErrorBase, 'body'> & { body: z.infer<typeof ApiErrorV2Schema> };
export type ApiErrorV3 = Omit<ApiErrorBase, 'body'> & { body: z.infer<typeof ApiErrorV3Schema> };

export const isApiError = (error: unknown): error is ApiErrorBase =>
    typeof error === 'object' && error !== null && 'body' in error && error.body !== undefined;
const isApiErrorFormatV1 = (error: unknown): error is ApiErrorV1 =>
    isApiError(error) && Boolean(error.body.messages);
const isApiErrorFormatV2 = (error: unknown): error is ApiErrorV2 =>
    isApiError(error) && Boolean(error.body.message);
const isApiErrorFormatV3 = (error: unknown): error is ApiErrorV3 =>
    isApiError(error) && Boolean(error.body.errors?.length);

export const isRtpValidationError = (data) =>
    !isEmpty(data.errors) && typeof data.errors[0] === 'string';

const isCheckNameError = (error): boolean =>
    error?.body?.message === 'tag.form.asyncvalidate.reject';

const isTranslateArticleContentError = (error) =>
    error?.body?.path?.includes('content/articles?sourceId');
const isSeoCheckError = (error): boolean => error?.body?.message?.includes('No SEO check result');

export const isSpellCheckerConnectionTimedOutError = (error: unknown) =>
    isApiErrorFormatV2(error) &&
    error.status === (HTTP_STATUS_CODES.CONNECTION_TIMED_OUT as number) &&
    error.body.message.includes('Spellchecker');

export const isSpellCheckerBandwidthLimitExceededError = (error: unknown) =>
    isApiErrorFormatV2(error) &&
    error.status === (HTTP_STATUS_CODES.BANDWIDTH_LIMIT_EXCEEDED as number) &&
    error.body.message.includes('Spellchecker');

export const isSpellCheckerError = (error: unknown) =>
    isSpellCheckerConnectionTimedOutError(error) ||
    isSpellCheckerBandwidthLimitExceededError(error);

const isApiErrorHandledElsewhere = (error: unknown) =>
    isCheckNameError(error) ||
    isTranslateArticleContentError(error) ||
    isSpellCheckerError(error) ||
    isSeoCheckError(error) ||
    (isApiError(error) &&
        (error.status === HTTP_STATUS_CODES.UNAUTHORIZED ||
            error.status === HTTP_STATUS_CODES.PRECONDITION_FAILED ||
            error.status === HTTP_STATUS_CODES.UNPROCESSABLE_CONTENT ||
            (isApiErrorFormatV3(error) &&
                (isRtpValidationError(error.body) || isSeoCheckError(error))) ||
            (isApiErrorFormatV2(error) &&
                (isPlacementTenantError(error.body) || isPlacementCopySectionError(error.body)))));

const isVideoPreviewImageError = (error) =>
    isApiError(error) &&
    error.status === ApiErrorCodes.BAD_REQUEST &&
    error.body.errors.some(
        (error) => error.defaultMessage === 'No ElvisId provided on the PreviewImage',
    );

const isTeaserImageDescriptionError = (error) =>
    isApiError(error) &&
    error.body.errors.every(
        (error) =>
            error.fieldNames &&
            error.fieldNames.length === 1 &&
            error.fieldNames[0].startsWith('teasers') &&
            error.fieldNames[0].endsWith('image.name'),
    );

const errorHandler = (error: unknown) => {
    if (
        import.meta.env.MODE === 'development' &&
        !isApiError(error) &&
        !(error instanceof FetcherValidationError)
    ) {
        // In some cases, errors are intercepted and not printed in the devtool's console,
        // making it very hard to debug. So we log them ourselves.
        // We do not log Api errors because react-query takes care of that.
        console.error(error);
    }

    if (isApiErrorHandledElsewhere(error)) {
        // Some api errors are handled directly, for example, by the form

        return;
    }

    if (typeof error === 'string') {
        showErrorMessage({ message: error });

        return;
    }

    if (isApiErrorFormatV3(error)) {
        // New unified generic error format not yet implemented in every microservice/endpoints

        if (isVideoPreviewImageError(error)) {
            showErrorMessage({ message: i18n.t('videoUpload.error.noPreviewImage') });

            return;
        }

        if (isTeaserImageDescriptionError(error)) {
            showErrorMessage({ message: i18n.t('metaform.teaser.imageDescription.size.error') });

            return;
        }

        let containsFieldErrors = false;

        error.body.errors.forEach((error) => {
            if (typeof error !== 'string' && 'fieldNames' in error) {
                containsFieldErrors = true;
            } else {
                showErrorMessage(error);
            }
        });

        if (containsFieldErrors) {
            showErrorMessage({ message: i18n.t('form.fields.error') });
        }

        return;
    }

    if (isApiErrorFormatV2(error)) {
        // Old generic error format implemented by some microservices/endpoints
        // Used in response of:
        // - PUT https://staging.unitycms.io/api/tenant/tenants/1/sitemaps (when moving nodes with
        //   identical slug to the same parent. Maybe remove this when CD2-5923 has been implemented)

        showErrorMessage({
            title: error.body.error,
            message: error.body.message,
        });

        return;
    }

    if (isApiErrorFormatV1(error)) {
        // Old generic error format implemented by most microservices/endpoints

        const body = error.body;

        showErrorMessage({
            title: ApiErrorCodes[body.errorCode] || body.errorCode,
            message: body.messages[0].message,
        });

        return;
    }

    if (error instanceof ZodError) {
        console.error(error.issues);

        showErrorMessage({
            title: 'Zod Error',
            message: "Data doesn't match the schema. Check the console for more information.",
        });

        return;
    }

    // Generic error handler for example if an error happens in the code, in redux etc.
    // or for api errors which were not handled by the api error handlers above
    if (error instanceof Error) {
        showErrorMessage({
            title: error.name,
            message: error.message,
        });

        return;
    }

    showErrorMessage({
        title: 'Unknown error',
        message: 'An unknown error occurred.',
    });
};

export default errorHandler;
