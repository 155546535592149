import { Box, styled, Tab, Tabs } from '@mui/material';
import { ellipsis } from 'polished';
import { useTranslation } from 'react-i18next';

import { type AuthRouter } from '@@api/services/auth/client';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Pagination from '@@components/Pagination';
import RelativeBadge from '@@components/RelativeBadge';
import Spacer from '@@components/Spacer';
import UnitySearch from '@@containers/Search/UnitySearch';
import useUnitySearch from '@@containers/Search/useUnitySearch';
import { getDefaultVariant } from '@@containers/TenantSpecific/arrayUtils';
import useUserTabs from '@@containers/users/useUserTabs';
import SelectionTableField from '@@form/fields/SelectionTable';

import { PAGE_SIZE } from './constants';
import { SelectionTableFieldWrapper } from './styles';

const FullName = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    ...ellipsis('140px'),
    padding: theme.spacing(3),
}));

const UserSelectionTableField = () => {
    const { t } = useTranslation();

    const search = useUnitySearch({
        disableUrlSync: true,
        fields: {
            q: true,
        },
        moreFilters: false,
    });

    const page = search.pageNumbers[0];

    const params = getQueryParams<AuthRouter['users']['getAll']>({
        q: search.debouncedValues.q,
        serviceUser: false,
        size: PAGE_SIZE,
        page: page - 1,
    });

    const { selectedTab, setSelectedTab, tabs, users } = useUserTabs(params);

    const columns: TableColumn[] = [
        {
            title: t('user.fullName'),
            getValue: ({ record }) => ({
                firstName: getDefaultVariant(record.variants).firstName,
                lastName: getDefaultVariant(record.variants).lastName,
            }),
            verticalAlign: 'top',
            width: '200px',
            render: ({ value }) => {
                if (value && value.firstName && value.lastName) {
                    const fullName = `${value.firstName} ${value.lastName}`;

                    return <FullName title={fullName}>{fullName}</FullName>;
                }
            },
        },
        {
            title: t('user.shortName'),
            getValue: ({ record }) => getDefaultVariant(record.variants).shortName,
            verticalAlign: 'top',
            width: '150px',
        },
        {
            title: t('user.jobTitle'),
            getValue: ({ record }) => getDefaultVariant(record.variants).jobTitle,
            verticalAlign: 'top',
            width: '250px',
        },
    ];

    return (
        <Box>
            <UnitySearch {...search.unitySearchProps} />

            <Spacer v lg />

            <Tabs value={selectedTab}>
                {tabs.map((tab) => (
                    <Tab
                        key={tab.value}
                        value={tab.value}
                        onClick={() => {
                            setSelectedTab(tab.value);
                            search.handlePageChange?.(null, users.firstPageNumber);
                        }}
                        iconPosition="end"
                        label={
                            <>
                                {tab.label}
                                <Spacer h sm />
                                <RelativeBadge
                                    badgeContent={tab.badgeContent}
                                    color="primary"
                                    max={99999}
                                />
                            </>
                        }
                    />
                ))}
            </Tabs>

            <SelectionTableFieldWrapper>
                <SelectionTableField
                    name="authorId"
                    columns={columns}
                    tableData={users.content || []}
                />
            </SelectionTableFieldWrapper>

            <Spacer v lg />

            <Pagination
                current={page}
                first={users.firstPageNumber}
                last={users.totalPages || 0}
                getPageHref={search.getPageHref}
                onChange={search.handlePageChange}
            />
        </Box>
    );
};

export default UserSelectionTableField;
