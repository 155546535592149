import { merge } from 'lodash-es';
import { Transforms } from 'slate';

import { type FileHead } from '@@api/hooks/useFileHead';
import { type UploadFile } from '@@api/hooks/useUploadFile';
import { type FilerepoFile } from '@@api/services/metadata/schemas';
import { LoadingStatusManager } from '@@containers/LoadingStatusManager';
import {
    generateKeyForRichTextEditorLoadingStatus,
    generateLookupKeyForRichTextEditorLoadingStatuses,
} from '@@containers/LoadingStatusManager/utils';
import { Editor, Node, ReactEditor } from '@@editor/helpers';
import {
    type EmbedData,
    type EmbedElement,
    type ImageElement,
    NameSource,
} from '@@editor/helpers/Element';
import { validateImageFile } from '@@form/utils/validators/image';
import { decodeImage, isFile } from '@@scripts/utils';
import { type IImageTransformationsOutput } from '@@scripts/utils/buildImageTransformationUrl';
import { type AssetPayload } from '@@utils/assets';

import { hasAccessToNode, updateFileNodeData } from './utils';

const FETCH_HEADERS_TIMEOUT = 500;
const MAX_RETRIES = 10;

type DataToUpdate = Pick<FilerepoFile, 'id' | 'title'> &
    Pick<FilerepoFile['metadata'], 'name' | 'caption' | 'credit'> & {
        src: FilerepoFile['_links']['data']['href'];
    } & Pick<EmbedData, 'nameSource' | 'naturalWidth' | 'naturalHeight'>;

type UpdateEmbedData = (data: DataToUpdate) => EmbedData;

type Options = {
    fileHead: FileHead;
    updateEmbedData: UpdateEmbedData;
    uploadFile: UploadFile;
    updateSource?: boolean;
    buildThumbnailUrl: (block: EmbedElement) => {
        baseUrl: string;
        queryParams: IImageTransformationsOutput;
        url: string;
    };
};

export const uploadFileHelper = (options: Options) => {
    const upload = async (
        editor: Editor,
        block: EmbedElement,
        file: File | string | AssetPayload,
        previousNode?: Node,
    ) => {
        const path = ReactEditor.findPath(editor, block);
        const pathRef = Editor.pathRef(editor, path);
        const { loadingStatusId } = block.data;
        const { updateEmbedData, updateSource = true } = options;
        const errors = await validateImageFile(file);

        if (errors.length === 0) {
            const uploadFile = () =>
                new Promise<FilerepoFile>((resolve, reject) => {
                    const key = generateKeyForRichTextEditorLoadingStatus({
                        editorId: editor.id,
                        loadingStatusId,
                        type: 'uploadFile',
                    });

                    LoadingStatusManager.load({ key });

                    options
                        .uploadFile({
                            file,
                            onUploadProgress: (progressEvent) => {
                                // It does not make sense to show a progress bar for uploading files by url,
                                // since we are only sending the url to the backend, not the file. So we have no
                                // progress information related to the actual file size / upload.
                                if (isFile(file)) {
                                    LoadingStatusManager.loadProgress({
                                        key,
                                        progress: progressEvent.lengthComputable
                                            ? {
                                                  value: progressEvent.loaded,
                                                  max: progressEvent.total!,
                                                  progress:
                                                      (progressEvent.loaded /
                                                          progressEvent.total!) *
                                                      100,
                                              }
                                            : {
                                                  value: 0,
                                                  max: progressEvent.total!,
                                                  progress: 0,
                                              },
                                    });
                                }
                            },
                        })
                        .then((payload) => {
                            resolve(payload.body);

                            LoadingStatusManager.loadSuccess({
                                key,
                                data: payload.body,
                            });
                        })
                        .catch((error) => {
                            reject(error);

                            LoadingStatusManager.loadError({
                                key,
                                error: new Error('Error while uploading file'),
                            });
                        });
                });

            const generateThumbnail = (id: string, nextBlock: EmbedElement) =>
                new Promise((resolve) => {
                    const { url: thumbnailUrl, queryParams } = options.buildThumbnailUrl(nextBlock);

                    const key = generateKeyForRichTextEditorLoadingStatus({
                        editorId: editor.id,
                        loadingStatusId,
                        type: 'generateThumbnail',
                    });

                    let retries = 0;

                    LoadingStatusManager.load({
                        key,
                    });

                    const preloadThumbnail = (src: string) => {
                        const image = new Image();
                        image.src = src;
                        return decodeImage(image);
                    };

                    const fetchHeaders = () => {
                        const retry = () => {
                            retries++;

                            setTimeout(fetchHeaders, FETCH_HEADERS_TIMEOUT);
                        };

                        const next = () => {
                            // `preloadThumbnail`is not really needed here, because `EmbedComponentLoader` already
                            // deals with loading the image, but the UI will look better / less flickering if we
                            // preload here.
                            preloadThumbnail(thumbnailUrl).finally(() => {
                                resolve(undefined);

                                // In order to execute `loadSuccess` after resolving the promise, we need to wrap
                                // it in a `Promise.resolve`. We want to start the next loading state before
                                // ending the previous one, this is important for having a non-flickering UI.
                                Promise.resolve().then(() => {
                                    LoadingStatusManager.loadSuccess({
                                        key,
                                    });
                                });
                            });
                        };

                        options
                            .fileHead(id, queryParams)
                            .then((response) => {
                                const { headers } = response;
                                const isFallbackImage =
                                    headers.get('x-unity-fallback-image') === 'true';

                                if (isFallbackImage && retries < MAX_RETRIES) {
                                    retry();
                                } else {
                                    next();
                                }
                            })
                            .catch(() => {
                                if (retries < MAX_RETRIES) {
                                    retry();
                                } else {
                                    next();
                                }
                            });
                    };

                    fetchHeaders();
                });

            return uploadFile()
                .then((filerepoFile) => {
                    if (!hasAccessToNode(editor, pathRef, block.data)) {
                        return Promise.reject(new Error('Image upload was cancelled #1'));
                    }

                    const { id, title, _links, mediaType, metadata, height, width } = filerepoFile;
                    const payloadSrc = _links?.data.href;
                    const { src, mimetype, originalSrc } = (block as ImageElement).data;
                    const { caption, credit, name } = metadata;

                    const node = Node.get(editor, pathRef.current);

                    const nextBlock = {
                        ...node,
                        data: merge({}, node.data, {
                            mimetype: mimetype || mediaType,
                            src: updateSource ? payloadSrc : src,
                            originalSrc: typeof originalSrc === 'string' ? originalSrc : null,
                            embed: updateEmbedData({
                                src: payloadSrc,
                                id,
                                title,
                                caption,
                                credit,
                                name,
                                nameSource: NameSource.ASSETS,
                                naturalHeight: height,
                                naturalWidth: width,
                            }),
                        }),
                    };

                    return generateThumbnail(id, nextBlock).then(() => {
                        if (!hasAccessToNode(editor, pathRef, block.data)) {
                            return Promise.reject(new Error('Image upload was cancelled #2'));
                        }

                        Transforms.setNodes(editor, nextBlock, { at: pathRef.current });
                    });
                })
                .catch((error) => {
                    updateFileNodeData(editor, pathRef, {
                        ...(previousNode?.data || {}),
                    });

                    throw error;
                });
        }

        updateFileNodeData(editor, pathRef, {
            ...(previousNode?.data || {}),
        });

        return Promise.resolve();
    };

    const cancelUpload = (editor: Editor, block: EmbedElement) => {
        const { loadingStatusId } = block.data;

        const key = generateLookupKeyForRichTextEditorLoadingStatuses({
            editorId: editor.id,
            loadingStatusId,
        });

        LoadingStatusManager.cancelLoading({ key });
    };

    return { upload, cancelUpload };
};
