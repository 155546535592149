import { styled } from '@mui/material';
import { type TFunction } from 'i18next';
import { omit } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { deserializeImage, serializeImage } from '@@api/utils/transformers';
import { useLoadingStatusManager } from '@@containers/LoadingStatusManager/LoadingStatusManagerContext';
import { generateKeyForRichTextEditorLoadingStatus } from '@@containers/LoadingStatusManager/utils';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import {
    type EnhancedUseFormReturn,
    type UseReactHookFormProps,
} from '@@containers/ReactHookForm/types';
import { type Editor } from '@@editor/helpers';
import { Element, type ImageElement } from '@@editor/helpers/Element';
import createImage from '@@form/components/ImageEditor/createImage';
import ImageField from '@@form/fields/ImageField';

import { MAX_HEIGHT, MAX_WIDTH } from '../../constants';

const DEFAULT_STYLE_IMAGE_TYPE = 'image';

// element data needs to be transformed back into form data
const transformElementToFormValues = (element) => {
    // default
    if (!element) {
        return { embed: createImage({ style: { imageType: DEFAULT_STYLE_IMAGE_TYPE } }) };
    }

    const data = element.data;
    const image = data.embed;

    const newData = {
        embed: {
            ...omit(image, 'src'),
            style: {
                imageType: image.style?.imageType || DEFAULT_STYLE_IMAGE_TYPE,
            },
            url: Element.isTemplateElement(element) ? '' : data.src || image.url,
            mimetype: data.mimetype,
        },
        loadingStatusId: data.loadingStatusId,
    };

    return deserializeImage(newData, 'embed');
};

type Props = {
    className?: string;
    editor: Editor;
    element: ImageElement;
    renderButtons: (button: any) => JSX.Element;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
    t: TFunction;
};

type FormFieldsProps = Pick<Props, 'editor'> & EnhancedUseFormReturn;

const FormFields = ({ editor, resetField, watch }: FormFieldsProps) => {
    const { t } = useTranslation();
    const loadingStatusId = watch('loadingStatusId');
    const { isLoading } = useLoadingStatusManager<{ altText: string }>({
        // A rich text editor can contain more than one image with the same elvisId, but this is probably
        // not very often the case. We accept this limiting factor for now, since we have no unique
        // identifier per image element so far.
        key: generateKeyForRichTextEditorLoadingStatus({
            editorId: editor.id,
            loadingStatusId,
            type: 'generateImageAltText',
        }),
        onLoadSuccess: ({ data }) => {
            resetField('embed.name', { defaultValue: data.altText });
        },
    });

    return (
        <ImageField
            name="embed"
            label={t('editor.imageform.image')}
            noDropArea
            hasButtonGroup={false}
            showCaption={false}
            isGeneratingDescription={isLoading}
            showCredit={false}
            showImageType
            transformations={{ maxWidth: MAX_WIDTH, maxHeight: MAX_HEIGHT }}
        />
    );
};

export const EmbeddedContentForm = ({
    editor,
    onCancel,
    onSubmit,
    renderButtons,
    element,
    className,
}: Props) => {
    const handleClickOnCancel = () => {
        onCancel();
    };

    const handleSubmit: UseReactHookFormProps['onSubmit'] = (data, form, options) => {
        const newData = {
            ...data,
            embed: {
                ...omit(data.embed, ['caption', 'credit']),
            },
        };

        const serializedData = serializeImage(newData, 'embed');

        onSubmit(serializedData, form, options);
    };

    return (
        <ReactHookForm
            formName="EmbedImage"
            values={transformElementToFormValues(element)}
            onSubmit={handleSubmit}
            onCancel={handleClickOnCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            className={className}
        >
            {(props) => <FormFields {...{ ...props, editor }} />}
        </ReactHookForm>
    );
};

export default styled(EmbeddedContentForm)({
    width: '640px',
});
