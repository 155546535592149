import { noop } from 'lodash-es';
import {
    createContext,
    type KeyboardEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Transforms } from 'slate';

import { Editor, ReactEditor } from '@@editor/helpers';
import { isNode } from '@@scripts/utils/DOM';

type State = Editor[];

type ActionsState = {
    registerEditor: (editor: Editor) => void;
    unregisterEditor: (editor: Editor) => void;
};

const INITIAL_STATE = [];

const EditorGroupContext = createContext<State>(INITIAL_STATE);
const EditorGroupActionsContext = createContext<ActionsState>({
    registerEditor: noop,
    unregisterEditor: noop,
});

export const EditorGroupProvider = ({ children }: { children: React.ReactNode }) => {
    const [editors, setEditors] = useState<State>(INITIAL_STATE);

    useEffect(() => {
        const switchFocus = (event: StorageEvent) => {
            if (
                event.key &&
                (event.key.startsWith('focusPreviousEditor::') ||
                    event.key.startsWith('focusNextEditor::'))
            ) {
                const [action, editorId] = event.key.split('::');
                const editorIndex = editors.findIndex((editor) => editor.id === editorId);
                const editor = editors[editorIndex];

                if (action === 'focusPreviousEditor' && editorIndex > 0) {
                    const prevEditor = editors[editorIndex - 1];

                    ReactEditor.blur(editor);

                    Transforms.select(prevEditor, Editor.end(prevEditor, []));

                    requestAnimationFrame(() => {
                        ReactEditor.focus(prevEditor);
                    });
                } else if (action === 'focusNextEditor' && editorIndex < editors.length - 1) {
                    const nextEditor = editors[editorIndex + 1];

                    ReactEditor.blur(editor);

                    Transforms.select(nextEditor, Editor.start(nextEditor, []));

                    requestAnimationFrame(() => {
                        ReactEditor.focus(nextEditor);
                    });
                }
            }
        };

        window.addEventListener('storage', switchFocus);

        return () => {
            window.removeEventListener('storage', switchFocus);
        };
    }, [editors]);

    const actions = useMemo<ActionsState>(
        () => ({
            registerEditor: (editor: Editor) => {
                setEditors((editors) =>
                    [...editors, editor].sort((a, b) => {
                        try {
                            const domNodeA = ReactEditor.toDOMNode(a, a);
                            const domNodeB = ReactEditor.toDOMNode(b, b);

                            if (domNodeA && domNodeB) {
                                if (
                                    // Because the result returned by `compareDocumentPosition` is a bitmask,
                                    // the bitwise AND operator must be used for meaningful results.
                                    // https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
                                    // eslint-disable-next-line no-bitwise
                                    domNodeA.compareDocumentPosition(domNodeB) &
                                    Node.DOCUMENT_POSITION_FOLLOWING
                                ) {
                                    return -1;
                                }

                                return 1;
                            }
                        } catch (e) {
                            console.error(e);
                        }

                        return 0;
                    }),
                );
            },
            unregisterEditor: (editor: Editor) => {
                setEditors((editors) => editors.filter(({ id }) => id !== editor.id));
            },
        }),
        [],
    );

    return (
        <EditorGroupContext.Provider value={editors}>
            <EditorGroupActionsContext.Provider value={actions}>
                {children}
            </EditorGroupActionsContext.Provider>
        </EditorGroupContext.Provider>
    );
};

const isFirstElementSelected = (editor: Editor) => {
    if (!editor.selection) {
        return false;
    }
    const { path } = editor.selection.anchor;

    return path.every((num) => num === 0);
};

const isLastElementSelected = (editor: Editor) => {
    if (!editor.selection) {
        return false;
    }
    const { path } = editor.selection.anchor;

    return path[0] === editor.children.length - 1;
};

export const useEditorGroup = (editor: Editor) => {
    const editors = useContext(EditorGroupContext);
    const { registerEditor, unregisterEditor } = useContext(EditorGroupActionsContext);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            const editorIndex = editors.findIndex((editor) => {
                const domNode = ReactEditor.toDOMNode(editor, editor);

                return Boolean(
                    domNode &&
                        isNode(event.target) &&
                        domNode.contains(event.target) &&
                        domNode === event.target,
                );
            });

            if (editorIndex >= 0) {
                const editor = editors[editorIndex];

                if (
                    event.nativeEvent.key === 'ArrowUp' &&
                    !event.shiftKey &&
                    editorIndex > 0 &&
                    isFirstElementSelected(editor) &&
                    ReactEditor.isCursorOnFirstLine(editor)
                ) {
                    const event = new StorageEvent('storage', {
                        key: 'focusPreviousEditor::' + editor.id,
                    });

                    window.dispatchEvent(event);
                } else if (
                    event.nativeEvent.key === 'ArrowDown' &&
                    !event.shiftKey &&
                    editorIndex < editors.length - 1 &&
                    isLastElementSelected(editor) &&
                    ReactEditor.isCursorOnLastLine(editor)
                ) {
                    const event = new StorageEvent('storage', {
                        key: 'focusNextEditor::' + editor.id,
                    });

                    window.dispatchEvent(event);
                }
            }
        },
        [editors],
    );

    useEffect(() => {
        registerEditor(editor);

        return () => {
            unregisterEditor(editor);
        };
    }, [editor]);

    return { onKeyDown: handleKeyDown };
};
