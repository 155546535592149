import { styled } from '@mui/material';
import { type FC, useMemo } from 'react';
import { type Descendant } from 'slate';

import { type Tenant } from '@@api/services/tenant/schemas';
import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import { P } from '@@editor/plugins/serializable/text/paragraph/components/Paragraph';
import setupPlugins from '@@editor/plugins/setup';
import { PLUGIN_NAMES, type PluginList } from '@@editor/typings/UnityPlugins';
import useFetchMetadata from '@@form/hooks/useFetchMetadata';
import usePostSpellCheck from '@@form/hooks/usePostSpellCheck';

import RichTextEditorBase from './RichTextEditorBase';

type Props = {
    singleLine?: boolean;
    spellCheck?: boolean;
    tenantIds?: Tenant['id'][];
    value?: Descendant[];
    readOnly?: boolean;
    hideStickyToolbar?: boolean;
    hideFloatingToolbar?: boolean;
};

const CaptionEditor: FC<Props> = ({ tenantIds, ...props }) => {
    const pluginConfig: PluginList[] = [
        PLUGIN_CONFIG_TEMPLATES.autoReplaceText,
        PLUGIN_NAMES.PARAGRAPH,
        PLUGIN_NAMES.BOLD,
        PLUGIN_NAMES.ITALIC,
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        PLUGIN_NAMES.LINK,
        PLUGIN_CONFIG_TEMPLATES.softHyphen,
        PLUGIN_NAMES.SINGLE_LINE,
    ];

    if (props.spellCheck) {
        pluginConfig.push(PLUGIN_NAMES.SPELL_CHECKER);
    }

    const spellCheck = usePostSpellCheck();
    const fetchMetadata = useFetchMetadata();

    const plugins = useMemo(
        () =>
            setupPlugins(pluginConfig, {
                optionsPerPlugin: {
                    [PLUGIN_NAMES.SPELL_CHECKER]: {
                        spellCheck,
                        tenantIds,
                    },
                    [PLUGIN_NAMES.LINK]: {
                        fetchMetadata,
                    },
                    [PLUGIN_NAMES.INSERT_HTML]: {
                        fetchMetadata,
                    },
                },
            }),
        [],
    );

    const stickyToolbarButtons = [
        { name: PLUGIN_NAMES.BOLD },
        { name: PLUGIN_NAMES.ITALIC },
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        PLUGIN_NAMES.LINK,
        PLUGIN_CONFIG_TEMPLATES.softHyphen,
    ];

    return (
        <RichTextEditorBase
            {...props}
            minRows={1}
            plugins={plugins}
            stickyToolbarButtons={stickyToolbarButtons}
            hideFloatingToolbar
        />
    );
};

export const StyledCaptionEditor = styled(CaptionEditor)(({ theme }) => ({
    [`${P}`]: {
        ...theme.fixed.editor.embed.caption.font,
    },
}));

export default CaptionEditor;
