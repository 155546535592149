import isPropValid from '@emotion/is-prop-valid';
import { IconButton, Stack, styled, type Theme } from '@mui/material';
import { omit } from 'lodash-es';
import { type PropsWithChildren, useCallback } from 'react';
import { Transforms } from 'slate';
import { useFocused } from 'slate-react';
import invariant from 'tiny-invariant';

import Icon from '@@components/Icon';
import InfoTooltip from '@@components/InfoTooltip';
import Spacer from '@@components/Spacer';
import { type Editor, type Node, ReactEditor } from '@@editor/helpers';
import { type Element, type ElementAttributes } from '@@editor/helpers/Element';
import Draggable from '@@editor/plugins/dnd/Draggable';
import { type ToolbarAction, type ToolbarConfig } from '@@editor/typings/Embed';
import { type PluginName } from '@@editor/typings/UnityPlugins';

import Badge from './Badge';

const SideBar = styled(Stack, { shouldForwardProp: (prop: string) => isPropValid(prop) })<{
    $left?: boolean;
    $fullHeight?: boolean;
}>(({ theme, $left, $fullHeight = true }) => ({
    visibility: 'hidden',
    opacity: 0,
    transition: 'all 0.17s ease-in',
    alignItems: 'center',
    width: theme.fixed.editor.elementWrapper.width,
    padding: theme.spacing(1),
    flexShrink: 0,
    height: $fullHeight ? 'auto' : 'fit-content',
    borderRadius: $left
        ? `${theme.fixed.editor.elementWrapper.borderRadius} 0 0 ${theme.fixed.editor.elementWrapper.borderRadius}`
        : `0 ${theme.fixed.editor.elementWrapper.borderRadius} ${theme.fixed.editor.elementWrapper.borderRadius} 0`,
    userSelect: 'none',
}));

export type WidthType = 'full' | 'text' | 'small';

export const getMaxWidth = ({
    widthType,
    theme,
    includeSidebar,
}: {
    widthType: WidthType;
    theme: Theme;
    includeSidebar: boolean;
}) => {
    if (!includeSidebar) {
        switch (widthType) {
            case 'full':
                return theme.fixed.editor.embed.width;

            case 'text':
                return theme.fixed.editor.textElement.width;

            case 'small':
                return theme.fixed.editor.embed.smallWidth;
        }
    }

    switch (widthType) {
        case 'full':
            return `calc(${theme.fixed.editor.embed.width} + (${theme.fixed.editor.elementWrapper.width} * 2))`;

        case 'text':
            return `calc(${theme.fixed.editor.textElement.width} + (${theme.fixed.editor.elementWrapper.width} * 2))`;

        case 'small':
            return `calc(${theme.fixed.editor.embed.smallWidth} + (${theme.fixed.editor.elementWrapper.width} * 2))`;
    }
};

const ContentWrapper = styled('div')<{ $readOnly: boolean; $widthType: WidthType }>(
    ({ $readOnly, $widthType, theme }) => ({
        flexGrow: 1,
        position: 'relative',
        transition: 'background 0.17s ease-in',
        margin: '0 auto',
        maxWidth: getMaxWidth({ widthType: $widthType, theme, includeSidebar: $readOnly }),
    }),
);

const ToolbarWrapper = styled(Stack, { shouldForwardProp: (prop: string) => isPropValid(prop) })<{
    $widthType: WidthType;
}>(({ $widthType, theme }) => ({
    flexDirection: 'row',
    width: '100%',
    maxWidth: getMaxWidth({ widthType: $widthType, theme, includeSidebar: true }),
    '&:hover': {
        [`${SideBar}`]: {
            visibility: 'visible',
            opacity: 1,
        },
    },
}));

const PenIconWrapper = styled('span')(({ theme }) => ({
    // Prevents the pen icon from interfering with text selection
    // - `userSelect: 'none'` ensures the icon itself isn't selectable
    // - `pointerEvents: 'none'` prevents it from blocking interactions with the text
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.fixed.editor.penIcon.left,
    userSelect: 'none',
    pointerEvents: 'none',
}));

export const PenIcon = styled(({ className }: { className?: string }) => (
    <PenIconWrapper contentEditable={false} className={className}>
        <Icon name="pen-clip" color="secondary" />
    </PenIconWrapper>
))({});

const Wrapper = styled(Stack)({
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
});

const processDefaultActions = (
    actions: (ToolbarAction | string)[],
    config: { editor: Editor; element: Node; type?: PluginName },
): ToolbarAction[] => {
    const { editor, element, type } = config;

    invariant(
        editor && element,
        'Editor, element are required to process the default actions on toolbar.',
    );

    if (!actions) {
        return actions;
    }

    const isEditAction = actions.find((action) =>
        typeof action === 'string' ? action === 'edit' : action?.type === 'edit',
    );

    invariant(isEditAction ? type : true, 'Type is required for edit action on toolbar');

    const defaultActions = {
        edit: {
            type: 'edit',
            iconName: 'pen-regular',
            title: editor.t('editor.plugin.embed.toolbar.edit'),
            onClick: (e) => {
                // This prevents the browser from messing around with the focus
                e.preventDefault();

                // Use `requestAnimationFrame` in order to let the browser set the focus on the clicked
                // embed before we call `showEmbedModal`. This is important since `showEmbedModal` will
                // use the currently selected slate node to operate on.
                return requestAnimationFrame(() => editor.showEmbedModal(type!, element));
            },
        },
        delete: {
            type: 'delete',
            iconName: 'trash-can',
            title: editor.t('editor.plugin.embed.toolbar.delete'),
            // In order to keep the focus in the editor, we use onMouseDown here, so the user can just
            // continue typing after deleting an embed. With `onClick`, the browser moves the focus to
            // `<body></body>` after the button has been clicked.
            onMouseDown: (e) => {
                // This prevents the browser from messing around with the focus
                e.preventDefault();

                const path = ReactEditor.findPath(editor, element);

                Transforms.removeNodes(editor, { at: path });
            },
        },
    };

    return actions.map((action) => (typeof action === 'string' ? defaultActions[action] : action));
};

const rightSideActionsList = ['delete'];

type Props<ElementType> = PropsWithChildren<{
    editor: Editor;
    toolbarConfig: ToolbarConfig;
    readOnly: boolean;
    attributes?: ElementAttributes;
    element: ElementType;
    type?: PluginName;
    widthType?: WidthType;
}>;

const ElementWrapper = <ElementType extends Element>(props: Props<ElementType>) => {
    const {
        toolbarConfig,
        type,
        children,
        editor,
        element,
        attributes,
        readOnly,
        widthType = 'full',
    } = props;

    const { withDnd, withPenIcon } = editor;
    const path = ReactEditor.findPath(editor, element);
    const isFocused = useFocused();

    const showPenIcon = useCallback(() => {
        const focused = withPenIcon && isFocused && path.length === 1;

        // Call isElementSelected only when path.length === 1, otheriwse it will throw an error.
        return focused && ReactEditor.isElementSelected(editor, element);
    }, [isFocused, editor, path, element]);

    const { infos, actions, tooltips } = toolbarConfig || {};

    const elementContent = (
        <ContentWrapper $readOnly={readOnly} $widthType={widthType}>
            {showPenIcon() && <PenIcon />}
            {children}
        </ContentWrapper>
    );

    if (readOnly) {
        return elementContent;
    }

    const parsedActions = processDefaultActions(actions, { editor, element, type });

    const leftSideActions = parsedActions.filter(
        (action) => !rightSideActionsList.includes(action.type),
    );

    const rightSideActions = parsedActions.filter((action) =>
        rightSideActionsList.includes(action.type),
    );

    const renderActions = (actions: ToolbarAction[]) =>
        actions.length ? (
            <>
                {actions.map((actionProps, pos) => (
                    <IconButton
                        key={actionProps.iconName || `action-string-${pos}`}
                        size="small"
                        {...omit(actionProps, ['iconName', 'type'])}
                    >
                        <Icon name={actionProps.iconName} />
                    </IconButton>
                ))}
            </>
        ) : null;

    const renderTooltips = (tooltips: ToolbarConfig['tooltips']) =>
        tooltips && tooltips.length ? (
            <>
                {tooltips.map((props) => {
                    const tooltipProps = {
                        ...props,
                        iconColor: props.iconColor ?? 'primary.600',
                        hideSpacer: true,
                    };

                    return <InfoTooltip {...tooltipProps} key={crypto.randomUUID()} />;
                })}
            </>
        ) : null;

    const hasBadge = Boolean(infos);

    const elementWithToolbar = (
        <ToolbarWrapper $widthType={widthType} data-drag-preview>
            <SideBar $left $fullHeight={!hasBadge} contentEditable={false}>
                {hasBadge && (
                    <>
                        <Spacer xs v />
                        <Badge iconName={infos!.iconName} title={infos!.title || type!} />
                        <Spacer sm v />
                    </>
                )}

                {renderActions(leftSideActions)}

                {withDnd && tooltips ? <Spacer sm v /> : null}
                {withDnd && tooltips ? renderTooltips(tooltips) : null}
                {/* we need to make sure there is at least one child element in the SideBar, otherwise
                the browser might ignore the contentEditable={false} attribute, when element is empty */}
                <span contentEditable={false} />
            </SideBar>

            {elementContent}

            <SideBar contentEditable={false}>
                {withDnd && <Draggable.Handle />}

                {withDnd && rightSideActions.length ? <Spacer sm v /> : null}

                {rightSideActions.length ? renderActions(rightSideActions) : null}
            </SideBar>
        </ToolbarWrapper>
    );

    // The attributes must be added to the top-level DOM element inside the component https://docs.slatejs.org/concepts/09-rendering
    return withDnd ? (
        <Draggable attributes={attributes} element={element}>
            {({ ref, style, attributes }) => (
                <Wrapper {...{ ref, style, ...attributes }}>{elementWithToolbar}</Wrapper>
            )}
        </Draggable>
    ) : (
        <Wrapper {...attributes}>{elementWithToolbar}</Wrapper>
    );
};

export default ElementWrapper;
