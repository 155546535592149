import { type RouteObject } from 'react-router';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { ArticleList } = await import('@@routes/articles/ArticleList');

            return { Component: ArticleList };
        },
    },
    {
        path: 'new',
        lazy: async () => {
            const { ArticleSetupPage } = await import('@@routes/articles');

            return { Component: ArticleSetupPage };
        },
    },
    {
        path: ':id',
        lazy: async () => {
            const { ArticleDetailPage } = await import('@@routes/articles');

            return { Component: ArticleDetailPage };
        },
        children: [
            {
                path: '*',
                lazy: async () => {
                    const { ArticleDetailPage } = await import('@@routes/articles');

                    return { Component: ArticleDetailPage };
                },
            },
        ],
    },
];
