import { Button, paperClasses } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';
import Modal from '@@containers/Modal/ModalWithLeavePrompt';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { useReactHookForm } from '@@containers/ReactHookForm/useReactHookForm';
import ContentSelectionTableField from '@@containers/RelatedContent/ContentSelectionTableField';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import ButtonGroup from '@@form/components/ButtonGroup';
import { CancelButton, SubmitButton } from '@@form/components/Form/Buttons';
import RadioGroupField from '@@form/fields/RadioGroup';
import { requiredNumberNullable, requiredStringNullable } from '@@form/validations';
import { getReducedUISmartSetting } from '@@scripts/settings/settingsSlice';
import { useSelector } from '@@scripts/store/hooks';

import CategorySelectionTableField from './CategorySelectionTableField';
import { PAGE_SIZE } from './constants';
import TagSelectionTableField from './TagSelectionTableField';
import UserSelectionTableField from './UserSelectionTableField';

type InternalLinkType =
    | typeof ELEMENT_TYPES.INTERNAL_CONTENT_LINK
    | typeof ELEMENT_TYPES.CATEGORY_LINK
    | typeof ELEMENT_TYPES.TAG_LINK
    | typeof ELEMENT_TYPES.AUTHOR_LINK;

const schema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal(ELEMENT_TYPES.INTERNAL_CONTENT_LINK),
        metadataId: requiredNumberNullable,
    }),
    z.object({
        type: z.literal(ELEMENT_TYPES.CATEGORY_LINK),
        categoryId: requiredNumberNullable,
    }),
    z.object({
        type: z.literal(ELEMENT_TYPES.TAG_LINK),
        tagId: requiredNumberNullable,
    }),
    z.object({
        type: z.literal(ELEMENT_TYPES.AUTHOR_LINK),
        authorId: requiredStringNullable,
    }),
]);

type FormValues = z.infer<typeof schema>;

type Props = {
    onSubmit: (values: FormValues) => void;
    type: InternalLinkType;
    metadataId: number | null;
    categoryId: number | null;
    tagId: number | null;
    authorId: string | null;
};

const SelectTargetModal: React.FC<Props> = ({
    onSubmit,
    type: selectedType,
    metadataId,
    categoryId,
    tagId,
    authorId,
}: Props) => {
    const reducedUI = useSelector(getReducedUISmartSetting);
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => setIsModalOpen(false);

    const form = useReactHookForm({
        formName: 'InternalLinkForm',
        schema,
        values: {
            type: selectedType,
            metadataId: metadataId || null,
            categoryId: categoryId || null,
            tagId: tagId || null,
            authorId: authorId || null,
        },
    });

    const { methods } = form;

    const { type } = methods.watch();

    const renderSelectionField = (type: InternalLinkType) => {
        switch (type) {
            case ELEMENT_TYPES.INTERNAL_CONTENT_LINK:
                return (
                    <ContentSelectionTableField
                        params={{ size: PAGE_SIZE }}
                        disableUrlSync
                        name="metadataId"
                    />
                );
            case ELEMENT_TYPES.CATEGORY_LINK:
                return <CategorySelectionTableField />;
            case ELEMENT_TYPES.TAG_LINK:
                return <TagSelectionTableField />;
            case ELEMENT_TYPES.AUTHOR_LINK:
                return <UserSelectionTableField />;
            default:
                return null;
        }
    };

    return (
        <>
            <Button
                variant="outlined"
                startIcon={<Icon name="magnifying-glass" />}
                onClick={() => setIsModalOpen(true)}
            >
                {t('link.selectTarget.button')}
            </Button>

            <Modal
                title={t('link.selectTargetModal.title')}
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                }}
                sx={{
                    [`.${paperClasses.root}`]: {
                        width: '875px',
                        maxWidth: '875px',
                    },
                }}
            >
                <ReactHookForm
                    form={form}
                    onSubmit={(values) => {
                        onSubmit(values);
                        setIsModalOpen(false);
                    }}
                >
                    {!reducedUI && (
                        <>
                            <RadioGroupField
                                row
                                required
                                name="type"
                                fields={[
                                    {
                                        value: ELEMENT_TYPES.INTERNAL_CONTENT_LINK,
                                        label: t('link.selectTargetModal.internalLink.label'),
                                    },
                                    {
                                        value: ELEMENT_TYPES.CATEGORY_LINK,
                                        label: t('link.selectTargetModal.categoryLink.label'),
                                    },
                                    {
                                        value: ELEMENT_TYPES.TAG_LINK,
                                        label: t('link.selectTargetModal.tagLink.label'),
                                    },
                                    {
                                        value: ELEMENT_TYPES.AUTHOR_LINK,
                                        label: t('link.selectTargetModal.authorLink.label'),
                                    },
                                ]}
                            />
                            <Spacer v lg />
                        </>
                    )}

                    {renderSelectionField(type)}

                    <Spacer v lg />

                    <ButtonGroup>
                        <CancelButton isAlwaysActive isAlwaysVisible onClick={closeModal} />
                        <SubmitButton>{t('link.selectTargetModal.submitButton')}</SubmitButton>
                    </ButtonGroup>
                </ReactHookForm>
            </Modal>
        </>
    );
};

export default SelectTargetModal;
