import { type ReactNode } from 'react';

import { HOTKEYS } from '@@editor/constants';
import { type Editor } from '@@editor/helpers';
import handleHotkey from '@@editor/plugins/utils/handleHotkey';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';
import { isPluginInvalid } from '@@editor/toolbars/utils/getToolbarConfig';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

const MARK = 'code';

type Props = {
    attributes: UnknownObject;
    children: ReactNode;
};

const CodeMark = (props: Props) => <code {...props.attributes}>{props.children}</code>;

export const withCode = (editor: Editor) =>
    Object.assign(editor, {
        renderLeaf: renderLeaf(editor, [[MARK, CodeMark]]),
        handleHotkey: !isPluginInvalid(editor, PLUGIN_NAMES.CODE)
            ? handleHotkey(editor, [[HOTKEYS.CODE, (editor: Editor) => editor.toggleMark(MARK)]])
            : () => {},
    });

export default withCode;
