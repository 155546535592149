import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Element, Node, ReactEditor } from '@@editor/helpers';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';

type StyledCreditElementProps = {
    $templateElement?: boolean;
    $placeholderText: string;
    $readOnly: boolean;
};

const StyledCreditElement = styled('div')<StyledCreditElementProps>(
    ({ $templateElement, $placeholderText, $readOnly, theme }) => ({
        padding: '0 !important',
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        ...theme.fixed.editor.embed.credit.font,
        ...($templateElement &&
            getInputPlaceholderStyle({
                theme,
                placeholderText: $placeholderText,
                readOnly: $readOnly,
            })),
    }),
);

const CreditElement = (props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);
    const readOnly = ReactEditor.isReadOnly(editor);

    return (
        <StyledCreditElement
            {...attributes}
            $templateElement={
                Element.isTemplateElement(parentNode) || Element.isEmptyEmbedCreditElement(element)
            }
            $placeholderText={t('image.credit')}
            $readOnly={readOnly}
        >
            {children}
        </StyledCreditElement>
    );
};

export default CreditElement;
