import { Stack, styled } from '@mui/material';
import debug from 'debug';
import { detailedDiff } from 'deep-object-diff';
import { isEqual, pick } from 'lodash-es';
import {
    forwardRef,
    type KeyboardEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Range, Transforms } from 'slate';
import { Editable, type RenderElementProps, Slate, useSlateStatic } from 'slate-react';

import { Editor, Node, ReactEditor } from '@@editor/helpers';
import useRefFn from '@@hooks/useRefFn';
import { compose } from '@@utils/function';

import { useEditorGroup } from './containers/EditorGroupContext';
import { useUnityReactEditor } from './hooks/useUnityEditor';
import { StickyToolbar } from './toolbars/stickyToolbar';
import { type OuterProps, type Props } from './typings/UnityEditor';

const log = debug('editor');

export const Placeholder = styled('span')(({ theme }) => ({
    color: theme.palette.primary.light,
    pointerEvents: 'none',
    float: 'left',
    width: 0,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
}));

const PluginEditableWrappers = (props) => {
    const editor = useSlateStatic();

    return editor.renderEditor(props);
};

const DebugInfo = styled('div')<{ $id: string }>(({ $id, theme }) => ({
    '&:before': {
        display: 'block',
        content: `"${$id}"`,
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 9999,
        border: `1px dashed red`,
        padding: theme.spacing(0, 2),
    },
}));

const StyledDiv = styled('div')({});

// To make visual tests work, we need to have a specific component name to select the
// editable div.
const InnerEditable = forwardRef<HTMLDivElement>((props, ref) => (
    <StyledDiv ref={ref} {...props} />
));

InnerEditable.displayName = 'InnerEditable';

type EditableWrapperProps = Partial<Props> & {
    pureReadOnly?: boolean;
};

export const EditableWrapper = forwardRef<HTMLDivElement, EditableWrapperProps>((props, ref) => {
    const {
        hideStickyToolbar,
        stickyToolbarButtons,
        stickyToolbarSize,
        pureReadOnly,
        targetTextLength,
        showReadOnlyStickyToolbar,
        ...otherProps
    } = props;

    const editor = useSlateStatic();

    const showStickyToolbar = !pureReadOnly && !hideStickyToolbar;

    return (
        // Since read only information is not available yet via `ReactEditor.isReadOnly` or
        // 'useReadOnly' inside of `renderEditor` (since it is set on `Editable`, but `renderEditor` is
        // wrapped around `Editable`) we need to pass it here.
        <PluginEditableWrappers readOnly={pureReadOnly}>
            <Stack width="100%">
                {editor.debug && <DebugInfo $id={editor.id} />}

                {(showReadOnlyStickyToolbar || showStickyToolbar) && (
                    <StickyToolbar
                        activePlugins={stickyToolbarButtons}
                        size={stickyToolbarSize}
                        readOnly={pureReadOnly}
                        targetTextLength={targetTextLength}
                    />
                )}

                <InnerEditable ref={ref} {...otherProps} />
            </Stack>
        </PluginEditableWrappers>
    );
});

const EDITOR_TO_SELECTION_RECT = new WeakMap<Editor, DOMRect | undefined>();

export const UnityEditor = (props: Props) => {
    const {
        editor,
        initialValue,
        autoFocus = false,
        className,
        disabled,
        placeholder = '',
        readOnly,
        spellCheck = true,
        tabKeyDisabled,
        onFocus,
        onChange,
        onValueChange,
        onSelectionChange,
        onBlur,
        id,
    } = props;

    const focusedFlag = useRef(false);

    const editableWrapperOptions = {
        ...pick(props, [
            'stickyToolbarButtons',
            'stickyToolbarSize',
            'hideStickyToolbar',
            'defaultFloatingToolbarPlugins',
            'targetTextLength',
            'showReadOnlyStickyToolbar',
        ]),
        pureReadOnly: readOnly,
    };

    const { onKeyDown } = useEditorGroup(editor);

    const handleValueChange = useCallback(
        (value) => {
            if (import.meta.env.MODE === 'development') {
                const duplicateKeys = ReactEditor.findDuplicateKeyIdsInDom(editor);

                if (duplicateKeys.size > 0) {
                    throw new Error(
                        `Duplicate keys found in editor dom elements! Please have a look at \`https://docs.slatejs.org/concepts/09-rendering\`. List of duplicate keys: ${Array.from(
                            duplicateKeys,
                        )}`,
                    );
                }
            }

            onValueChange?.(value);
        },
        [editor, onChange],
    );

    const handleFocus = useCallback(
        (e) => {
            focusedFlag.current = true;
            const documentSelection = document.getSelection();

            // This is needed, if the editor area is taller than the actua content. If the user clicks outside of the
            // selection, the selection will be null. We need to set the selection as close as possible to where the
            // user clicked, or to the start of the editor.
            // For the focus to work properly, we need to define a selection first.
            if (documentSelection && editor.selection == null) {
                const anchorNode = documentSelection.anchorNode;

                // We want to make sure the DOM selection is part of the same editor
                if (anchorNode && ReactEditor.hasDOMNode(editor, anchorNode)) {
                    // We try to match the dom selection to a slate selection
                    // This allows to apply the selection at the right place if a user clicks on an element within the editor
                    const range = ReactEditor.toSlateRange(editor, documentSelection, {
                        exactMatch: false,
                        suppressThrow: false,
                    });

                    if (range) {
                        Transforms.select(editor, range);
                    } else {
                        Transforms.select(editor, Editor.start(editor, []));
                    }
                }
            }

            onFocus?.(e);

            // In order to let slate handle its internal focus state correctly, we need to tell
            // it we did not handle the focus event already
            return false;
        },
        [onFocus, editor],
    );

    const handleBlur = useCallback(
        (e) => {
            onBlur?.(e);

            // In order to let slate handle its internal focus state correctly, we need to tell
            // it we did not handle the blur event already
            return false;
        },
        [onBlur],
    );

    const handleKeyDown = (event: KeyboardEvent) => {
        // Disable tab key in article editor.
        // We have to disable it because we use arrow keys to move between elements.
        // Keeping the tab key option, will result in user's losing the focus on hitting tab key.
        if (tabKeyDisabled && event.nativeEvent.key === 'Tab') {
            event.preventDefault();

            return;
        }

        onKeyDown(event);

        return editor.handleHotkey(event);
    };

    const handleClickOnHiddenCheckbox = useCallback(() => {
        // This also gets called when clicking into the editable field which is a bit weird
        ReactEditor.focus(editor);
    }, [editor]);

    const renderElement = useMemo(() => {
        if (import.meta.env.MODE === 'development') {
            return (props: RenderElementProps) => {
                const { attributes, element } = props;

                return editor.renderElement({
                    ...props,
                    attributes: {
                        ...attributes,
                        'data-slate-node-key-id': ReactEditor.findKey(editor, element).id,
                    },
                });
            };
        }

        return editor.renderElement;
    }, [editor]);

    const handleIntersection: IntersectionObserverCallback = useCallback(
        (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach(({ isIntersecting, intersectionRatio, intersectionRect, target }) => {
                observer.unobserve(target);

                let shouldScrollIntoView = false;

                // We want to scroll the current node into view, if the node is completely hidden, or if it is partially
                // hidden and the caret is located in the hidden part. If the caret is still visible, we do not want to
                // initiate any scroll action, in order to avoid a jumpy UX. This is also how the browser natively would
                // behave in uncontrolled contenteditables. We want to come close to this behaviour in order to have a
                // consistent UX (when pressing arrow up/down, the browser would control the scroll action,
                // only when pressing arrow left/right, this code is executed; don't ask me why)
                if (!isIntersecting) {
                    shouldScrollIntoView = true;
                } else if (intersectionRatio !== 1) {
                    const selectionRect = EDITOR_TO_SELECTION_RECT.get(editor);

                    if (selectionRect) {
                        const isSelectionWithinIntersectionHorizontally =
                            selectionRect.x >= intersectionRect.x &&
                            selectionRect.x + selectionRect.width <=
                                intersectionRect.x + intersectionRect.width;

                        const isSelectionWithinIntersectionVertically =
                            selectionRect.y >= intersectionRect.y &&
                            selectionRect.y + selectionRect.height <=
                                intersectionRect.y + intersectionRect.height;

                        const isSelectionWithinIntersection =
                            isSelectionWithinIntersectionHorizontally &&
                            isSelectionWithinIntersectionVertically;

                        if (!isSelectionWithinIntersection) {
                            shouldScrollIntoView = true;
                        }
                    }
                }

                if (shouldScrollIntoView) {
                    target.scrollIntoView({
                        block: 'center',
                    });
                }
            });
        },
        [editor],
    );

    const observer = useMemo(
        () => new IntersectionObserver(handleIntersection),
        [handleIntersection],
    );

    const handleScrollSelectionIntoView = useCallback(() => {
        if (focusedFlag.current) {
            focusedFlag.current = false;

            return;
        }

        if (editor.selection == null || Range.equals(editor.selection, Editor.range(editor, []))) {
            return;
        }

        const { path } = editor.selection.focus;
        const node = Node.get(editor, path);

        if (node == null) {
            return;
        }

        const element = ReactEditor.toDOMNode(editor, node);

        if (element) {
            EDITOR_TO_SELECTION_RECT.set(
                editor,
                document.getSelection()?.getRangeAt(0)?.getBoundingClientRect?.(),
            );

            observer.observe(element);
        }
    }, [editor]);

    return (
        <>
            <Slate
                editor={editor}
                initialValue={initialValue}
                onChange={onChange}
                onValueChange={handleValueChange}
                onSelectionChange={onSelectionChange}
            >
                {
                    // If the editor is wrapped by a label, this hidden checkbox will receive the
                    // click event (if the label has been clicked). We have to do this because
                    // contenteditable is not recognized as
                    // form field by the native browser label click logic.
                    // This hidden checkbox also prevents other elements (for example hidden inputs
                    // of type file) from receiving the click event (which would cause
                    // weird behaviour).
                    // If you encounter issues with focusing an empty editor, have a look at this PR:
                    // https://github.com/ianstormtaylor/slate/issues/1161
                }
                <input
                    type="checkbox"
                    style={{ display: 'none' }}
                    onClick={handleClickOnHiddenCheckbox}
                    id={id}
                />

                <Editable
                    {...editableWrapperOptions}
                    as={EditableWrapper}
                    className={className}
                    decorate={editor.decorate}
                    renderElement={renderElement}
                    renderLeaf={editor.renderLeaf}
                    placeholder={placeholder}
                    renderPlaceholder={({ children, attributes }) => (
                        <Placeholder {...attributes}>{children}</Placeholder>
                    )}
                    spellCheck={!readOnly && spellCheck}
                    readOnly={readOnly || disabled}
                    autoFocus={autoFocus}
                    onFocus={handleFocus}
                    // "Instead of using keydown events you should likely override command behaviors instead."
                    // https://docs.slatejs.org/concepts/xx-migrating
                    // That is why we do not forward `keydown` events to plugins.
                    onKeyDown={handleKeyDown}
                    // Disable slate's internal drag handler because it would interfere with our custom drag
                    // and drop logic
                    onDragStart={() => true}
                    onDragOver={() => true}
                    onDrop={() => true}
                    onBlur={handleBlur}
                    // The default `scrollSelectionIntoView` behaviour is acting weird on `/embed` routes in
                    // modals (and probably other places): On every keystroke the document is moving.
                    scrollSelectionIntoView={handleScrollSelectionIntoView}
                />
            </Slate>
        </>
    );
};

const withDebug = () => (WrappedComponent) => (props: OuterProps) => {
    const debug = import.meta.env.MODE === 'development' ? props.debug : false;

    return <WrappedComponent {...props} debug={debug} />;
};

const withValueKey = () => (WrappedComponent) => (props: OuterProps) => {
    const { onChange } = props;
    const [editorId] = useState(() => props.editorId ?? crypto.randomUUID());
    const [valueId, setValueId] = useState(() => crypto.randomUUID());
    const { editor, initialValue, normalizedValue, prevNormalizedValue } = useUnityReactEditor({
        ...props,
        editorId,
        valueId,
    });

    const didNormalizedValueChange = useMemo(
        () => !isEqual(prevNormalizedValue, normalizedValue),
        [prevNormalizedValue, normalizedValue],
    );

    const lastChangeHandlerValue = useRefFn<Array<unknown>>(() => normalizedValue);

    // We've got a change here, not triggered by the onChange handler. This means the value prop has
    // been changed, but not by the onChange handler. Since slate is only using the `value` prop
    // from the first render, we have to manually overwrite slates children here, to support this. This
    // could lead to unexpected results, but what can we do.
    // https://github.com/ianstormtaylor/slate/pull/4540
    // https://github.com/ianstormtaylor/slate/issues/4612
    useEffect(() => {
        if (didNormalizedValueChange) {
            const isValueDifferentFromLastChangeHandlerValue = !isEqual(
                lastChangeHandlerValue.current,
                normalizedValue,
            );

            if (isValueDifferentFromLastChangeHandlerValue) {
                const newValueId = crypto.randomUUID();

                if (props.debug) {
                    log('withValueKey', 'valueId', valueId);
                    log('withValueKey', 'newValueId', newValueId);
                    log(
                        'withValueKey',
                        'detailedDiff',
                        detailedDiff(lastChangeHandlerValue.current, normalizedValue),
                    );
                }

                setValueId(newValueId);

                lastChangeHandlerValue.current = normalizedValue;
            }
        }
    }, [normalizedValue]);

    // Important note: This onChange is not the same as the onChange on the `editor` object! They are fired in a
    // different moment and we need to be aware, what logic to put where. Look at the slate source code, in order
    // to understand! One little hint, which might not always be true, bust mostly: Logic that affects what we
    // are propagating to the outside world on the onChange handler on the `Slate` component should be placed here.
    // Logic that affects the internal document on the `editor` object.
    const handleChangeValue = useCallback(
        (value) => {
            lastChangeHandlerValue.current = value;

            onChange?.(value);
        },
        [onChange],
    );

    const handleChangeSelection = useCallback(() => {
        onChange?.(editor.children);
    }, [onChange]);

    return (
        <WrappedComponent
            {...props}
            key={valueId}
            editor={editor}
            initialValue={initialValue}
            onValueChange={handleChangeValue}
            onSelectionChange={handleChangeSelection}
        />
    );
};

export default compose(
    withDebug(),
    withValueKey(),
    // @ts-expect-error
)(UnityEditor);
