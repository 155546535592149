import { type RouteObject } from 'react-router';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const CuratedList = await import('@@routes/curatedList');

            return { Component: CuratedList.default };
        },
    },
];
