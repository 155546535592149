import { type RouteObject } from 'react-router';

export const routes: RouteObject[] = [
    {
        path: ':id?',
        lazy: async () => {
            const { Layout } = await import('@@routes/releasenotes');

            return { Component: Layout };
        },
    },
];
