import isPropValid from '@emotion/is-prop-valid';
import { styled } from '@mui/material';
import { type CSSProperties, forwardRef, type PropsWithChildren } from 'react';

import { type Editor } from '@@editor/helpers';
import { type CrossheadElement, type ElementAttributes } from '@@editor/helpers/Element';

import { CROSSHEAD_STYLES, EDITOR_TO_ORDERED_LISTICLES } from './../constants';
import { getCrossheadStyle } from './../utils';

type CrossheadWrapperProps = {
    $isSubsection: boolean;
};

const CrossheadWrapper = styled('p', {
    shouldForwardProp: (prop: string) => isPropValid(prop),
})<CrossheadWrapperProps>(({ $isSubsection, theme }) => ({
    position: 'relative',
    margin: 0,
    ...($isSubsection
        ? {
              ...theme.fixed.editor.paragraph.subsection.font,
              paddingLeft: theme.spacing(4),
          }
        : theme.fixed.editor.paragraph.crosshead.font),
}));

const ListicleWrapper = styled('div')<{
    $isOrderedListicle: boolean;
    $orderedListicleNumber: number;
}>(({ $isOrderedListicle, $orderedListicleNumber }) => ({
    display: 'inline-block',

    '&::before': {
        content: $isOrderedListicle ? `'${$orderedListicleNumber}. '` : "'• '",
    },
}));

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element: CrossheadElement;
    style?: CSSProperties;
}>;

const Crosshead = forwardRef<HTMLElement, Props>(
    ({ attributes, children, editor, element, style }, ref) => {
        const crossheadStyle = getCrossheadStyle(element);

        const isListicle = [
            CROSSHEAD_STYLES.ORDERED_LISTICLE,
            CROSSHEAD_STYLES.UNORDERED_LISTICLE,
        ].includes(crossheadStyle);

        const isSubsection = crossheadStyle === CROSSHEAD_STYLES.SUBSECTION;

        const isOrderedListicle = crossheadStyle === CROSSHEAD_STYLES.ORDERED_LISTICLE;

        const orderedListicles = EDITOR_TO_ORDERED_LISTICLES.get(editor) ?? [];
        const orderedListicleIndex = Array.from(orderedListicles).findIndex(
            (node) => node === element,
        );
        const orderedListicleNumber = orderedListicleIndex !== -1 ? orderedListicleIndex + 1 : 0;

        const content = isListicle ? (
            <ListicleWrapper
                $isOrderedListicle={isOrderedListicle}
                $orderedListicleNumber={orderedListicleNumber}
            >
                {children}
            </ListicleWrapper>
        ) : (
            children
        );

        return (
            <CrossheadWrapper {...{ ref, style, ...attributes }} $isSubsection={isSubsection}>
                {content}
                <span contentEditable={false} />
            </CrossheadWrapper>
        );
    },
);

export default Crosshead;
