import { isEqual, omit, omitBy, pick, pickBy } from 'lodash-es';
import { type Location, matchPath } from 'react-router';

import { matchSomeValue } from '@@utils/string';

export const matchSomePath = (path: string, pathsToMatch: string[] = []) =>
    pathsToMatch.some((pathToMatch) =>
        matchPath(
            {
                path: pathToMatch,
                end: true,
                caseSensitive: false,
            },
            path,
        ),
    );

export type WillPathChangeOptions = {
    ignore?: string[];
    to?: string[];
};

export const willPathChange = (
    location: Location,
    nextLocation?: Location,
    options?: WillPathChangeOptions,
) => {
    // When closing the tab or reloading the page etc. `nextLocation` will not exist
    if (!nextLocation) {
        return true;
    } else if (location.pathname === nextLocation.pathname) {
        return false;
    }

    if (options?.ignore?.length) {
        if (matchSomePath(nextLocation.pathname, options.ignore)) {
            return false;
        }
    }

    if (options?.to?.length) {
        return matchSomePath(nextLocation.pathname, options.to);
    }

    return true;
};

export type WillPathParamsChangeOptions = {
    path: string;
    ignore?: string[];
    pick?: string[];
};

export const willPathParamsChange = (
    location: Location,
    nextLocation?: Location,
    options?: WillPathParamsChangeOptions,
) => {
    // When closing the tab or reloading the page etc. `nextLocation` will not exist
    if (!nextLocation) {
        return true;
    }

    const path = options?.path || '';

    const match = matchPath(
        {
            path,
            end: false,
            caseSensitive: false,
        },
        location.pathname,
    );

    const nextMatch = matchPath(
        {
            path,
            end: false,
            caseSensitive: false,
        },
        nextLocation.pathname,
    );

    let params = match?.params ?? {};
    let nextParams = nextMatch?.params ?? {};

    if (options?.ignore?.length) {
        params = omit(params, options.ignore);
        nextParams = omit(nextParams, options.ignore);
    }

    if (options?.pick?.length) {
        params = pick(params, options.pick);
        nextParams = pick(nextParams, options.pick);
    }

    return !isEqual(params, nextParams);
};

export type WillUrlParamsChangeOptions = {
    ignore?: (string | RegExp)[];
    pick?: (string | RegExp)[];
};

export const willUrlParamsChange = (
    location: Location,
    nextLocation?: Location,
    options?: WillUrlParamsChangeOptions,
) => {
    // When closing the tab or reloading the page etc. `nextLocation` will not exist
    if (!nextLocation) {
        return true;
    }

    let search = Object.fromEntries(new URLSearchParams(location.search));
    let nextSearch = Object.fromEntries(new URLSearchParams(nextLocation.search));

    if (options?.ignore?.length) {
        search = omitBy(search, (value, key) => matchSomeValue(key, options.ignore));
        nextSearch = omitBy(nextSearch, (value, key) => matchSomeValue(key, options.ignore));
    }

    if (options?.pick?.length) {
        search = pickBy(search, (value, key) => matchSomeValue(key, options.pick));
        nextSearch = pickBy(nextSearch, (value, key) => matchSomeValue(key, options.pick));
    }

    return !isEqual(search, nextSearch);
};
