import { styled } from '@mui/material';
import { get } from 'lodash';

import PreviewImage from '@@containers/PreviewImage';
import { type Editor } from '@@editor/helpers';
import { type VideocmsElement } from '@@editor/helpers/Element';
import { MAX_HEIGHT } from '@@editor/plugins/serializable/constants';

const getImageSrc = (data) => get(data, 'embed.previewImage.url', '');

export const getImageTransformationArguments = (element: VideocmsElement) => {
    const src = getImageSrc(element.data);

    const transformations = {
        ...element.data.embed?.previewImage,
    };

    return [src, transformations];
};

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.fixed.editor.embed.backgroundColor,
    padding: theme.spacing(2),
    width: '100%',
}));

const LimitedHeightPreviewImage = styled(PreviewImage)({
    maxHeight: `${MAX_HEIGHT}px`,
    borderRadius: 0,
    width: '100%',
});

type Props = {
    editor: Editor;
    element: VideocmsElement;
};

const VideoCmsPreview = ({ element }: Props) => {
    const [src, transformations] = getImageTransformationArguments(element);

    return (
        <Wrapper>
            <LimitedHeightPreviewImage transformations={transformations} src={src} skeleton />
        </Wrapper>
    );
};

export default VideoCmsPreview;
