import { styled } from '@mui/material';
import { useReadOnly } from 'slate-react';

import { Element, ELEMENT_TYPES } from '@@editor/helpers/Element';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import { PluginsWrapper } from '../../../components/PluginsWrapper';
import EmbedWrapper from '../../../embed/components/EmbedWrapper';

export const TYPE = PLUGIN_NAMES.QUOTE;
export const NODE_TYPE = ELEMENT_TYPES.QUOTE;
export const INLINE_EDITABLE_CHILDREN_TYPES = [
    ELEMENT_TYPES.QUOTE_TEXT,
    ELEMENT_TYPES.QUOTE_CAPTION,
];

export interface TemplateProps {
    element: Element;
}

const QuoteWrapper = styled(PluginsWrapper)<TemplateProps>(({ theme, element }) => ({
    backgroundColor: Element.isEmptyQuoteElement(element) ? theme.palette.gold.light : 'unset',
}));

const EmptyComponent = ({ children, contentEditable, element }) => {
    const isReadOnly = useReadOnly();

    return (
        <QuoteWrapper
            {...{
                contentEditable: contentEditable && !isReadOnly,
                element,
            }}
        >
            {children}
        </QuoteWrapper>
    );
};
const QuoteNode = (props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        component={EmptyComponent}
        placeholderComponent={EmptyComponent}
        contentEditable
        toolbarConfig={{
            infos: {
                iconName: PLUGIN_ICON_NAMES[TYPE],
                title: 'Quote',
            },
            actions: ['delete'],
            // t('editor.plugin.quote.tooltip')
            tooltips: [{ title: 'editor.plugin.quote.tooltip' }],
        }}
    />
);

export default QuoteNode;
