import { type RouteObject } from 'react-router';

import {
    ICE_HOCKEY_TEAMS,
    LEAGUES_AND_TOURNAMENTS,
    SOCCER_TEAMS,
    TENNIS_PLAYERS,
} from './constants';

export const routes: RouteObject[] = [
    {
        path: '',
        lazy: async () => {
            const { Layout } = await import('@@routes/teams');

            return { Component: Layout };
        },
        children: [
            {
                path: SOCCER_TEAMS,
                children: [
                    {
                        path: ':id',
                        lazy: async () => {
                            const { TeamForm } = await import('@@routes/teams/TeamForm');

                            return { element: <TeamForm sportType="soccer" /> };
                        },
                    },
                ],
            },
            {
                path: ICE_HOCKEY_TEAMS,
                children: [
                    {
                        path: ':id',
                        lazy: async () => {
                            const { TeamForm } = await import('@@routes/teams/TeamForm');

                            return { element: <TeamForm sportType="iceHockey" /> };
                        },
                    },
                ],
            },
            {
                path: TENNIS_PLAYERS,
                children: [
                    {
                        path: ':id',
                        lazy: async () => {
                            const { TennisForm } = await import('@@routes/teams/TennisForm');

                            return { element: <TennisForm /> };
                        },
                    },
                ],
            },
            {
                path: LEAGUES_AND_TOURNAMENTS,
                children: [
                    {
                        path: ':id',
                        lazy: async () => {
                            const { LeagueForm } = await import('@@routes/teams/LeagueForm');

                            return { element: <LeagueForm /> };
                        },
                    },
                ],
            },
        ],
    },
];
