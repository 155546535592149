import { type RouteObject } from 'react-router';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { SlideshowList } = await import('@@routes/slideshow/components/SlideshowList');

            return { Component: SlideshowList };
        },
    },
    {
        path: 'new',
        lazy: async () => {
            const { SlideshowSetupPage } = await import('@@routes/slideshow');

            return { Component: SlideshowSetupPage };
        },
    },
    {
        path: ':id',
        lazy: async () => {
            const { SlideshowDetailPage } = await import('@@routes/slideshow');

            return { Component: SlideshowDetailPage };
        },
        children: [
            {
                path: '*',
                lazy: async () => {
                    const { SlideshowDetailPage } = await import('@@routes/slideshow');

                    return { Component: SlideshowDetailPage };
                },
            },
        ],
    },
];
