import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type MetadataRouter, useMetadataClient } from '@@api/services/metadata/client';
import { defaultQueryPlaceholderData } from '@@api/utils/defaultPlaceholderData';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Pagination from '@@components/Pagination';
import Spacer from '@@components/Spacer';
import UnitySearch from '@@containers/Search/UnitySearch';
import useUnitySearch from '@@containers/Search/useUnitySearch';
import TagTabs, { TAB_NAME, type TabName } from '@@containers/tags/TagTabs';
import SelectionTableField from '@@form/fields/SelectionTable';

import { PAGE_SIZE } from './constants';
import { SelectionTableFieldWrapper } from './styles';

export const TagSelectionTableField = () => {
    const { t } = useTranslation();

    const search = useUnitySearch({
        disableUrlSync: true,
        minWidth: '250px',
        fields: {
            q: true,
        },
        moreFilters: false,
    });

    const [selectedTab, setSelectedTab] = useState<TabName>(TAB_NAME.ACTIVE);

    useEffect(() => {
        if (search.values.q) {
            setSelectedTab(TAB_NAME.ALL);
        }
    }, [search.values.q, setSelectedTab]);

    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();

    const page = search.pageNumbers[0];

    const activeTagsParams = getQueryParams<MetadataRouter['tags']['getAll']>({
        q: search.debouncedValues.q,
        active: true,
        size: PAGE_SIZE,
        page: page - 1,
    });

    const activeTags = metadataClient.tags.getAll.useQuery({
        queryKey: metadataKeys.tags.getAll({ query: activeTagsParams }),
        queryData: { query: activeTagsParams },
        placeholderData: defaultQueryPlaceholderData(PAGE_SIZE),
    });

    const allTagsParams = getQueryParams<MetadataRouter['tags']['getAll']>({
        q: search.debouncedValues.q,
        size: PAGE_SIZE,
        page: page - 1,
    });

    const allTags = metadataClient.tags.getAll.useQuery({
        queryKey: metadataKeys.tags.getAll({ query: allTagsParams }),
        queryData: { query: allTagsParams },
        placeholderData: defaultQueryPlaceholderData(PAGE_SIZE),
    });

    const isActiveTabSelected = selectedTab === TAB_NAME.ACTIVE;
    const tags = isActiveTabSelected ? activeTags : allTags;

    const content = tags.data?.body.content || [];
    const firstPageNumber = 1;
    const lastPageNumber = tags.data?.body.totalPages || 0;

    const countPerTab = {
        [TAB_NAME.ACTIVE]: activeTags.isFetching
            ? '?'
            : String(activeTags.data?.body.totalElements || 0),
        [TAB_NAME.ALL]: allTags.isFetching ? '?' : String(allTags.data?.body.totalElements || 0),
    };

    return (
        <Box>
            <UnitySearch {...search.unitySearchProps} />

            <Spacer v lg />

            <TagTabs
                selectedTab={selectedTab}
                setSelectedTab={(tabName) => {
                    setSelectedTab(tabName);
                    search.handlePageChange?.(null, firstPageNumber);
                }}
                countPerTab={countPerTab}
            />

            <Spacer v sm />

            <SelectionTableFieldWrapper>
                <SelectionTableField
                    name="tagId"
                    columns={[
                        {
                            title: t('tag.name'),
                            fieldName: 'name',
                            width: '200px',
                        },

                        {
                            title: t('tag.title'),
                            fieldName: 'title',
                            width: '200px',
                        },
                        {
                            title: t('tag.description'),
                            fieldName: 'description',
                        },
                    ]}
                    tableData={content}
                />

                <Spacer v lg />

                <Pagination
                    current={page}
                    first={firstPageNumber}
                    last={lastPageNumber}
                    getPageHref={search.getPageHref}
                    onChange={search.handlePageChange}
                />
            </SelectionTableFieldWrapper>
        </Box>
    );
};

export default TagSelectionTableField;
