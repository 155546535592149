import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { omit } from 'lodash';
import { type FieldError, type FieldPath, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ContentItemWithActions from '@@containers/ContentItem/ContentItemWithActions';
import useFormField, { type UseFormFieldProps } from '@@form/hooks/useFormField';
import { type FormFieldError } from '@@form/hooks/useReactHookFormFieldError';
import { getErrorMessage } from '@@form/utils/getErrorMessage';

import { LinkFieldWrapper } from './styles';
import CategoryPreview from '../LinkPreview/CategoryPreview';

type CategoryLinkBaseProps = {
    error?: FormFieldError | FieldError;
    label?: string;
    required?: boolean;
    value: number | null;
    onChange: (value: number | null) => void;
};

const CategoryLinkBase = (props: CategoryLinkBaseProps) => {
    const { error, label, required, value, onChange } = props;

    const { t } = useTranslation();

    const errorMessage = getErrorMessage(error, t);

    const helperText = errorMessage;

    return (
        <FormControl error={Boolean(error)} required={required}>
            {label && <FormLabel>{label}</FormLabel>}

            {value && (
                <ContentItemWithActions
                    onDeleteClick={() => {
                        onChange(null);
                    }}
                >
                    <LinkFieldWrapper>
                        <CategoryPreview id={value} />
                    </LinkFieldWrapper>
                </ContentItemWithActions>
            )}

            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

type CategoryLinkFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<CategoryLinkBaseProps, 'onChange' | 'value'> & UseFormFieldProps<TFieldValues, TName>;

const CategoryLinkField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: CategoryLinkFieldProps<TFieldValues, TName>,
) => {
    const {
        name,
        control,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const { field: formFieldProps } = useFormField({
        name,
        control,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    return <CategoryLinkBase {...omit(formFieldProps, ['ref'])} {...rest} required={required} />;
};

export default CategoryLinkField;
