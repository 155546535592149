import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Element, Node, ReactEditor } from '@@editor/helpers';

import { getInputPlaceholderStyle } from '../../styles';

type StyledDynamicTeaserTitleElementProps = {
    $templateElement: boolean;
    $placeholderText: string;
    $readOnly: boolean;
};

export const StyledDynamicTeaserTitleElement = styled('div')<StyledDynamicTeaserTitleElementProps>(
    ({ $templateElement, $placeholderText, $readOnly, theme }) => ({
        padding: `0 0 ${theme.spacing(1)} !important`,
        ...theme.fixed.editor.dynamicTeaser.title.font,
        fontWeight: 'bold',
        ...($templateElement &&
            getInputPlaceholderStyle({
                theme,
                placeholderText: $placeholderText,
                readOnly: $readOnly,
            })),
    }),
);

const DynamicTeaserTitleElement = (props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);
    const readOnly = ReactEditor.isReadOnly(editor);

    return (
        <StyledDynamicTeaserTitleElement
            {...attributes}
            $templateElement={
                Element.isTemplateElement(parentNode) ||
                Element.isEmptyDynamicTeaserTitleElement(element)
            }
            $placeholderText={t('editor.plugin.dynamicTeaser.form.title')}
            $readOnly={readOnly}
        >
            {children}
        </StyledDynamicTeaserTitleElement>
    );
};

export default DynamicTeaserTitleElement;
