import { Stack, styled } from '@mui/material';
import { find, identity, map } from 'lodash-es';

import { type Tenant } from '@@api/services/tenant/schemas';
import { StyledImage } from '@@containers/PreviewImage';

import TenantImage from './TenantImage';

const StackWithMargin = styled(Stack)<{ $isColumn?: boolean }>(({ theme, $isColumn }) => ({
    flexDirection: 'row',
    [`> ${StyledImage}`]: {
        marginRight: theme.spacing(2),
        '&:last-child': {
            marginRight: $isColumn ? theme.spacing(2) : 0,
        },
    },
}));
type Props = {
    className?: string;
    size?: 'S' | 'M' | 'L';
    tenants: any[] | Record<string, any>;
    tenantIds: Tenant['id'][];
    $isColumn?: boolean;
};

const TenantImages = (props: Props) => {
    const { className, size = 'S', tenants, tenantIds, $isColumn } = props;

    // The value (tenantIds) is the source of truth, thats why we should map it in this direction
    // to preserve also the order if it.
    // TODO: Write a unit test for this to make sure it stays this way.
    // Also test that it works with objects and arrays, thats why we use find from lodash here
    const activeTenants = map(tenantIds, (id) =>
        find(tenants, (entity) => entity.id === id),
    ).filter(identity);

    return (
        <StackWithMargin className={className} $isColumn={$isColumn}>
            {activeTenants.map((tenant) => (
                <TenantImage
                    key={tenant.id}
                    src={tenant.logoUrl}
                    tenant={tenant.name}
                    size={size}
                    natural
                />
            ))}
        </StackWithMargin>
    );
};

export default TenantImages;
