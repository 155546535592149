import { alpha, Stack, styled, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Icon from '@@components/Icon';
import { useLoadingStatusManager } from '@@containers/LoadingStatusManager/LoadingStatusManagerContext';
import { generateKeyForRichTextEditorLoadingStatus } from '@@containers/LoadingStatusManager/utils';
import { type Editor } from '@@editor/helpers';
import { type ImageElement } from '@@editor/helpers/Element';

const THREE_QUARTERS = 0.75;

const StyledIcon = styled(Icon)({ flexShrink: 0 });

type Props = {
    editor: Editor;
    element: ImageElement;
};

const ImageAltText = ({ editor, element }: Props) => {
    const {
        data: { loadingStatusId },
    } = element;
    const altText = element.data.embed?.name;
    const { t } = useTranslation();

    const generateAltTextKey = generateKeyForRichTextEditorLoadingStatus({
        editorId: editor.id,
        loadingStatusId,
        type: 'generateImageAltText',
    });

    const { isLoading: isGeneratingImageAltText } = useLoadingStatusManager({
        key: generateAltTextKey,
    });

    return (
        altText &&
        !isGeneratingImageAltText && (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="stretch"
                position="absolute"
                left={0}
                right={0}
                bottom={0}
                paddingX={2}
                sx={(theme) => ({
                    backgroundColor: alpha(theme.palette.common.white, THREE_QUARTERS),
                    userSelect: 'text',
                })}
            >
                <Typography color="primary.dark" flexGrow="1" paddingY={2}>
                    <Typography color="black" component="span">
                        {t('editor.plugin.image.imageDescription.label')}:&nbsp;
                    </Typography>

                    {altText}
                </Typography>

                <Tooltip title={t('editor.plugin.image.imageDescription.tooltip')}>
                    <StyledIcon name="bernand" color="amber" size="large" />
                </Tooltip>
            </Stack>
        )
    );
};

export default ImageAltText;
