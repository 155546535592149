import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import { Editor } from '@@editor/helpers';
import { useInitEditorDataContext } from '@@editor/plugins/data/EditorDataContext/EditorDataContext';
import { withPlugins } from '@@editor/plugins/setup';
import makeState from '@@editor/utils/makeState';
import useForceUpdate from '@@hooks/useForceUpdate';
import usePrevious from '@@hooks/usePrevious';

import withData from '../plugins/data/plugin';
import withDefaults, { withFallbacks } from '../plugins/defaults/plugin';
import { type UseUnityEditorProps } from '../typings/UnityEditor';

const createUnityEditor = () => withFallbacks(withHistory(createEditor()));
const createUnityReactEditor = () => withFallbacks(withHistory(withReact(createEditor())));

const createUseUnityEditor = (isReact?: boolean) => {
    const factoryFn = isReact ? createUnityReactEditor : createUnityEditor;

    return (props: UseUnityEditorProps) => {
        const { editorId, valueId, plugins = [] } = props;
        const { editorContextData, setEditorContextData } = useInitEditorDataContext(valueId);
        const { t } = useTranslation();
        const forceUpdate = useForceUpdate();

        const basicEditor: Editor = useMemo(() => {
            const editor = factoryFn();

            editor.setEditorContextData = setEditorContextData;

            return editor;
        }, [valueId]);

        basicEditor.id = editorId;
        basicEditor.valueId = valueId;
        basicEditor.t = t;
        basicEditor.debug = props.debug;
        basicEditor.disabled = props.disabled;
        // Do not use `forceUpdate` casually! Make sure you know what you're doing! This should only be used for
        // extreme rare edge cases and could lead to significant performance issues!
        basicEditor.forceUpdate = forceUpdate;
        basicEditor.availablePlugins = plugins;
        basicEditor.withDnd = props.withDnd;
        basicEditor.hideFloatingToolbar = props.hideFloatingToolbar;
        basicEditor.preserveFloatingToolbarHeight = props.preserveFloatingToolbarHeight;
        basicEditor.defaultFloatingToolbarPlugins = props.defaultFloatingToolbarPlugins;
        basicEditor.stickyToolbarButtons = props.stickyToolbarButtons;
        basicEditor.withEnhancedTextBlocks = props.withEnhancedTextBlocks;
        basicEditor.withPenIcon = props.withPenIcon;

        const editor = useMemo(
            // Order is crucial!!! Defaults must be applied first, because they must be called last!
            () => withPlugins(withData(withDefaults(basicEditor)), plugins),
            [basicEditor, plugins],
        );

        useEffect(() => {
            // Keep the editors internal data storage in sync with `editorContextData`
            if (editorContextData) {
                editor.setData(editorContextData);
            }
        }, [editor, editorContextData]);

        useEffect(() => {
            editor.onEditorMount();

            return () => {
                editor.onEditorUnmount();
            };
        }, [editor]);

        const [defaultValue] = useState(makeState);
        // We always need at least one paragraph with at least one text node as child for slate to
        // work correctly
        const normalizedValue =
            !props.value || props.value.length === 0 ? defaultValue : props.value;
        const prevNormalizedValue = usePrevious(normalizedValue);

        const initialValue = useMemo(() => {
            editor.children = normalizedValue;

            Editor.normalize(editor, { force: true });

            return editor.children;

            // We want to run this whenever a new editor is created
        }, [basicEditor]);

        return { editor, initialValue, normalizedValue, prevNormalizedValue };
    };
};

export const useUnityEditor = createUseUnityEditor(false);
export const useUnityReactEditor = createUseUnityEditor(true);
