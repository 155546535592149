import { DEFAULT_BLOCK } from '@@editor/constants';
import { Editor, Element } from '@@editor/helpers';
import { ELEMENT_TYPES, type SeparatorElement } from '@@editor/helpers/Element';
import renderElement from '@@editor/plugins/utils/renderElement';

import Separator from './Separator';

const NODE_TYPE = ELEMENT_TYPES.SEPARATOR;

export const withSeparator = (editor) =>
    Object.assign(editor, {
        renderElement: renderElement(editor, [[NODE_TYPE, Separator]]),
        insertSeparator: (at) => {
            Editor.insertElement(
                editor,
                Element.create<SeparatorElement>({
                    ...DEFAULT_BLOCK,
                    type: NODE_TYPE,
                }),
                {
                    at,
                },
            );
        },
    });

export default withSeparator;
