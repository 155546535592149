type Options = {
    contentStationEnv?: 'dev' | 'qua' | 'prod';
    brixwireEnv?: 'dev' | 'qua' | 'prod';
    assetsEnv?: 'dev' | 'qua' | 'prod';
    overrides?: {
        authRedirectUrl?: string;
    };
};

export type ApiEnvConfig = {
    aiToolsUrl: string;
    attachmentsUrl: string;
    authRedirectUrl: string;
    authUrl: string;
    breakingnewsUrl: string;
    contentUrl: string;
    externalContentUrl: string;
    filerepoUrl: string;
    functionsUrl: string;
    livetickerUrl: string;
    metadataUrl: string;
    newsletterUrl: string;
    notification: string;
    placementUrl: string;
    releasenotesUrl: string;
    revisionsUrl: string;
    rtElementsUrl: string;
    slideshowUrl: string;
    spellCheckerUrl: string;
    tenantUrl: string;
    userPresencesUrl: string;
    websocketUrl: string;
    webstatsUrl: string;
    contentStation: {
        newArticleUrl: string;
        dossierUrl: string;
    };
    externalLinks: {
        contentStation: string;
        videoCMS: string;
        assets: string;
        brixWire: string;
        toolbox: string;
        kordiam: string;
        okta: string;
    };
};

export type ApiEnvsConfig = Record<NonNullable<ImportMetaEnv['CDII_API']>, ApiEnvConfig>;

const domainMapper = {
    dev: 'ness-dev.tamedia.ch',
    qua: 'ness-qua.tamedia.ch',
    prod: 'ness.tamedia.ch',
};

const createEnv = (baseUrl: string, options?: Options): ApiEnvConfig => {
    const domains = {
        assets: domainMapper[options?.assetsEnv || 'dev'],
        brixwire: domainMapper[options?.brixwireEnv || 'dev'],
        contentStation: domainMapper[options?.contentStationEnv || 'dev'],
    };

    const assetsUrl = `https://dam.${domains.assets}/`;
    const brixwireUrl = `https://news.${domains.brixwire}/stories-browser`;
    const contentStationUrl = `https://cs.${domains.contentStation}/ep/contentstation/`;

    return {
        attachmentsUrl: `${baseUrl}/attachments`,
        userPresencesUrl: `${baseUrl}/user-presence`,
        revisionsUrl: `${baseUrl}/revisions`,
        webstatsUrl: `${baseUrl}/webstats`,
        releasenotesUrl: `${baseUrl}/releasenotes`,
        websocketUrl: `${baseUrl.replace('http', 'ws')}/websocket`,
        breakingnewsUrl: `${baseUrl}/breakingnews`,
        notification: `${baseUrl}/notification`,
        authRedirectUrl: `${baseUrl}/auth/oauth/authorize?scope=webapp&client_id=cd2&response_type=code&redirect_uri=`,
        authUrl: `${baseUrl}/auth`,
        contentUrl: `${baseUrl}/content`,
        externalContentUrl: `${baseUrl}/external-content`,
        filerepoUrl: `${baseUrl}/filerepo`,
        functionsUrl: `${baseUrl}/functions`,
        livetickerUrl: `${baseUrl}/liveticker`,
        metadataUrl: `${baseUrl}/metadata`,
        placementUrl: `${baseUrl}/placement`,
        slideshowUrl: `${baseUrl}/slideshow`,
        tenantUrl: `${baseUrl}/tenant`,
        rtElementsUrl: `${baseUrl}/rtelements`,
        spellCheckerUrl: `${baseUrl}/spell-checker`,
        aiToolsUrl: `${baseUrl}/ai-tools`,
        newsletterUrl: `${baseUrl}/newsletter`,
        contentStation: {
            dossierUrl: `${contentStationUrl}#/dossier/?dossierId=`,
            newArticleUrl: `${contentStationUrl}#/search/?Type=Article`,
        },
        externalLinks: {
            toolbox: 'https://discovery.tamedia.ch/toolbox',
            assets: assetsUrl,
            brixWire: brixwireUrl,
            contentStation: contentStationUrl,
            kordiam: 'https://kordiam.app/dashboardPage.htm',
            okta: 'https://login.tx.group/',
            videoCMS:
                'https://videocms.newsnetz.appuser.ch/?k=487b829a-c0ae-4e30-9cf2-9de578394ac4&language=de',
        },
        ...options?.overrides,
    };
};

const apiEnvs = {
    a1: createEnv('https://a1.unitycms.io/api'),
    a2: createEnv('https://a2.unitycms.io/api'),
    a3: createEnv('https://a3.unitycms.io/api'),
    a4: createEnv('https://a4.unitycms.io/api'),
    a5: createEnv('https://a5.unitycms.io/api'),
    a6: createEnv('https://a6.unitycms.io/api'),
    development: createEnv('https://dev.unitycms.io/api'),
    demo: createEnv('https://demo.unitycms.io/api', {
        contentStationEnv: 'qua',
        brixwireEnv: 'qua',
        assetsEnv: 'qua',
    }),
    preprod: createEnv('https://preprod.unitycms.io/api', {
        contentStationEnv: 'qua',
        brixwireEnv: 'prod',
        assetsEnv: 'prod',
    }),
    production: createEnv('https://prod.unitycms.io/api', {
        contentStationEnv: 'prod',
        brixwireEnv: 'prod',
        assetsEnv: 'prod',
    }),

    staging: createEnv('https://staging.unitycms.io/api'),
    'localhost:apiMock': createEnv('http://localhost:3000'),
    test: createEnv('http://test-env', {
        overrides: {
            authRedirectUrl: '',
        },
    }),
    'localhost:api': {
        attachmentsUrl: 'https://localhost:9098/attachments',
        userPresencesUrl: 'https://localhost:9097/user-presence',
        revisionsUrl: 'https://localhost:9098/revisions',
        webstatsUrl: 'https://localhost:9096',
        releasenotesUrl: 'https://localhost:9093',
        websocketUrl: 'wss://localhost:9097',
        breakingnewsUrl: 'https://localhost:9092',
        notification: 'https://localhost:9091',
        authRedirectUrl:
            'https://localhost:8443/oauth/authorize?scope=webapp&client_id=cd2&response_type=code&redirect_uri=',
        authUrl: 'https://localhost:8443',
        contentUrl: 'https://localhost:9084',
        externalContentUrl: 'https://localhost:9087',
        filerepoUrl: 'https://localhost:9082',
        functionsUrl: '',
        livetickerUrl: 'https://localhost:9081',
        metadataUrl: 'https://localhost:9089',
        placementUrl: 'https://localhost:9085',
        slideshowUrl: 'https://localhost:9083',
        tenantUrl: 'https://localhost:9088',
        editionsUrl: 'https://localhost:9085',
        rtElementsUrl: 'https://localhost:9085',
        spellCheckerUrl: 'https://localhost:9103',
        aiToolsUrl: 'https://localhost:9099',
        newsletterUrl: 'https://localhost:9105',
        contentStation: {
            newArticleUrl:
                'https://cs.ness-dev.tamedia.ch/ep/contentstation/#/search/?Type=Article',
            dossierUrl: 'https://cs.ness-dev.tamedia.ch/ep/contentstation/#/dossier/?dossierId=',
        },
        externalLinks: {
            kordiam: 'https://kordiam.app/dashboardPage.htm',
            okta: 'https://login.tx.group/',
            brixWire: 'https://news.ness-dev.tamedia.ch/stories-browser',
            videoCMS:
                'https://videocms.newsnetz.appuser.ch/?k=487b829a-c0ae-4e30-9cf2-9de578394ac4&language=de',
            assets: 'https://dam.ness-dev.tamedia.ch/',
            contentStation: 'https://cs.ness-dev.tamedia.ch/ep/contentstation/',
            toolbox: 'https://discovery.tamedia.ch/toolbox',
        },
    },
};

export default apiEnvs;
