import { Transforms } from 'slate';

import { type Editor } from '@@editor/helpers';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import deleteBackward from '@@editor/plugins/utils/deleteBackward';
import deleteForward from '@@editor/plugins/utils/deleteForward';
import deleteFragment from '@@editor/plugins/utils/deleteFragment';
import {
    normalizeInlineEditableElement,
    preventDeleteBackward,
    preventDeleteForward,
    preventDeleteFragment,
    preventInsertBreak,
} from '@@editor/plugins/utils/inlineEditing';
import insertBreak from '@@editor/plugins/utils/insertBreak';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';
import renderElement from '@@editor/plugins/utils/renderElement';
import makeState from '@@editor/utils/makeState';

import InterviewSegmentNode, {
    INLINE_EDITABLE_CHILDREN_TYPES,
    NODE_TYPE,
    TYPE,
} from '../../layout/components/InterviewSegmentNode';
import InterviewSegmentAnswerElement from '../components/InterviewSegmentAnswerElement';
import InterviewSegmentQuestionElement from '../components/InterviewSegmentQuestionElement';
import { createGenerateLayoutBlock } from '../utils';

const emptyInterviewData = {
    question: makeState(),
    answers: [makeState()],
};

const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

export const withInlineInterview = (editor: Editor, options) =>
    Object.assign(editor, {
        deleteForward: deleteForward(editor, [
            [preventDeleteForward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteBackward: deleteBackward(editor, [
            [preventDeleteBackward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteFragment: deleteFragment(editor, [
            [preventDeleteFragment, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        insertBreak: insertBreak(editor, [
            [preventInsertBreak, { types: [ELEMENT_TYPES.INTERVIEW_SEGMENT_QUESTION] }],
        ]),
        normalizeNode: normalizeNode(editor, [
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.INTERVIEW_SEGMENT,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
        renderElement: renderElement(
            editor,
            [
                [NODE_TYPE, InterviewSegmentNode],
                [ELEMENT_TYPES.INTERVIEW_SEGMENT_QUESTION, InterviewSegmentQuestionElement],
                [ELEMENT_TYPES.INTERVIEW_SEGMENT_ANSWER, InterviewSegmentAnswerElement],
            ],
            options,
        ),
        insertInterview: (at = editor.selection?.focus) => {
            generateLayoutBlock(editor, emptyInterviewData, {
                at,
                replace: false,
            });
            if (at) {
                Transforms.select(editor, at);
                Transforms.collapse(editor);
            }
        },
    });

export default withInlineInterview;
