import { styled } from '@mui/material';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { type Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type EmbedElement } from '@@editor/helpers/Element';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';

type StyledInterviewSegmentQuestionElementProps = {
    $templateElement: boolean;
    $placeholderText: string;
    $readOnly: boolean;
};

export const StyledInterviewSegmentQuestionElement = styled(
    'div',
)<StyledInterviewSegmentQuestionElementProps>(
    ({ $templateElement, $placeholderText, $readOnly, theme }) => ({
        ...theme.fixed.editor.interviewSegment.question.font,
        ...($templateElement &&
            getInputPlaceholderStyle({
                theme,
                placeholderText: $placeholderText,
                readOnly: $readOnly,
            })),
    }),
);

type Props = {
    attributes: ElementAttributes;
    children: ReactNode;
    editor: Editor;
    element: EmbedElement;
};

const InterviewSegmentQuestionElement = (props: Props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);
    const readOnly = ReactEditor.isReadOnly(editor);

    return (
        <>
            <StyledInterviewSegmentQuestionElement
                {...attributes}
                $templateElement={
                    Element.isTemplateElement(parentNode) ||
                    Element.isEmptyInterviewSegmentQuestionElement(element)
                }
                $placeholderText={t('editor.plugin.layout.form.question')}
                $readOnly={readOnly}
            >
                {children}
            </StyledInterviewSegmentQuestionElement>
        </>
    );
};

export default InterviewSegmentQuestionElement;
