import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type TenantRouter, useTenantClient } from '@@api/services/tenant/client';
import { defaultQueryPlaceholderData } from '@@api/utils/defaultPlaceholderData';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Pagination from '@@components/Pagination';
import Spacer from '@@components/Spacer';
import CategoryTabs, { TAB_NAME, type TabName } from '@@containers/categories/CategoryTabs';
import UnitySearch from '@@containers/Search/UnitySearch';
import useUnitySearch from '@@containers/Search/useUnitySearch';
import SelectionTableField from '@@form/fields/SelectionTable';

import { PAGE_SIZE } from './constants';
import { SelectionTableFieldWrapper } from './styles';

const CategorySelectionTableField = () => {
    const { t } = useTranslation();
    const { client: tenantClient, queryKeys: tenantKeys } = useTenantClient();

    const [selectedTab, setSelectedTab] = useState<TabName>(TAB_NAME.ACTIVE);

    const search = useUnitySearch({
        disableUrlSync: true,
        minWidth: '250px',
        fields: {
            q: true,
        },
        moreFilters: false,
    });

    const page = search.pageNumbers[0];

    useEffect(() => {
        if (search.values.q) {
            setSelectedTab(TAB_NAME.ALL);
        }
    }, [search.values.q, setSelectedTab]);

    const params = getQueryParams<TenantRouter['category']['getAllPaginated']>({
        sort: 'name,ASC',
        name: search.debouncedValues.q,
        size: PAGE_SIZE,
        page: page - 1,
    });

    const categoryList = tenantClient.category.getAllPaginated.useQuery({
        queryKey: tenantKeys.category.getAllPaginated({ query: params }),
        queryData: {
            query: params,
        },
        placeholderData: defaultQueryPlaceholderData(PAGE_SIZE),
    });

    const activeQueryParams = { ...params, active: true };
    const activeCategoryList = tenantClient.category.getAllPaginated.useQuery({
        queryKey: tenantKeys.category.getAllPaginated({ query: activeQueryParams }),
        queryData: {
            query: activeQueryParams,
        },
        placeholderData: defaultQueryPlaceholderData(PAGE_SIZE),
    });

    const desknetQueryParams = { ...params, syncedWithDesknet: true };
    const desknetCategoryList = tenantClient.category.getAllPaginated.useQuery({
        queryKey: tenantKeys.category.getAllPaginated({ query: desknetQueryParams }),
        queryData: {
            query: desknetQueryParams,
        },
        placeholderData: defaultQueryPlaceholderData(PAGE_SIZE),
    });

    const getCategories = () => {
        switch (selectedTab) {
            case TAB_NAME.ACTIVE: {
                return activeCategoryList;
            }
            case TAB_NAME.DESKNET: {
                return desknetCategoryList;
            }
            default:
                return categoryList;
        }
    };

    const categories = getCategories();

    const content = categories.data?.body.content || [];
    const firstPageNumber = 1;
    const lastPageNumber = categories.data?.body.totalPages || 0;

    const countPerTab = {
        [TAB_NAME.ACTIVE]: activeCategoryList.isLoading
            ? '?'
            : String(activeCategoryList.data?.body.totalElements ?? 0),
        [TAB_NAME.ALL]: categoryList.isLoading
            ? '?'
            : String(categoryList.data?.body.totalElements ?? 0),
        [TAB_NAME.DESKNET]: desknetCategoryList.isLoading
            ? '?'
            : String(desknetCategoryList.data?.body.totalElements ?? 0),
    };

    return (
        <Box>
            <UnitySearch {...search.unitySearchProps} />

            <Spacer v lg />

            <CategoryTabs
                selectedTab={selectedTab}
                setSelectedTab={(tabName) => {
                    setSelectedTab(tabName);
                    search.handlePageChange?.(null, firstPageNumber);
                }}
                countPerTab={countPerTab}
            />
            <Spacer v sm />

            <SelectionTableFieldWrapper>
                <SelectionTableField
                    name="categoryId"
                    columns={[
                        {
                            title: t('category.name'),
                            fieldName: 'name',
                            width: '200px',
                        },

                        {
                            title: t('category.parentName'),
                            fieldName: 'parentName',
                            width: '200px',
                        },
                        {
                            title: t('category.type'),
                            fieldName: 'type',
                            width: '200px',
                        },
                    ]}
                    tableData={content}
                />

                <Spacer v lg />

                <Pagination
                    current={page}
                    first={firstPageNumber}
                    last={lastPageNumber}
                    getPageHref={search.getPageHref}
                    onChange={search.handlePageChange}
                />
            </SelectionTableFieldWrapper>
        </Box>
    );
};

export default CategorySelectionTableField;
