import { createTheme, type CSSObject, type Theme } from '@mui/material';

export type InputPlaceholderStyleProps = {
    theme: Theme;
    placeholderText?: string;
    readOnly: boolean;
};

export const getInputPlaceholderStyle = ({
    theme,
    placeholderText,
    readOnly,
}: InputPlaceholderStyleProps): CSSObject => ({
    position: 'relative',

    '&::before': {
        position: 'absolute',
        content: `"${placeholderText || ''}"`,
        color: theme.palette.gold.dark,
    },

    ...(!readOnly && {
        '&:hover': {
            '&::before': {
                color: theme.palette.gold.contrastText,
            },
        },
    }),
});

type TextBoxStyleProps = {
    theme: Theme;
    placeholderText: string;
};

export const getTextBoxStyle = ({ theme, placeholderText }: TextBoxStyleProps) => ({
    '&::before': {
        content: `"${placeholderText}"`,
        color: theme.palette.gold.dark,
    },
    '&': {
        padding: theme.spacing(4, 0), // Raise importance to overwrite editor-wide padding styles
    },
    p: {
        margin: 0,
    },
});

type TextTemplateStyleProps = {
    theme: Theme;
    $readOnly: boolean | undefined;
};

const getTextTemplateStyle = ({ theme, $readOnly }: TextTemplateStyleProps) => ({
    backgroundColor: theme.palette.gold.light,
    color: theme.palette.gold.dark,
    pointerEvents: 'none',
    display: 'block',
    overflow: 'hidden',
    padding: theme.spacing(2),
    ...($readOnly && { paddingLeft: theme.spacing(3) }),
    width: '100%',
    borderRadius: theme.fixed.editor.elementWrapper.borderRadius,
});

export const getParagraphTemplateStyle = ({
    theme,
    $readOnly,
}: {
    theme: Theme;
    $readOnly?: boolean;
}) => ({
    '&::after': {
        ...getTextTemplateStyle({ theme, $readOnly }),
        ...theme.fixed.editor.paragraph.template.font,
        content: `'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis at libero non convallis. Sed tempor enim et efficitur egestas. Cras dapibus interdum felis a suscipit. Phasellus lobortis enim a tincidunt feugiat. Vivamus ut interdum neque, eu finibus quam.'`,
    },
});

export const getCrossheadTemplateStyle = ({
    theme,
    $readOnly,
    $isSubsection,
    $crossheadTemplateContent,
}: {
    theme: Theme;
    $isSubsection?: boolean;
    $crossheadTemplateContent: string;
    $readOnly?: boolean;
}) => ({
    '&::after': {
        ...getTextTemplateStyle({ theme, $readOnly }),
        ...($isSubsection
            ? {
                  ...theme.fixed.editor.paragraph.subsection.font,
              }
            : theme.fixed.editor.paragraph.crosshead.font),
        content: `'${$crossheadTemplateContent}'`,
    },
});

export const getFooterTemplateStyle = ({
    theme,
    $footerTemplateContent,
    $readOnly,
}: {
    theme: Theme;
    $footerTemplateContent: string;
    $readOnly?: boolean;
}) => ({
    '&::after': {
        ...getTextTemplateStyle({ theme, $readOnly }),
        ...theme.fixed.editor.paragraph.footer.font,
        content: `'${$footerTemplateContent}'`,
    },
});

export const editorPlaceholderTheme = (theme: Theme) =>
    createTheme(theme, {
        fixed: {
            editor: {
                embed: {
                    backgroundColor: theme.palette.gold.light,
                },
            },
        },
    });
