import { type Comment } from '@@api/services/content/schemas/comment';
import { type CropMarks, type FocusPoint, type Image } from '@@api/utils/schemas/schemas';
import type { ImageMimeType } from '@@constants/ImageMimeTypes';
import {
    BLOCK_TYPES,
    type ContainerType,
    type CrossheadStyle,
    ELEMENT_TYPES,
    type FacebookMimeType,
    GENERAL_EMBED_TYPE,
    type ImageElementOverlayIcon,
    LAYOUT_TYPE,
    type MIME_TYPES,
} from '@@editor/helpers/Element';
import { type SCHEMA } from '@@editor/serialization/constants';
import { type CURRENT_VERSION } from '@@editor/serialization/UnitySerializer/constants';

type UnityNode = {
    elementId?: Id;
    version: typeof CURRENT_VERSION;
    displayError?: boolean;
};

export type TextItemAttributes = {
    bold?: boolean;
    italic?: boolean;
    underlined?: boolean;
    superscript?: boolean;
    subscript?: boolean;
    commentId?: Comment['id'];
};

export type UnityTextItem = UnityNode & {
    type: typeof ELEMENT_TYPES.TEXTITEM;
    text: string;
    attributes?: TextItemAttributes;
};

type UnityTextBase = UnityNode & {
    items: (UnityTextItem | UnityLink)[];
};

export type UnityItemVariants = Record<string, any>;

export type UnityParagraph = UnityTextBase & {
    type: typeof ELEMENT_TYPES.TEXT;
};

export type UnityCrosshead = UnityTextBase & {
    type: typeof ELEMENT_TYPES.CROSSHEAD;
    style: { crossheadType?: CrossheadStyle };
};

export type UnityFooter = UnityTextBase & {
    type: typeof ELEMENT_TYPES.FOOTER;
};

export type UnityTitle = UnityTextBase & {
    type: typeof ELEMENT_TYPES.TITLE;
    variants?: UnityItemVariants;
};

export type UnityTitleHeader = UnityTextBase & {
    type: typeof ELEMENT_TYPES.TITLE_HEADER;
    variants?: UnityItemVariants;
};

export type UnityLead = UnityTextBase & {
    type: typeof ELEMENT_TYPES.LEAD;
    variants?: UnityItemVariants;
};

export type UnityUnorderedList = UnityNode & {
    type: typeof ELEMENT_TYPES.UNORDERED_LIST;
    items: UnityParagraph[];
};

export type UnityOrderedList = UnityNode & {
    type: typeof ELEMENT_TYPES.ORDERED_LIST;
    items: UnityParagraph[];
};

type UnityList = UnityUnorderedList | UnityOrderedList;

export type UnityInternalLink = UnityNode & {
    type: typeof ELEMENT_TYPES.INTERNAL_CONTENT_LINK;
    text: string;
    metadataId: number;
    attributes?: Record<string, string>;
};

export type UnityTagLink = UnityNode & {
    type: typeof ELEMENT_TYPES.TAG_LINK;
    text: string;
    tagId: number;
    attributes?: Record<string, string>;
};

export type UnityCategoryLink = UnityNode & {
    type: typeof ELEMENT_TYPES.CATEGORY_LINK;
    text: string;
    categoryId: number;
    attributes?: Record<string, string>;
};

export type UnityAuthorLink = UnityNode & {
    type: typeof ELEMENT_TYPES.AUTHOR_LINK;
    text: string;
    authorId: string;
    attributes?: Record<string, string>;
};

export type UnityExternalLink = UnityNode & {
    type: typeof ELEMENT_TYPES.EXTERNAL_LINK;
    text: string;
    url: string;
    attributes?: Record<string, string>;
};

export type UnityLink =
    | UnityInternalLink
    | UnityExternalLink
    | UnityTagLink
    | UnityCategoryLink
    | UnityAuthorLink;

export type UnityText =
    | UnityParagraph
    | UnityCrosshead
    | UnityFooter
    | UnityTitle
    | UnityTitleHeader
    | UnityLead;

export type UnityPollItem = UnityNode & {
    type: typeof ELEMENT_TYPES.POLLITEM;
    id: Id;
    text: UnityParagraph;
};

export type UnityPoll = UnityNode & {
    type: typeof ELEMENT_TYPES.POLL;
    question: UnityPollItem;
    answers: UnityPollItem[];
};

export type UnityQuote = UnityNode & {
    type: typeof ELEMENT_TYPES.QUOTE;
    quote: UnityParagraph;
    caption: UnityParagraph;
};

export type UnitySummaryList = UnityNode & {
    type: typeof ELEMENT_TYPES.SUMMARY_LIST;
    summary: UnityUnorderedList;
};

export type UnitySeparator = UnityNode & {
    type: typeof ELEMENT_TYPES.SEPARATOR;
};

export type UnityInfobox = UnityNode & {
    type: typeof ELEMENT_TYPES.INFOBOX;
    collapsed: boolean;
    title: UnityParagraph | null;
    content: any;
    style: {
        containerType: ContainerType;
    };
};

export type UnitySnippet = UnityNode & {
    type: typeof ELEMENT_TYPES.SNIPPET;
    content: UnityElement[];
};

export type UnityInterviewSegment = UnityNode & {
    type: typeof ELEMENT_TYPES.INTERVIEW_SEGMENT;
    question: UnityParagraph;
    answers: UnityParagraph[];
};

export type UnityDynamicTeaser = UnityNode & {
    type: typeof ELEMENT_TYPES.DYNAMIC_TEASER;
    title: UnityParagraph | null;
    image?: Image;
    metadataIds: number[];
};

export type UnityLayout =
    | UnityQuote
    | UnitySeparator
    | UnityInfobox
    | UnityInterviewSegment
    | UnityDynamicTeaser;

export type EmbedContentAttributes = {
    captionTitle?: string;
    caption?: UnityState | null;
    credit?: string;
};

type UnityEmbedBase = UnityNode & {
    src?: string;
    originalSrc?: string;
    options?: any;
    templateElement?: boolean;
};

export type UnityFrontendComponent = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.FRONTEND_COMPONENT;
    mimetype: typeof MIME_TYPES.FRONTEND_COMPONENT;
    contentAttributes: {
        label?: string;
        properties?: Record<string, string>;
    };
};

export type UnityEmbeddedContent = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.EMBEDDED_CONTENT;
    mimetype: typeof MIME_TYPES.EMBEDDED_CONTENT;
    contentAttributes: EmbedContentAttributes & {
        height?: { mobile: number; desktop: number };
        fallBackHeight?: number;
    };
};

export type UnityRTElement = {
    [SCHEMA.UNITY]: (UnityPoll | UnityInfobox | UnityEmbeddedContent | UnitySnippet)[];
};

export type UnityFacebook = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.FACEBOOK;
    mimetype: FacebookMimeType;
    contentAttributes: EmbedContentAttributes;
};

export type UnityImage = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.IMAGE;
    mimetype: ImageMimeType;
    contentAttributes: EmbedContentAttributes & {
        name?: string;
        elvisId?: string;
        cropMarks?: CropMarks;
        focusPoint?: FocusPoint;
        overlayIcon?: ImageElementOverlayIcon;
        url?: string;
        naturalWidth?: number | null;
        naturalHeight?: number | null;
        style?: {
            imageType: string;
        };
    };
};

export type UnityThreads = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.THREADS;
    mimetype: typeof MIME_TYPES.THREADS;
};

export type UnityInstagram = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.INSTAGRAM;
    mimetype: typeof MIME_TYPES.INSTAGRAM;
    contentAttributes: EmbedContentAttributes & {
        includeCaption?: boolean;
    };
};

export type UnitySlideshow = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.SLIDESHOW;
    mimetype: typeof MIME_TYPES.SLIDESHOW;
    contentAttributes: EmbedContentAttributes & {
        id: string;
    };
};

export type UnityCSSlideshow = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.CS_SLIDESHOW;
    mimetype?: never;
    contentAttributes?: never;
    slides: UnityImage[];
};

export type UnityTwitter = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.TWITTER;
    mimetype: typeof MIME_TYPES.TWITTER;
    contentAttributes: EmbedContentAttributes;
};

export type UnityVideocms = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.VIDEOCMS;
    mimetype: typeof MIME_TYPES.VIDEOCMS;
    contentAttributes: EmbedContentAttributes & {
        previewImage?: Image;
    };
};

export type UnityVideocmsLivestream = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.VIDEOCMS_LIVESTREAM;
    mimetype: typeof MIME_TYPES.VIDEOCMS_LIVESTREAM;
    contentAttributes: EmbedContentAttributes;
};

export type UnityYoutube = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.YOUTUBE;
    mimetype: typeof MIME_TYPES.YOUTUBE;
    contentAttributes: EmbedContentAttributes & {
        startTime?: number;
    };
};

export type UnityZattoo = UnityEmbedBase & {
    type: typeof ELEMENT_TYPES.ZATTOO;
    mimetype: typeof MIME_TYPES.ZATTOO;
    contentAttributes: EmbedContentAttributes;
};

export type UnityEmbed =
    | UnityEmbeddedContent
    | UnityFacebook
    | UnityImage
    | UnityThreads
    | UnityInstagram
    | UnityFrontendComponent
    | UnitySlideshow
    | UnityCSSlideshow
    | UnityTwitter
    | UnityVideocms
    | UnityVideocmsLivestream
    | UnityYoutube
    | UnityZattoo;

export type UnityElement =
    | UnityText
    | UnityPoll
    | UnityPollItem
    | UnityLayout
    | UnityEmbed
    | UnityList;

export type UnityState = {
    [SCHEMA.UNITY]: UnityElement[];
};

export type UnityElementOrNull = UnityElement | null | undefined;

export type UnityStateNullable = {
    [SCHEMA.UNITY]: UnityElementOrNull[];
};

export const isUnityEmbed = (value: any): value is UnityEmbed =>
    value.type
        ? // Unfortunately we need an exception here for slideshow since backend did not talk
          // to frontend before their implementation, so it does not apply to our standards. So unity
          // slideshow have a type of `embed.slideshow` and contentstation slideshow have a type of `slideshow`
          value.type.startsWith(GENERAL_EMBED_TYPE) || value.type === ELEMENT_TYPES.CS_SLIDESHOW
        : false;

export const isUnityLayout = (value: any): value is UnityLayout =>
    value.type ? value.type.startsWith(LAYOUT_TYPE) : false;
export const isUnityList = (value: any): value is UnityList =>
    value.type === BLOCK_TYPES.ORDERED_LIST || value.type === BLOCK_TYPES.UNORDERED_LIST;
export const isUnityPoll = (value: any): value is UnityPoll => value.type === BLOCK_TYPES.POLL;
export const isUnitySnippet = (value: any): value is UnityPoll =>
    value.type === BLOCK_TYPES.SNIPPET;
export const isUnityTitle = (value: any): value is UnityTitle => value.type === BLOCK_TYPES.TITLE;
export const isUnitySummaryList = (value: any): value is UnitySummaryList =>
    value.type === BLOCK_TYPES.SUMMARY_LIST;
