import { type PropsWithChildren, useEffect } from 'react';
import { useReadOnly } from 'slate-react';

import { type Editor, Node } from '@@editor/helpers';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import { PLUGIN_NAMES, type PluginOptions } from '@@editor/typings/UnityPlugins';

import { setPluginState } from './utils';

type EditorWrapperProps = PropsWithChildren<{
    editor: Editor;
    options: PluginOptions;
}>;

const EditorWrapper = ({ children, editor }: EditorWrapperProps) => {
    const readOnly = useReadOnly();

    useEffect(() => {
        setPluginState(editor, 'disabled');
    }, []);

    useEffect(() => {
        editor.setEditorContextData({ [PLUGIN_NAMES.TEASER_GENERATOR]: { isReadOnly: readOnly } });
    }, [readOnly]);

    const articleText = editor.children?.map(Node.string).filter(Boolean).join(' ');

    useEffect(() => {
        editor.setEditorContextData({
            [PLUGIN_NAMES.TEASER_GENERATOR]: { articleText },
        });
    }, [articleText, editor]);

    return <>{children}</>;
};

const withTeaserGenerator = (editor: Editor, options: PluginOptions) =>
    Object.assign(editor, {
        renderEditor: renderEditor(editor, ({ children }) => (
            <EditorWrapper editor={editor} options={options}>
                {children}
            </EditorWrapper>
        )),
    });

export default withTeaserGenerator;
