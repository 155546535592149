import { Button, Stack, styled } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useRouteError } from 'react-router';

import Image from '@@components/Image';
import unityLogoBig from '@@images/unity-logo-big.png';

const WrapperStack = styled(Stack)({
    width: '100%',
    height: '100vh',
});

const Title = styled('h1')(({ theme }) => ({
    ...theme.typography.subtitle1,
    marginBottom: theme.spacing(2),
}));

const Subtitle = styled('h2')(({ theme }) => ({
    ...theme.typography.large,
    margin: `0 0 ${theme.spacing(3)} 0`,
}));

const Logo = styled(Image)({
    width: '400px',
});

const ReloadPage = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname) {
            window.location.replace(location.pathname);
        } else {
            window.location.reload();
        }
    }, [location]);

    return null;
};

export const ErrorPage = () => {
    const { t } = useTranslation();
    const error = useRouteError();

    if (error && error instanceof Error) {
        if (error.name === 'ChunkLoadError') {
            return <ReloadPage />;
        }
    }

    return (
        <WrapperStack alignItems="center" justifyContent="center">
            <Logo src={unityLogoBig} alt="Unity Home" />
            <Title>{t('errorPage.title')}</Title>
            <Subtitle>{t('errorPage.subtitle')}</Subtitle>
            <Button href="/" color="secondary" variant="contained">
                {t('errorPage.button')}
            </Button>
        </WrapperStack>
    );
};
