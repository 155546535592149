import { useTranslation } from 'react-i18next';

import {
    isSpellCheckerBandwidthLimitExceededError,
    isSpellCheckerConnectionTimedOutError,
} from '@@api/errorHandler';
import { useSpellCheckerClient } from '@@api/services/spellChecker/client';
import snackbar from '@@containers/Snackbar';

const usePostSpellCheck = () => {
    const { t } = useTranslation();
    const { client: spellCheckerClient } = useSpellCheckerClient();

    const { mutateAsync: spellCheck } = spellCheckerClient.spellCheck.post.useMutation({
        onError: (error) => {
            if (isSpellCheckerConnectionTimedOutError(error)) {
                snackbar.error(t('spellchecker.error.timeout.message'));
            } else if (isSpellCheckerBandwidthLimitExceededError(error)) {
                snackbar.error(t('spellchecker.error.overload.message'));
            }
        },
    });

    return spellCheck;
};

export default usePostSpellCheck;
