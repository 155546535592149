import { type Theme } from '@mui/material';
import { merge } from 'lodash-es';

const theme = (parentTheme: Theme) =>
    merge({}, parentTheme, {
        fixed: {
            editor: {
                padding: 0,
                textElement: {
                    width: '100%',
                },
                stickyToolbar: {
                    crossheadButton: {
                        font: parentTheme.typography.title4,
                    },
                    footerButton: {
                        font: parentTheme.typography.small,
                    },
                },
                embed: {
                    caption: {
                        font: parentTheme.typography.medium,
                    },
                    credit: {
                        font: parentTheme.typography.small,
                    },
                },
                dynamicTeaser: {
                    titleHeader: {
                        font: parentTheme.typography.small,
                    },
                    title: {
                        font: parentTheme.typography.title4,
                    },
                },
                infobox: {
                    title: {
                        font: parentTheme.typography.title4,
                    },
                    content: {
                        font: parentTheme.typography.medium,
                    },
                },
                interviewSegment: {
                    question: {
                        font: parentTheme.typography.large,
                    },
                    answer: {
                        font: parentTheme.typography.medium,
                    },
                },
                list: {
                    font: parentTheme.typography.medium,
                },
                paragraph: {
                    font: parentTheme.typography.medium,
                    crosshead: {
                        font: parentTheme.typography.title4,
                    },
                    footer: {
                        font: parentTheme.typography.small,
                    },
                    lead: {
                        font: parentTheme.typography.xlarge,
                    },
                    subsection: {
                        font: parentTheme.typography.title4,
                    },
                    template: {
                        font: parentTheme.typography.medium,
                    },
                    title: {
                        font: parentTheme.typography.title1,
                    },
                    titleHeader: {
                        font: parentTheme.typography.subtitle3,
                    },
                },
                poll: {
                    question: {
                        font: parentTheme.typography.large,
                    },
                    answer: {
                        font: parentTheme.typography.medium,
                    },
                },
                quote: {
                    caption: {
                        font: parentTheme.typography.large,
                    },
                    dash: {
                        font: parentTheme.typography.large,
                    },
                    text: {
                        font: parentTheme.typography.xlarge,
                    },
                },
                separator: {
                    width: 'auto',
                },
                slideshow: {
                    caption: {
                        font: parentTheme.typography.medium,
                    },
                    credit: {
                        font: parentTheme.typography.small,
                    },
                },
                summary: {
                    font: parentTheme.typography.medium,
                },
            },
        },
    });

export default theme;
