import { castArray, isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { type MetadataRouter, useMetadataClient } from '@@api/services/metadata/client';
import { defaultQueryPlaceholderData } from '@@api/utils/defaultPlaceholderData';
import { getQueryParams } from '@@api/utils/getQueryParams';
import OrderableTable from '@@components/OrderableTable';
import Table from '@@components/Table';
import linkToContent from '@@containers/ContentItem/utils/linkToContent';

import ContentItem from './ContentItem';

const DEFAULT_PAGE_SIZE = 20;

const ACTION_TYPES = {
    EDIT: 'edit',
    DELETE: 'delete',
};

export type Value = number[] | null;

export type Props = {
    className?: string;
    onChange: (value: Value) => void;
    columns?: TableColumn[];
    orderable?: boolean;
    readOnly?: boolean;
    value: Value;
    withHeader?: boolean;
};

export const ContentItemList = ({
    value,
    onChange,
    orderable,
    readOnly,
    withHeader = false,
    columns,
    className,
}: Props) => {
    const navigate = useNavigate();
    const ids = useMemo(() => (value && castArray(value)) || [], [value]);

    const shouldFetch = Boolean(ids.length > 0);

    const requestParams = useMemo(
        () =>
            getQueryParams<MetadataRouter['metadata']['getList']>({
                size: DEFAULT_PAGE_SIZE,
                // Sort the array so that it doesn't refetch if the order changes
                ids: ((value && [...castArray(value)].sort()) || []).toString(),
            }),
        [value],
    );

    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();

    const { data: data2 } = metadataClient.metadata.getList.useQuery({
        queryKey: metadataKeys.metadata.getList({ query: requestParams }),
        queryData: {
            query: requestParams,
        },

        enabled: shouldFetch,
        placeholderData: defaultQueryPlaceholderData(DEFAULT_PAGE_SIZE),
    });

    const content = data2?.body.content || [];

    if (isEmpty(ids)) {
        return null;
    }

    const handleOnDelete = (teaserId) => {
        if (Array.isArray(value)) {
            onChange(value.filter((itemId) => itemId !== teaserId));
        } else if (value === teaserId) {
            onChange(null);
        }
    };

    const handleChange = (value) => onChange(value.map(({ id }) => id));

    const onClickFieldAction = (e, action, col, record) => {
        if (action.type === ACTION_TYPES.EDIT) {
            linkToContent(record, navigate, true);
        }

        if (action.type === ACTION_TYPES.DELETE) {
            handleOnDelete(record.id);
        }
    };

    const data = ids.map((item) => ({
        id: item,
        ...content.find((metadata) => metadata?.id === item),
    }));

    const tableColumns = columns || [
        {
            fieldName: 'id',
            render: (props) => (
                <ContentItem
                    key={props.rowIndex}
                    id={props.record.id}
                    onDelete={handleOnDelete}
                    readOnly={readOnly}
                />
            ),
        },
    ];

    return orderable ? (
        <OrderableTable
            className={className}
            data={data}
            onChange={handleChange}
            withHeader={withHeader}
            onClickFieldAction={onClickFieldAction}
            columns={tableColumns}
        />
    ) : (
        <Table className={className} data={data} withHeader={withHeader} columns={tableColumns} />
    );
};

export default ContentItemList;
