import { type CSSObject, Stack, styled } from '@mui/material';
import { get, omit } from 'lodash-es';
import { type ElementType, type ReactNode } from 'react';
import invariant from 'tiny-invariant';

import Icon from '@@components/Icon';
import { Editor, Element } from '@@editor/helpers';
import { type ElementAttributes, type EmbedElement } from '@@editor/helpers/Element';
import asSlateNode from '@@editor/hoc/asSlateNode';
import ElementWrapper, { type WidthType } from '@@editor/toolbars/ElementWrapper/ElementWrapper';
import { type MimeTypeConfig, type ToolbarConfig } from '@@editor/typings/Embed';
import { PLUGIN_NAMES, type PluginName } from '@@editor/typings/UnityPlugins';
import { getToolbarSettings } from '@@editor/utils/getToolbarSettings';

import EmbeddedContentFrame from './../embeddedcontent/EmbeddedContentFrame';
import EditableElementFooter from './EditableElementFooter';
import ElementFooter from './ElementFooter';
import EmbedOverlay from './EmbedOverlay';
import EmbedProgress from './EmbedProgress';

const StyledIcon = styled(Icon)(({ theme }) => ({
    height: 'auto',
    width: '30%',
    marginTop: theme.spacing(4),
}));

export type Props = {
    type: PluginName;
    attributes: ElementAttributes;
    className: string;
    contentEditable?: boolean;
    component: ElementType;
    overlayComponent?: ElementType | null;
    placeholderComponent?: ElementType;
    placeholderIconName?: string;
    placeholderText?: string;
    editor: Editor;
    element: EmbedElement;
    extendedStyles?: CSSObject;
    readOnly: boolean;
    toolbarConfig: ToolbarConfig;
    withCaption?: boolean;
    mimetypeConfigs?: MimeTypeConfig;
    widthType?: WidthType;
    children: ReactNode;
};

const Placeholder = styled(Stack)(({ theme }) => ({
    width: '100%',
    backgroundColor: theme.palette.gold.light,
    ...theme.typography.title2,
    padding: theme.spacing(6),
    color: theme.palette.gold.dark,
}));

const WrapperStack = styled(Stack, { shouldForwardProp: (prop: string) => !prop.startsWith('$') })<{
    $templateElement?: boolean;
}>(({ $templateElement, contentEditable, ...props }) => ({
    flexDirection: 'row',
    backgroundColor: 'transparent',
    border: '0 none',
    margin: 0,
    padding: 0,
    minHeight: $templateElement ? '290px' : '160px',
    position: 'relative',
    backgroundSize: 'cover',
    width: '100%',
    userSelect: contentEditable !== true ? 'none' : 'auto',
    [`${props['data-styles']} img`]: {
        objectFit: 'cover',
    },
    '&[data-align="full-width"] img, &[data-align="left"] img, &[data-align="right"] img': {
        maxWidth: '100%',
        height: 'auto',
    },
}));

type ContentProps = {
    component: ElementType;
    element: EmbedElement;
    embedWrapperProps: Props;
};

const Content = ({ component: Component, element, embedWrapperProps }: ContentProps) => {
    const { templateElement } = element.data;

    if (templateElement) {
        const PlaceholderComponent = embedWrapperProps.placeholderComponent;

        if (PlaceholderComponent) {
            return <PlaceholderComponent {...embedWrapperProps} />;
        }

        if (embedWrapperProps.placeholderText && embedWrapperProps.placeholderIconName) {
            return (
                <Placeholder alignItems="center" justifyContent="center">
                    {embedWrapperProps.placeholderText}
                    <StyledIcon name={embedWrapperProps.placeholderIconName} />
                </Placeholder>
            );
        }

        return <div>No placeholder information found for {element.type}</div>;
    }

    return <Component {...embedWrapperProps} />;
};

export const EmbedWrapper = (props: Props) => {
    const {
        children,
        element,
        extendedStyles,
        className,
        readOnly,
        type,
        contentEditable,
        component: Component,
        mimetypeConfigs,
        withCaption,
        toolbarConfig,
        editor,
        overlayComponent: Overlay = EmbedOverlay,
        widthType,
    } = props;

    invariant(
        !(contentEditable === true && Editor.isVoid(editor, element)),
        'The content of a void `EmbedWrapper` cannot be editable: ' + JSON.stringify(element),
    );

    const data = element.data;
    const align = data.align || 'full-width';

    const coverIframes = editor.getDataIn([PLUGIN_NAMES.DRAG_DROP, 'coverIframes']);

    const mappedComponent = Component || get(mimetypeConfigs, `${data.mimetype}.Component`);

    // currently, BE provides embeddedcontent with only originalSrc set,
    // if an imported embed is not supported in unity OR the mapping is not clear (todo).
    const component = mappedComponent || EmbeddedContentFrame;

    // We need this log for imported embeds from contentstation, which are not yet supported in unity.
    // Either BE is doing the transformation CS -> Unity wrong, or FE lacks of plugin support.
    if (!mappedComponent) {
        console.warn(
            `No component is specified for embed with type '${type}' and mimetype '${data.mimetype}'. Falling back to iframe. This happens in case of unsupported media embeds.`,
        );
    }

    const renderElementFooter = () => {
        if (!Editor.isVoid(editor, element)) {
            return (
                <EditableElementFooter {...{ element, withCaption }}>
                    {contentEditable ? null : children}
                </EditableElementFooter>
            );
        }

        return (
            <>
                <ElementFooter {...{ element, withCaption }}>
                    {contentEditable ? null : children}
                </ElementFooter>
            </>
        );
    };

    const renderElementContent = () => {
        // We need to check if the element is an interview segment element.
        // We need to apply the templateElement styles to the wrapper only for non interview and non dynamic teaser templates.
        const isInlineElement =
            Element.isInterviewSegmentElement(element) ||
            Element.isDynamicTeaserElement(element) ||
            Element.isPollElement(element) ||
            Element.isSummaryListElement(element);

        // The overlay should not render any children. The overlay should only be an empty div - of course there might me exceptions.
        // It's the footers or the content components responsability to render the children.
        // Multiple rendering of the children does not make sense and could lead to wrong behaviours.
        const overlayProps = omit(props, 'children');

        return (
            <>
                <WrapperStack
                    contentEditable={contentEditable && !readOnly}
                    data-align={align || 'center'}
                    data-styles={extendedStyles}
                    justifyContent="center"
                    className={className}
                    $templateElement={Element.isTemplateElement(element) && !isInlineElement}
                >
                    <Content component={component} element={element} embedWrapperProps={props} />

                    <EmbedProgress editor={editor} element={element} />

                    {Element.isEmbedElement(element) && coverIframes && !readOnly && Overlay ? (
                        <Overlay {...overlayProps} />
                    ) : null}
                </WrapperStack>

                {renderElementFooter()}
            </>
        );
    };

    const toolbarSettings = getToolbarSettings(editor, element);

    // Only show toolbar for root level elements, never for nested elements.
    if (toolbarSettings?.embedWrapperToolbar.enabled) {
        return (
            // The attributes must be added to the top-level DOM element inside the component https://docs.slatejs.org/concepts/09-rendering
            <ElementWrapper<EmbedElement>
                {...{
                    toolbarConfig,
                    type,
                    editor,
                    element,
                    readOnly,
                    widthType,
                }}
            >
                {renderElementContent()}
            </ElementWrapper>
        );
    }

    return renderElementContent();
};

EmbedWrapper.displayName = 'EmbedWrapper';

export default asSlateNode()(EmbedWrapper);
