import { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type Descendant } from 'slate';

import useFileHead from '@@api/hooks/useFileHead';
import useGenerateImageAltText from '@@api/hooks/useGenerateImageAltText';
import useUploadFile from '@@api/hooks/useUploadFile';
import { type Tenant } from '@@api/services/tenant/schemas';
import config from '@@config';
import { useEditorElementsContext } from '@@editor/EditorElementsContext';
import setupPlugins, { ALL_PLUGIN_CONFIG } from '@@editor/plugins/setup';
import {
    type OptionsPerPlugin,
    PLUGIN_NAMES,
    type PluginList,
    type PluginName,
} from '@@editor/typings/UnityPlugins';
import isPluginAvailable from '@@editor/utils/isPluginAvailable';
import useFetchMetadata from '@@form/hooks/useFetchMetadata';
import usePostSpellCheck from '@@form/hooks/usePostSpellCheck';
import usePostTickerSummary from '@@form/hooks/usePostTickerSummary';
import { getContentLocaleSetting, getReducedUISmartSetting } from '@@settings/settingsSlice';
import { useSelector } from '@@store/hooks';

import RichTextEditorBase, { type Props as RichTextEditorBaseProps } from './RichTextEditorBase';

export type Props = Omit<RichTextEditorBaseProps, 'plugins'> & {
    includedPlugins?: PluginName[];
    excludedPlugins?: PluginName[];
    pluginConfig?: PluginList[];
    pluginOptions?: OptionsPerPlugin;
    tenantIds?: Tenant['id'][];
    allowAttachments?: boolean;
    resetOnChangedProp?: {
        fieldName: string;
        parse?: (value: Descendant[]) => any;
    };
};

const RichTextEditor: FC<Props> = (props) => {
    const {
        includedPlugins = [],
        pluginConfig = ALL_PLUGIN_CONFIG,
        pluginOptions = {},
        tenantIds,
        allowAttachments = true,
    } = props;

    let {
        excludedPlugins = [
            PLUGIN_NAMES.INFOBOX,
            PLUGIN_NAMES.POLL,
            PLUGIN_NAMES.DYNAMIC_TEASER,
            PLUGIN_NAMES.COMMENT,
            PLUGIN_NAMES.SPELL_CHECKER,
            PLUGIN_NAMES.TICKER_SUMMARY,
            PLUGIN_NAMES.SUMMARY,
            PLUGIN_NAMES.IMPORT_INTERVIEW,
        ],
    } = props;

    const { actions: elementsContextActions } = useEditorElementsContext();
    const { t } = useTranslation();
    const contentLocale = useSelector(getContentLocaleSetting);
    const reducedUI = useSelector(getReducedUISmartSetting);
    const fileHead = useFileHead();
    const uploadFile = useUploadFile();
    const generateImageAltText = useGenerateImageAltText();

    const fetchMetadata = useFetchMetadata();
    const spellCheck = usePostSpellCheck();
    const generateTickerSummary = usePostTickerSummary();

    if (reducedUI) {
        excludedPlugins = [...excludedPlugins, ...config.hiddenFloatingToolbarFeatures];
    }

    const plugins = useMemo(
        () =>
            setupPlugins(pluginConfig, {
                included: includedPlugins,
                excluded: excludedPlugins,
                defaultOptions: {
                    contentLocale,
                    reducedUI,
                    t,
                    fileHead,
                    uploadFile,
                    generateImageAltText,
                    tenantIds,
                    ...(allowAttachments ? { elementsContextActions } : {}),
                },
                optionsPerPlugin: {
                    ...pluginOptions,
                    [PLUGIN_NAMES.SPELL_CHECKER]: {
                        ...pluginOptions[PLUGIN_NAMES.SPELL_CHECKER],
                        spellCheck,
                        tenantIds,
                    },
                    [PLUGIN_NAMES.LINK]: {
                        ...pluginOptions[PLUGIN_NAMES.LINK],
                        fetchMetadata,
                    },
                    [PLUGIN_NAMES.INSERT_HTML]: {
                        ...pluginOptions[PLUGIN_NAMES.INSERT_HTML],
                        fetchMetadata,
                    },
                    [PLUGIN_NAMES.TICKER_SUMMARY]: {
                        ...pluginOptions[PLUGIN_NAMES.TICKER_SUMMARY],
                        generateTickerSummary,
                    },
                },
            }),
        [],
    );

    const isSpellCheckerAvailable = isPluginAvailable(PLUGIN_NAMES.SPELL_CHECKER, plugins);

    return <RichTextEditorBase {...{ ...props, plugins, spellCheck: !isSpellCheckerAvailable }} />;
};

export default RichTextEditor;
