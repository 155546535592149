import { Stack, styled } from '@mui/material';
import { type ReactNode } from 'react';
import { useSelected } from 'slate-react';

import { type Editor, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type SeparatorElement } from '@@editor/helpers/Element';
import ElementWrapper from '@@editor/toolbars/ElementWrapper/ElementWrapper';

const StackWrapper = styled(Stack)<{ selected: boolean }>(({ selected, theme }) => ({
    backgroundColor: selected ? theme.palette.primary.dark : 'transparent',
    margin: `${theme.spacing(2)} auto ${theme.spacing(2)}`,
    maxWidth: theme.fixed.editor.textElement.width,
    width: theme.fixed.editor.separator.width,
}));

const Hr = styled('hr')<{ selected: boolean }>(({ selected, theme }) => ({
    borderWidth: `${theme.borders[1]} 0 0`,
    borderColor: selected ? theme.palette.common.white : theme.palette.text.primary,
    width: '100%',
}));

type Props = {
    attributes: ElementAttributes;
    children: ReactNode;
    editor: Editor;
    element: SeparatorElement;
};

const Separator = ({ attributes, children, editor, element }: Props) => {
    const selected = useSelected();
    const readOnly = ReactEditor.isReadOnly(editor);

    return (
        <ElementWrapper
            {...{ attributes, children, editor, element, readOnly }}
            widthType="text"
            toolbarConfig={{ actions: ['delete'] }}
        >
            <div contentEditable={false}>
                <StackWrapper
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    selected={selected}
                >
                    <Hr selected={selected} />
                </StackWrapper>

                {children}
            </div>
        </ElementWrapper>
    );
};

export default Separator;
