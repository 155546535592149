import {
    combineReducers,
    configureStore,
    createListenerMiddleware,
    type Middleware,
} from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import handleApiError from '@@api/errorHandler';
import authSlice from '@@auth/authSlice';
import checkAuthentication from '@@auth/middleware/checkAuthentication';
import config from '@@config';
import i18n from '@@lib/i18n/i18n';
import persistedI18nSlice, { setLocale } from '@@lib/i18n/i18nSlice';
import settingsSlice from '@@settings/settingsSlice';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: setLocale,
    effect: async (action) => {
        await i18n.changeLanguage(action.payload);
    },
});

const shouldDiff = window && window.localStorage.getItem('reduxDiff') === 'true';
const reduxLogger = createLogger({
    collapsed: true,
    duration: true,
    diff: shouldDiff,
});

export const rootReducer = combineReducers({
    auth: authSlice,
    settings: settingsSlice,
    i18n: persistedI18nSlice,
});

const persistedReducer = persistReducer(config.reduxPersist, rootReducer);

const conditionalConcat: Middleware[] = [];

if (
    import.meta.env.MODE === 'development' &&
    window &&
    window.localStorage.getItem('reduxLogger') === 'true'
) {
    conditionalConcat.push(reduxLogger);
}

const logger: Middleware = () => (next) => (action: any) => {
    if (action.error && action.payload && action.payload.error) {
        const error = action.payload.error;

        handleApiError(error);
    }

    return next(action);
};

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .prepend(checkAuthentication, listenerMiddleware.middleware, logger)
            .concat(...conditionalConcat);

        return middlewares;
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
