import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import config from '@@config';

import { LANG_CODES, type LangCode } from './constants';
import { locales } from './translations';

type I18NState = {
    locale: LangCode;
    fullLocale: LangCode;
    locales: typeof locales;
};

const initialState: I18NState = {
    locale: LANG_CODES.ENGLISH,
    fullLocale: LANG_CODES.ENGLISH,
    locales,
};

export const i18nSlice = createSlice({
    name: 'i18n',
    initialState,
    reducers: {
        importLocales(state, action: PayloadAction<Pick<I18NState, 'locales'>>) {
            return {
                ...state,
                locales: {
                    ...state.locales,
                    ...action.payload.locales,
                },
            };
        },
        setLocale(state, action: PayloadAction<LangCode>) {
            return {
                ...state,
                locale: action.payload || initialState.locale,
                fullLocale: action.payload || initialState.fullLocale,
            };
        },
    },
    selectors: {
        getLocale: (state) => state.locale,
        getFullLocale: (state) => state.fullLocale,
    },
});

const persistedI18nReducer = persistReducer(config.reduxPersistI18n, i18nSlice.reducer);

export const { importLocales, setLocale } = i18nSlice.actions;
export const { getLocale, getFullLocale } = i18nSlice.selectors;
export default persistedI18nReducer;
