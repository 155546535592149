import { type RouteObject } from 'react-router';

export const routes: RouteObject[] = [
    {
        path: 'metadata',
        children: [
            {
                path: ':metadataId',
                children: [
                    {
                        path: ':tenantId?',
                        lazy: async () => {
                            const { PreviewMetadata } = await import(
                                '@@routes/preview/PreviewMetadata'
                            );

                            return { Component: PreviewMetadata };
                        },
                    },
                ],
            },
        ],
    },
    {
        path: 'placement',
        children: [
            {
                path: ':sectionId',
                children: [
                    {
                        path: ':tenantId',
                        lazy: async () => {
                            const { PreviewPlacement } = await import(
                                '@@routes/preview/PreviewPlacement'
                            );

                            return { Component: PreviewPlacement };
                        },
                    },
                ],
            },
        ],
    },
];
