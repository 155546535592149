import { type RouteObject } from 'react-router';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { EmbedsList } = await import('@@routes/externalContent/embeds/');

            return { Component: EmbedsList };
        },
    },
    {
        path: 'new',
        lazy: async () => {
            const { EmbedsSetupPage } = await import('@@routes/externalContent/embeds');

            return { Component: EmbedsSetupPage };
        },
    },
    {
        path: ':id',
        lazy: async () => {
            const { EmbedsDetailPage } = await import('@@routes/externalContent/embeds');

            return { Component: EmbedsDetailPage };
        },
        children: [
            {
                path: '*',
                lazy: async () => {
                    const { EmbedsDetailPage } = await import('@@routes/externalContent/embeds');

                    return { Component: EmbedsDetailPage };
                },
            },
        ],
    },
];
