import { IconButton, type IconButtonProps } from '@mui/material';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@@components/Icon';

export const PreviewButton: FC<IconButtonProps> = ({ onClick, ...props }) => {
    const { t } = useTranslation();

    return (
        <IconButton
            size="small"
            title={t('contentitem.previewButton')}
            onClick={onClick}
            {...props}
        >
            <Icon name="eye-regular" size="small" />
        </IconButton>
    );
};

export const EditButton: FC<IconButtonProps> = ({ onClick, ...props }) => {
    const { t } = useTranslation();

    return (
        <IconButton size="small" title={t('contentitem.editButton')} onClick={onClick} {...props}>
            <Icon name="pen-regular" size="small" />
        </IconButton>
    );
};

export const DeleteButton: FC<IconButtonProps> = ({ onClick, ...props }) => {
    const { t } = useTranslation();

    return (
        <IconButton
            size="small"
            title={props.title || t('contentitem.deleteButton')}
            onClick={onClick}
            {...props}
        >
            <Icon name="trash-can" size="small" />
        </IconButton>
    );
};

type BookmarkButtonProps = IconButtonProps & {
    bookmarked: boolean;
};

export const BookmarkButton: FC<BookmarkButtonProps> = ({ onClick, bookmarked, ...props }) => {
    const { t } = useTranslation();

    return (
        <IconButton
            color={bookmarked ? 'secondary' : 'primary'}
            size="small"
            title={t('contentitem.bookmarkButton')}
            onClick={onClick}
            {...props}
        >
            <Icon name="star" size="small" />
        </IconButton>
    );
};
