import { useTranslation } from 'react-i18next';

import { useAuthClient } from '@@api/services/auth/client';
import { type User } from '@@api/services/auth/schemas';

import BasicLinkPreview from './BasicLinkPreview';

type Props = {
    id: User['id'];
};

const AuthorPreview: React.FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const { client: authClient, queryKeys: authKeys } = useAuthClient();
    const params = { id };

    const { data, isLoading } = authClient.users.profile.get.useQuery({
        queryKey: authKeys.users.profile.get({ params }),
        queryData: { params },
        enabled: Boolean(id),
    });

    if (!id) {
        return null;
    }

    const user = data?.body;

    return (
        <BasicLinkPreview
            isLoading={isLoading}
            title={user?.fullName}
            iconName="users"
            label={t('link.authorLink.title')}
        />
    );
};

export default AuthorPreview;
