import { Button, styled } from '@mui/material';
import { type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@@components/Icon';
import { type Editor, Element, ReactEditor } from '@@editor/helpers';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

const AddEmbedButton = styled(Button)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});

const Wrapper = styled('div')<{ $templateElement: boolean }>(({ theme, $templateElement }) => ({
    background: $templateElement ? theme.palette.gold.light : theme.palette.primary['50'],
    minWidth: '100%',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderBottom: `${theme.borders[1]} solid ${theme.palette.primary.light}`,
    overflow: 'hidden',
}));

const StyledIcon = styled(Icon)({
    transform: 'scale(1.5)',
});

const TYPE = PLUGIN_NAMES.EMBEDDED_CONTENT;

type Props = {
    editor: Editor;
    element: Element;
};

const EmbeddedContentPlaceholder = ({ editor, element }: Props) => {
    const { t } = useTranslation();
    const handleClickAddEmbedButton = (e: MouseEvent) => {
        e.preventDefault();

        return requestAnimationFrame(() =>
            editor.showEmbedModal(TYPE, element, ReactEditor.findPath(editor, element)),
        );
    };

    const isTemplateElement = Element.isTemplateElement(element);
    const iconColorName = isTemplateElement ? 'template.dark' : 'primary';

    const hideAddButton = ReactEditor.isReadOnly(editor);

    return (
        <Wrapper $templateElement={isTemplateElement}>
            <StyledIcon name="embed-sharp" size="290px" color={iconColorName} />

            {!hideAddButton && (
                <AddEmbedButton
                    startIcon={<Icon name="plus-sharp" />}
                    variant="outlined"
                    onClick={handleClickAddEmbedButton}
                >
                    {t('editor.embeddedcontent.placeholder.addButton')}
                </AddEmbedButton>
            )}
        </Wrapper>
    );
};

export default EmbeddedContentPlaceholder;
