import { find } from 'lodash-es';

const isPluginAvailable = (pluginName: string, availablePlugins) =>
    Boolean(
        find(
            availablePlugins,
            (o) => o.name === pluginName || isPluginAvailable(pluginName, o.options?.plugins),
        ),
    );

export default isPluginAvailable;
