import { useTranslation } from 'react-i18next';

import SwitchField from '@@form/fields/SwitchField';

type Props = {
    groupName?: string;
};

const InfoboxFields = ({ groupName }: Props) => {
    const { t } = useTranslation();

    const makeName = (name) => (groupName ? `${groupName}.${name}` : name);

    return (
        <SwitchField
            name={makeName('collapsed')}
            label={t('editor.plugin.infobox.form.collapsed')}
        />
    );
};

export default InfoboxFields;
