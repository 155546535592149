import { styled } from '@mui/material';
import { type CSSProperties, forwardRef, type PropsWithChildren } from 'react';

import { type Editor } from '@@editor/helpers';
import { type ElementAttributes } from '@@editor/helpers/Element';

const Wrapper = styled('ol')(({ theme }) => ({
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    ...theme.fixed.editor.list.font,
}));

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    style?: CSSProperties;
}>;

const Ol = forwardRef<HTMLElement, Props>(({ attributes, children, style }, ref) => (
    <Wrapper {...{ ref, style, ...attributes }}>{children}</Wrapper>
));

export default Ol;
