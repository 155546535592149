import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ZERO_WIDTH_SPACE } from '@@constants/Unicode';
import { useLoadingStatusManager } from '@@containers/LoadingStatusManager/LoadingStatusManagerContext';
import { generateKeyForRichTextEditorLoadingStatus } from '@@containers/LoadingStatusManager/utils';
import { type Editor } from '@@editor/helpers';
import { type EmbedElement } from '@@editor/helpers/Element';

const BOTTOM_STRETCH_X_STYLES = {
    position: 'absolute',
    bottom: 0,
    width: '100%',
};

const BOTTOM_STRETCH_STYLES = {
    ...BOTTOM_STRETCH_X_STYLES,
    height: '100%',
};

type EmbedProgressProps = {
    editor: Editor;
    element: EmbedElement;
};

const EmbedProgress = ({ editor, element }: EmbedProgressProps) => {
    const { loadingStatusId } = element.data;
    const { t } = useTranslation();

    const uploadFileKey = generateKeyForRichTextEditorLoadingStatus({
        editorId: editor.id,
        loadingStatusId,
        type: 'uploadFile',
    });

    const generateThumbnailKey = generateKeyForRichTextEditorLoadingStatus({
        editorId: editor.id,
        loadingStatusId,
        type: 'generateThumbnail',
    });

    const generateAltTextKey = generateKeyForRichTextEditorLoadingStatus({
        editorId: editor.id,
        loadingStatusId,
        type: 'generateImageAltText',
    });

    const { isLoading: isUploadingFile, progress: fileUploadProgress } = useLoadingStatusManager({
        key: uploadFileKey,
    });

    const { isLoading: isGeneratingThumbnail } = useLoadingStatusManager({
        key: generateThumbnailKey,
    });

    const { isLoading: isGeneratingImageAltText } = useLoadingStatusManager({
        key: generateAltTextKey,
    });

    let text: string | undefined, progress: number | undefined;

    if (isUploadingFile) {
        text = t('embedProgress.uploadFile');
        progress = fileUploadProgress?.progress;
    } else if (isGeneratingThumbnail) {
        text = t('embedProgress.generateThumbnail');
    } else if (isGeneratingImageAltText) {
        text = t('embedProgress.generateImageAltText');
    }

    if (!text) {
        return null;
    }

    const variant = typeof progress === 'undefined' ? 'indeterminate' : 'determinate';

    return (
        // In order to avoid a jumpy UI, we want to use the same height as the image alt text, since the alt text
        // will be rendered in the same space (after the progress disappeared).
        <Box position="absolute" sx={{ ...BOTTOM_STRETCH_X_STYLES, opacity: 0.75 }}>
            {/* This is only here to set the height of the parent element */}
            <Typography padding={2}>{ZERO_WIDTH_SPACE}</Typography>

            {/* We need `key={variant}` here, because otherwise it leads to strange visual effects, when switching
                between `indeterminate` and `determinate` */}
            <LinearProgress
                key={variant}
                variant={variant}
                value={progress}
                sx={BOTTOM_STRETCH_STYLES}
            />

            <Stack alignItems="center" justifyContent="center" sx={BOTTOM_STRETCH_STYLES}>
                <Typography color="text.secondary" sx={{ mixBlendMode: 'difference' }}>
                    {text}
                </Typography>
            </Stack>
        </Box>
    );
};

export default EmbedProgress;
