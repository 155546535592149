import { Card, type CardProps, styled } from '@mui/material';

export const LinkFieldWrapper = styled((props: CardProps) => (
    <Card variant="outlined" {...props} />
))(({ theme }) => ({
    padding: theme.spacing(3, 5),
    borderLeft: 'none',
    borderRight: 'none',
    margin: `0 -${theme.spacing(4)}`,
}));

export const SelectionTableFieldWrapper = styled('div')(({ theme }) => ({
    margin: `0 -${theme.spacing(4)}`,
    marginTop: theme.spacing(4),
}));
