import {
    /* eslint-disable no-restricted-imports */
    useDispatch as useOriginalDispatch,
    useSelector as useOriginalSelector,
    /* eslint-enable no-restricted-imports */
} from 'react-redux';

import { type AppDispatch, type RootState } from './store';

export const useDispatch = useOriginalDispatch.withTypes<AppDispatch>();
export const useSelector = useOriginalSelector.withTypes<RootState>();
