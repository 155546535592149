// This is the only place in this stack where we import `notistack` directly! Use this abstraction
// in all other places!
/* eslint-disable no-restricted-imports */
import isPropValid from '@emotion/is-prop-valid';
import {
    Alert,
    AlertTitle,
    CircularProgress,
    GlobalStyles,
    IconButton,
    styled,
    useTheme,
} from '@mui/material';
import {
    closeSnackbar,
    type CustomContentProps,
    enqueueSnackbar,
    SnackbarContent,
    type SnackbarKey,
    SnackbarProvider as NotistackSnackbarProvider,
    type SnackbarProviderProps,
} from 'notistack';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { omitBy } from 'remeda';

import Icon from '@@components/Icon';
import config from '@@config';

declare module 'notistack' {
    export interface OptionsObject {
        customAutoHideDuration?: number | null;
        title?: string;
    }

    interface VariantOverrides {
        default: false;
    }
}

const useSnackbarTime = (props: CustomContentProps) => {
    const { customAutoHideDuration, id } = props;
    const [progress, setProgress] = useState(0);
    const times = useMemo<{
        used: number;
        interval: null | number;
        intervalStart: null | number;
    }>(() => ({ used: 0, interval: null, intervalStart: null }), []);

    useEffect(() => {
        start();

        window.addEventListener('blur', stop);
        window.addEventListener('focus', start);

        return () => {
            stop();

            window.removeEventListener('blur', stop);
            window.removeEventListener('focus', start);
        };
    }, []);

    const calcDiff = () => (times.intervalStart == null ? 0 : Date.now() - times.intervalStart);

    const start = () => {
        if (customAutoHideDuration == null) {
            return;
        }

        stop();

        times.intervalStart = Date.now();
        times.interval = window.setInterval(() => {
            const totalUsed = calcDiff() + times.used;
            const nextProgress = Math.min(100, (totalUsed * 100) / customAutoHideDuration);

            setProgress(nextProgress);

            if (nextProgress >= 100) {
                stop();
                closeSnackbar(id);
            }
        }, 100);
    };

    const stop = () => {
        if (customAutoHideDuration == null) {
            return;
        }

        if (times.interval) {
            times.used += calcDiff();

            clearInterval(times.interval);
            times.interval = null;
            times.intervalStart = null;
        }
    };

    return { progress, onMouseEnter: stop, onMouseLeave: start };
};

const StretchBox = styled('div')({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const SnackbarItem = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
    const { customAutoHideDuration, id, message, title, ...rest } = props;
    const { progress, onMouseEnter, onMouseLeave } = useSnackbarTime(props);
    const theme = useTheme();
    const validRest = omitBy(rest, (value, key) => isPropValid(key) || key === 'action');

    return (
        <SnackbarContent {...validRest} ref={ref}>
            <Alert
                action={
                    <IconButton
                        color="inherit"
                        size="small"
                        onClick={() => {
                            closeSnackbar(id);
                        }}
                    >
                        <Icon name="xmark" />

                        {customAutoHideDuration != null && (
                            <StretchBox>
                                <CircularProgress
                                    variant="determinate"
                                    value={progress}
                                    size={theme.spacing(4)}
                                    sx={{ color: `${rest.variant}.contrastText`, opacity: 0.3 }}
                                />
                            </StretchBox>
                        )}
                    </IconButton>
                }
                elevation={2}
                severity={rest.variant}
                sx={{ flexGrow: 1 }}
                variant="filled"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {title && <AlertTitle>{title}</AlertTitle>}
                {message}
            </Alert>
        </SnackbarContent>
    );
});

const containerAnchorOriginTopRight = 'notistack-SnackbarContainerTopRight';
const root = 'notistack-Snackbar';

const SnackbarProvider = (props: SnackbarProviderProps) => (
    <>
        <GlobalStyles
            styles={(theme) => ({
                [`.${containerAnchorOriginTopRight}`]: {
                    inset: theme.spacing(3, 0),
                    maxWidth: '100%',
                    '.notistack-CollapseWrapper': {
                        padding: theme.spacing(2, 4),
                        [`.${root}`]: {
                            maxWidth: theme.fixed.snackbar.width,
                        },
                    },
                },
            })}
        />

        <NotistackSnackbarProvider {...props} classes={{ containerAnchorOriginTopRight, root }} />
    </>
);

const snackbar = ['warning', 'error', 'success', 'info'].reduce(
    (result, variant) =>
        Object.assign(result, {
            [variant]: (message, options) => {
                // As long as `SnackbarProvider` is not mounted yet, `enqueueSnackbar` will be undefined
                if (enqueueSnackbar) {
                    enqueueSnackbar(message, {
                        // The native `autoHideDuration` option is buggy (snackbar is hidden too early) and we also
                        // want to display a progress bar. Therefore we use our custom implementation.
                        customAutoHideDuration: config.snackbar.autoHideDuration,
                        ...options,
                        variant,
                    });
                }
            },
        }),
    {
        close: (key?: SnackbarKey) => {
            // As long as `SnackbarProvider` is not mounted yet, `closeSnackbar` will be undefined
            if (closeSnackbar) {
                closeSnackbar(key);
            }
        },
    },
) as {
    warning: typeof enqueueSnackbar;
    error: typeof enqueueSnackbar;
    success: typeof enqueueSnackbar;
    info: typeof enqueueSnackbar;
    close: typeof closeSnackbar;
};

export * from 'notistack';
export { SnackbarItem, SnackbarProvider };
export default snackbar;
