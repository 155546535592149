import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import ButtonGroup from '@@form/components/ButtonGroup';

import ContentSelectionTable, {
    type Props as ContentSelectionTableProps,
} from './ContentSelectionTableField';

type SelectedRelatedContent = Id[] | Id;

export type Props = {
    submitButtonText?: string;
    selectedRelatedContent?: SelectedRelatedContent;
    onCancel: VoidFunction;
    onSubmit: (selectedContent: SelectedRelatedContent) => void;
    onSubmitSuccess?: VoidFunction;
    modalLeavePrompt?: () => boolean;
    className?: string;
} & ContentSelectionTableProps;

const SelectionForm = ({
    onCancel,
    onSubmit,
    onSubmitSuccess,
    submitButtonText,
    className,
    multiple,
    selectedRelatedContent,
    columns,
    modalLeavePrompt,
    params,
    forceContentTypes,
    disableUrlSync,
    searchProps,
    isRowDisabled,
}: Props) => {
    const { t } = useTranslation();

    const renderButtons = (Buttons) => (
        <ButtonGroup>
            <Buttons.CancelButton />
            <Buttons.SubmitButton>
                {submitButtonText || t('relatedcontent.submitButton')}
            </Buttons.SubmitButton>
        </ButtonGroup>
    );

    const initialValuesInterceptor = useCallback((values) => ({ tableSelection: values }), []);
    const valuesInterceptor = useCallback(({ tableSelection }) => tableSelection, []);

    const values = initialValuesInterceptor(selectedRelatedContent);

    const handleSubmit: UseReactHookFormProps['onSubmit'] = (values) => {
        const newValues = valuesInterceptor(values);

        onSubmit(newValues);

        onSubmitSuccess?.();
    };

    return (
        <ReactHookForm
            {...{
                className,
                modalLeavePrompt,
                renderButtons,
                onCancel,
            }}
            onSubmit={handleSubmit}
            formName="SelectionForm"
            values={values}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            autoFocus={false}
        >
            <ContentSelectionTable
                name="tableSelection"
                multiple={multiple}
                params={params}
                columns={columns}
                forceContentTypes={forceContentTypes}
                disableUrlSync={disableUrlSync}
                isRowDisabled={isRowDisabled}
                searchProps={searchProps}
            />
        </ReactHookForm>
    );
};

export default SelectionForm;
