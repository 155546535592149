import { styled } from '@mui/material';

import Image from '@@components/Image';
import {
    type ImageElementOverlayIcon,
    ImageElementOverlayIconType,
} from '@@editor/helpers/Element';
import lesereporter from '@@images/overlay/lesereporter.png';
import newsticker from '@@images/overlay/newsticker.png';
import video from '@@images/overlay/video.png';

const ICON_TYPES = {
    [ImageElementOverlayIconType.Lesereporter]: lesereporter,
    [ImageElementOverlayIconType.Newsticker]: newsticker,
    [ImageElementOverlayIconType.Video]: video,
};

const getPosition = (props) => {
    const pos = props['data-position'];
    const spacing = props.theme.spacing(4);

    switch (pos) {
        case 'bottom-right':
            return { bottom: spacing, right: spacing };
        case 'bottom-left':
            return { bottom: spacing, left: spacing };
        case 'top-right':
            return { top: spacing, right: spacing };
        case 'top-left':
            return { top: spacing, left: spacing };
        default:
            return {};
    }
};

const AbsoluteImage = styled(Image)((props) => ({
    position: 'absolute',
    ...getPosition(props),
}));

type Props = ImageElementOverlayIcon;

const OverlayIcon = ({ position, type }: Props) => (
    <AbsoluteImage data-position={position} src={ICON_TYPES[type]} placeholder={undefined} alt="" />
);

export default OverlayIcon;
