import { isEmpty } from 'lodash-es';

import { type SpellCheckerRouter } from '@@api/services/spellChecker/client';
import {
    type SpellCheckResponseBody,
    type SpellCheckTexts,
} from '@@api/services/spellChecker/schemas';
import { getQueryParams } from '@@api/utils/getQueryParams';
import { ErrBadRequest } from '@@api/utils/schemas/errors';
import { type Editor, Element, Node } from '@@editor/helpers';
import { asyncDebounce, type AsyncDebounceOptions } from '@@utils/function';

import { setEditorContextPluginData } from './data';
import { clearSpellAdvices, importSpellAdvices } from './spellAdvices';
import { type PluginOptions } from '../types';

const EDITOR_TO_DEBOUNCED_FUNCTION = new WeakMap<
    Editor,
    ReturnType<typeof createDebouncedSpellCheck>
>();

export const collectText = (result: SpellCheckTexts, index: string, editor: Editor) => {
    const node = Node.get(editor, index.split(',').map(Number));

    if (Element.isBlockList(node.children)) {
        node.children.forEach((child, childIndex) => {
            collectText(result, `${index},${childIndex}`, editor);
        });
    } else {
        const text = Node.string(node).trim();

        if (text) {
            // Since this is a collector function which is used in a `reduce`-call, it is fine to write
            // directly into this object-param
            // eslint-disable-next-line no-param-reassign
            result[index] = text;
        }
    }

    return result;
};

export const getTexts = (editor: Editor, dirtyIndices?: number[]) =>
    typeof dirtyIndices !== 'undefined'
        ? dirtyIndices.reduce<SpellCheckTexts>(
              (result, index) => collectText(result, String(index), editor),
              {},
          )
        : editor.children.reduce<SpellCheckTexts>(
              (result, _, index) => collectText(result, String(index), editor),
              {},
          );

type DebouncedSpellCheckArgs = [editor: Editor, dirtyIndices?: number[]];

const createDebouncedSpellCheck = (
    pluginOptions: PluginOptions,
    options: AsyncDebounceOptions<DebouncedSpellCheckArgs>,
) =>
    asyncDebounce<
        { body: SpellCheckResponseBody; texts: SpellCheckTexts },
        DebouncedSpellCheckArgs
    >((editor, dirtyIndices) => {
        const texts = getTexts(editor, dirtyIndices);

        if (isEmpty(texts)) {
            return Promise.resolve({ body: { spellAdvices: {} }, texts });
        }

        return pluginOptions
            .spellCheck({
                query: getQueryParams<SpellCheckerRouter['spellCheck']['post']>({
                    tenantIds: pluginOptions.tenantIds || [],
                }),
                body: {
                    texts,
                },
            })
            .then(({ body }) => ({
                body,
                texts,
            }));
    }, options);

// We could not use lodash's debounce function, since they do not offer debounce for async functions.
// We need to execute some code on first call of the debounced function (and then some more code whenever
// the debounce timeout has reached)
export const debouncedSpellCheck = (
    editor: Editor,
    options: PluginOptions,
    dirtyIndices?: number[],
) => {
    let debouncedFunction = EDITOR_TO_DEBOUNCED_FUNCTION.get(editor);

    if (!debouncedFunction) {
        // We need to create the `debouncedSpellCheck` function during runtime, since it depends on the
        // `options.spellCheck` function, which we get as an argument. But we also need to cache it, otherwise
        // it will not work (if we generate a new debounce function on every call, it will of course never debounce,
        // because every call to it, will be the first call to it and therefore does not need to be debounced)
        debouncedFunction = createDebouncedSpellCheck(options, {
            onFirstCall: (editor, dirtyIndices) => {
                const isPartial = typeof dirtyIndices !== 'undefined';

                // We want to display the loading state as quick as possible. `onFirstCall` is a good spot for this,
                // since it will be called immediately for the first call of the current "debounce phase" (even without
                // waiting for the first debounce timeout)
                setEditorContextPluginData(editor, {
                    isLoading: !isPartial,
                    isPartiallyLoading: isPartial,
                });
            },
        });

        EDITOR_TO_DEBOUNCED_FUNCTION.set(editor, debouncedFunction);
    }

    const isPartial = typeof dirtyIndices !== 'undefined';

    // Never debounce requests that check the whole document. Reason: The whole document is only checked
    // when the editor is initialized or when the spell checker is in error state. In these situations,
    // a debounce does not make any sense (we want an immediate response in those situations)
    debouncedFunction.setDebounceDelay(isPartial ? options.debounceDelay : 0);

    return debouncedFunction(editor, dirtyIndices)
        .then((value) => {
            const {
                body: { spellAdvices },
                texts,
            } = value;

            importSpellAdvices(editor, spellAdvices, texts);

            setEditorContextPluginData(editor, {
                isLoading: false,
                isPartiallyLoading: false,
                isError: false,
            });
        })
        .catch((reason) => {
            const hasDebounceBeenCanceled = reason === 'canceled';

            if (!hasDebounceBeenCanceled) {
                // In order to not swallow all the erros, log them here (except for api errors,
                // those are handled by our global `errorHandler`)
                console.error(reason);
            }

            if (!hasDebounceBeenCanceled) {
                setEditorContextPluginData(editor, {
                    isLoading: false,
                    isPartiallyLoading: false,
                    isError: true,
                    errorCode: ErrBadRequest,
                });

                // Once an error happened, we want to clear all spell advices. We don't know what
                // happened and cannot guarantee that what we would show to the user would still
                // make sense
                clearSpellAdvices(editor);
            }

            return hasDebounceBeenCanceled;
        });
};
