import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders } from '@@api/headers';
import {
    Category,
    CollectionItem,
    Locale,
    Menu,
    NewCategory,
    PageStyle,
    Sitemap,
    Tenant,
    TenantGroup,
    UnityCategory,
} from '@@api/services/tenant/schemas';
import {
    deserializeCategory,
    deserializeMenu,
    deserializeSitemap,
    serializeCategory,
    serializeMenu,
    serializeSitemap,
} from '@@api/services/tenant/transformers';
import { getQueryKeys } from '@@api/utils/queryKeys';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import { ContentLocale } from '@@api/utils/schemas/schemas';
import { paginated } from '@@api/utils/schemas/utils';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        category: contract.router({
            getAll: {
                method: 'GET',
                path: '/categories',
                query: z.object({
                    ...SearchParams.pick({ q: true, tenantIds: true }).shape,
                    ...PaginationParams.pick({ sort: true, size: true }).shape,
                    active: z.boolean().nullish(),
                    syncedWithDesknet: z.boolean().optional(),
                    name: z.string().optional(),
                    includeIds: z.string().optional(),
                    contentLocale: ContentLocale.optional(),
                }),
                responses: {
                    200: z.array(UnityCategory.transform(deserializeCategory)),
                },
            },
            getAllPaginated: {
                method: 'GET',
                path: '/categories',
                query: z.object({
                    ...SearchParams.pick({ q: true, tenantIds: true }).shape,
                    ...PaginationParams.shape,
                    active: z.boolean().nullish(),
                    syncedWithDesknet: z.boolean().optional(),
                    name: z.string().optional(),
                    includeIds: z.string().optional(),
                    contentLocale: ContentLocale.optional(),
                }),
                responses: {
                    200: paginated(UnityCategory.transform(deserializeCategory)),
                },
            },
            head: {
                method: 'GET',
                metadata: {
                    method: 'HEAD',
                },
                path: '/categories',
                query: z.object({
                    name: z.string(),
                    excludeId: z.number(),
                }),
                responses: {
                    200: z.never(),
                    404: z.never(),
                },
            },
            get: {
                method: 'GET',
                path: '/categories/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: UnityCategory.transform(deserializeCategory),
                },
            },
            put: {
                method: 'PUT',
                path: '/categories/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: Category.transform(serializeCategory),
                responses: {
                    200: UnityCategory.transform(deserializeCategory),
                },
            },
            post: {
                method: 'POST',
                path: '/categories',
                body: NewCategory.transform(serializeCategory),
                responses: {
                    201: UnityCategory.transform(deserializeCategory),
                },
            },
        }),
        tenantGroup: contract.router({
            get: {
                method: 'GET',
                path: '/tenant-groups',
                query: z.object({
                    contentLocale: ContentLocale.nullish(),
                }),
                responses: {
                    200: z.array(TenantGroup),
                },
            },
        }),
        tenant: contract.router({
            getAll: {
                method: 'GET',
                path: '/tenants',
                query: z.object({
                    ...SearchParams.pick({ tenantIds: true }).shape,
                    exclude: z.literal('categoriesNotRequired').optional(),
                    contentLocale: ContentLocale.nullish(),
                }),
                responses: {
                    200: z.array(Tenant),
                },
            },
            get: {
                method: 'GET',
                path: '/tenants/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: Tenant,
                },
            },
        }),
        collection: contract.router({
            getAll: {
                method: 'GET',
                path: '/collections/brixwireKeyword/items',
                query: z.object({
                    ...SearchParams.pick({ q: true }).shape,
                    empty: z.boolean().optional(),
                }),
                responses: {
                    200: z.array(CollectionItem),
                },
            },
            get: {
                method: 'GET',
                path: '/collections/brixwireKeyword/items/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: CollectionItem,
                },
            },
            put: {
                method: 'PUT',
                path: '/collections/brixwireKeyword/items/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: CollectionItem,
                responses: {
                    200: CollectionItem,
                },
            },
        }),
        menu: contract.router({
            getAll: {
                method: 'GET',
                path: '/menus',
                query: z.object({
                    tenantId: z.coerce.number(),
                }),
                responses: {
                    200: z.array(Menu.transform(deserializeMenu)),
                },
            },
            get: {
                method: 'GET',
                path: '/menus/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: Menu.transform(deserializeMenu),
                },
            },
            put: {
                method: 'PUT',
                path: '/menus/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: Menu.transform(serializeMenu),
                responses: {
                    200: Menu.transform(deserializeMenu),
                },
            },
            post: {
                method: 'POST',
                path: '/menus',
                body: Menu.transform(serializeMenu),
                responses: {
                    201: Menu.transform(deserializeMenu),
                },
            },
        }),
        sitemaps: contract.router({
            getAll: {
                method: 'GET',
                path: '/tenants/:id/sitemaps',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                query: z.object({
                    ...SearchParams.pick({ q: true }).shape,
                }),
                responses: {
                    200: z.array(Sitemap.transform(deserializeSitemap)),
                },
            },
            head: {
                method: 'GET',
                metadata: {
                    method: 'HEAD',
                },
                path: '/tenants/:id/sitemaps',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                query: z.object({
                    urlSlug: z.string(),
                    parentId: z.number().optional(),
                    excludeId: z.number(),
                }),
                responses: {
                    200: z.never(),
                    404: z.never(),
                },
            },
            get: {
                method: 'GET',
                path: '/tenants/:tenantId/sitemaps/:sitemapId',
                pathParams: z.object({
                    tenantId: z.union([z.coerce.number(), z.string()]),
                    sitemapId: z.union([z.coerce.number(), z.string()]),
                }),
                responses: {
                    200: Sitemap.transform(deserializeSitemap),
                },
            },
            putAll: {
                method: 'PUT',
                path: '/tenants/:tenantId/sitemaps',
                pathParams: z.object({
                    tenantId: z.coerce.number(),
                }),
                body: z.array(z.any()),
                responses: {
                    200: z.array(Sitemap.transform(deserializeSitemap)),
                },
            },
            put: {
                method: 'PUT',
                path: '/tenants/:tenantId/sitemaps/:sitemapId',
                pathParams: z.object({
                    tenantId: z.union([z.coerce.number(), z.string()]),
                    sitemapId: z.union([z.coerce.number(), z.string()]),
                }),
                body: Sitemap.transform(serializeSitemap),
                responses: {
                    200: Sitemap.transform(deserializeSitemap),
                },
            },
            post: {
                method: 'POST',
                path: '/tenants/:id/sitemaps',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: Sitemap.transform(serializeSitemap),
                responses: {
                    201: Sitemap.transform(deserializeSitemap),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/tenants/:tenantId/sitemaps/:sitemapId',
                pathParams: z.object({
                    tenantId: z.union([z.coerce.number(), z.string()]),
                    sitemapId: z.union([z.coerce.number(), z.string()]),
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
        pageStyles: contract.router({
            getAll: {
                method: 'GET',
                path: '/pageStyles',
                responses: {
                    200: z.array(PageStyle),
                },
            },
        }),
        locales: contract.router({
            getAll: {
                method: 'GET',
                path: '/locales',
                responses: {
                    200: z.array(Locale),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type TenantRouter = typeof router;

const tenantClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.tenantUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useTenantClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return tenantClient(state);
};
