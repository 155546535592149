import { styled } from '@mui/material';
import { forwardRef, type ReactNode, type Ref } from 'react';
import { useFocused } from 'slate-react';

import { type Editor, ReactEditor } from '@@editor/helpers';
import {
    Element,
    type LeadElement,
    type TitleElement,
    type TitleHeaderElement,
} from '@@editor/helpers/Element';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';
import { getMaxWidth, PenIcon } from '@@editor/toolbars/ElementWrapper/ElementWrapper';

type WrapperProps = {
    $placeholderText: string;
    $readOnly: boolean;
    $templateElement: boolean;
};

const Wrapper = styled('div')<WrapperProps>(
    ({ $placeholderText, $readOnly, $templateElement, theme }) => ({
        position: 'relative',
        margin: '0 auto',
        width: '100%',
        maxWidth: getMaxWidth({ widthType: 'text', theme, includeSidebar: $readOnly }),

        [`${PenIcon}`]: {
            // Heading elements have an extra top padding that we need to account for
            // when displaying the "selection icon", and no wrapper on the sides
            top: `calc(${theme.spacing(1)} + ${theme.spacing(3)})`,
        },

        ...($templateElement &&
            getInputPlaceholderStyle({
                theme,
                placeholderText: $placeholderText,
                readOnly: $readOnly,
            })),
    }),
);

type Props = {
    children: ReactNode;
    editor: Editor;
    element: TitleElement | TitleHeaderElement | LeadElement;
    forwardedRef?: Ref<HTMLDivElement>;
    placeholderText: string;
};

const HeadingWrapper = (props: Props) => {
    const { children, editor, element, forwardedRef, placeholderText, ...rest } = props;
    const isSelected = ReactEditor.isElementSelected(editor, element);
    const readOnly = ReactEditor.isReadOnly(editor);
    const isFocused = useFocused();

    return (
        <Wrapper
            ref={forwardedRef}
            $placeholderText={placeholderText}
            $readOnly={readOnly}
            $templateElement={
                Element.isTemplateElement(element) || Element.isEmptyTextElement(element)
            }
            {...rest}
        >
            {editor.withPenIcon && isFocused && isSelected && <PenIcon />}
            {children}
        </Wrapper>
    );
};

const HeadingWrapperWithRef = forwardRef<HTMLDivElement, Props>((props, ref) => (
    <HeadingWrapper {...props} forwardedRef={ref} />
));

export default HeadingWrapperWithRef;
