import { type TFunction } from 'i18next';
import { useLayoutEffect, useRef } from 'react';
import { z } from 'zod';

import Spacer from '@@components/Spacer';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { useReactHookForm } from '@@containers/ReactHookForm/useReactHookForm';
import { type LinkData } from '@@editor/helpers/Editor';
import { Element, ELEMENT_TYPES, type LinkElement } from '@@editor/helpers/Element';
import ButtonGroup from '@@form/components/ButtonGroup';
import { CancelButton, SubmitButton } from '@@form/components/Form/Buttons';
import ContentItemListField from '@@form/fields/ContentItemListField';
import RadioGroupField from '@@form/fields/RadioGroup';
import TextInputField from '@@form/fields/TextInput';
import UrlField from '@@form/fields/Url';
import { requiredNumberNullable, requiredString } from '@@form/validations';

import AuthorLinkField from './SelectTargetModal/AuthorLinkField';
import CategoryLinkField from './SelectTargetModal/CategoryLinkField';
import SelectTargetModal from './SelectTargetModal/SelectTargetModal';
import TagLinkField from './SelectTargetModal/TagLinkField';

export type Props = {
    selectedText: string;
    t: TFunction;
    onCancel: VoidFunction;
    onSubmit: (data: LinkData) => void;
    linkElement: LinkElement | undefined;
};

const schema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal(ELEMENT_TYPES.EXTERNAL_LINK),
        text: requiredString,
        url: requiredString.url(),
        isInternalLink: z.boolean().transform(() => false),
    }),
    z.object({
        type: z.literal(ELEMENT_TYPES.INTERNAL_CONTENT_LINK),
        text: requiredString,
        // we need to allow null for initial values but still validate if missing
        metadataId: requiredNumberNullable,
        isInternalLink: z.boolean().transform(() => true),
    }),
    z.object({
        type: z.literal(ELEMENT_TYPES.CATEGORY_LINK),
        text: requiredString,
        categoryId: z.number(),
        isInternalLink: z.boolean().transform(() => true),
    }),
    z.object({
        type: z.literal(ELEMENT_TYPES.TAG_LINK),
        text: requiredString,
        tagId: z.number(),
        isInternalLink: z.boolean().transform(() => true),
    }),
    z.object({
        type: z.literal(ELEMENT_TYPES.AUTHOR_LINK),
        text: requiredString,
        authorId: z.string(),
        isInternalLink: z.boolean().transform(() => true),
    }),
]);

type FormValuesType = z.infer<typeof schema>;

const getInitialValues = (linkElement: LinkElement, text: string): FormValuesType => {
    if (Element.isInternalLinkElement(linkElement)) {
        return {
            text,
            type: linkElement.type,
            metadataId: linkElement.data.metadataId,
            isInternalLink: true,
        };
    }

    if (Element.isCategoryLinkElement(linkElement)) {
        return {
            text,
            type: linkElement.type,
            categoryId: linkElement.data.categoryId,
            isInternalLink: true,
        };
    }

    if (Element.isTagLinkElement(linkElement)) {
        return {
            text,
            type: linkElement.type,
            tagId: linkElement.data.tagId,
            isInternalLink: true,
        };
    }

    if (Element.isAuthorLinkElement(linkElement)) {
        return {
            text,
            type: linkElement.type,
            authorId: linkElement.data.authorId,
            isInternalLink: true,
        };
    }

    if (Element.isExternalLinkElement(linkElement)) {
        return {
            text,
            type: ELEMENT_TYPES.EXTERNAL_LINK,
            url: linkElement.data.href,
            isInternalLink: false,
        };
    }

    const exhaustiveCheck: never = linkElement;
    throw new Error(`Uncovered link type ${exhaustiveCheck}`);
};

const getValues = (values: FormValuesType): LinkData => {
    if (values.type === ELEMENT_TYPES.EXTERNAL_LINK) {
        return {
            type: values.type,
            url: values.url,
            text: values.text,
        };
    }

    if (values.type === ELEMENT_TYPES.INTERNAL_CONTENT_LINK) {
        return {
            type: values.type,
            metadataId: values.metadataId,
            text: values.text,
        };
    }

    if (values.type === ELEMENT_TYPES.CATEGORY_LINK) {
        return {
            type: values.type,
            categoryId: values.categoryId,
            text: values.text,
        };
    }

    if (values.type === ELEMENT_TYPES.TAG_LINK) {
        return {
            type: values.type,
            tagId: values.tagId,
            text: values.text,
        };
    }

    if (values.type === ELEMENT_TYPES.AUTHOR_LINK) {
        return {
            type: values.type,
            authorId: values.authorId,
            text: values.text,
        };
    }

    const exhaustiveCheck: never = values;
    throw new Error(`Uncovered link type ${exhaustiveCheck}`);
};

const LinkForm: React.FC<Props> = ({ selectedText, onSubmit, onCancel, t, linkElement }) => {
    const linkFieldRef = useRef<HTMLInputElement | null>(null);

    useLayoutEffect(() => {
        // Needs a timeout because the ref is not defined directly after the component mounted
        setTimeout(() => {
            if (linkFieldRef.current) {
                const input = linkFieldRef.current.querySelector('input');

                input?.select();
            }
        });
    }, [linkFieldRef]);

    const initialValues = linkElement
        ? getInitialValues(linkElement, selectedText)
        : {
              text: selectedText,
              type: ELEMENT_TYPES.INTERNAL_CONTENT_LINK,
              metadataId: null,
              isInternalLink: true,
          };

    const form = useReactHookForm({
        formName: 'LinkForm',
        schema,
        values: initialValues,
    });

    const { methods } = form;

    const formValues = methods.watch();

    const renderInternalLinkContentField = () => {
        const label = t('editor.insert.link.modal.reference.label');

        if (formValues.type === ELEMENT_TYPES.CATEGORY_LINK) {
            return <CategoryLinkField name="categoryId" label={label} required />;
        }

        if (formValues.type === ELEMENT_TYPES.TAG_LINK) {
            return <TagLinkField name="tagId" label={label} required />;
        }

        if (formValues.type === ELEMENT_TYPES.AUTHOR_LINK) {
            return <AuthorLinkField name="authorId" label={label} required />;
        }

        if (formValues.type === ELEMENT_TYPES.INTERNAL_CONTENT_LINK) {
            return <ContentItemListField name="metadataId" label={label} required />;
        }
    };

    return (
        <ReactHookForm
            form={form}
            onSubmit={(values: FormValuesType) => {
                onSubmit(getValues(values));
            }}
        >
            <RadioGroupField
                name="isInternalLink"
                row
                required
                fields={[
                    {
                        value: true,
                        label: t('editor.insert.link.modal.internallink'),
                    },
                    {
                        value: false,
                        label: t('editor.insert.link.modal.externallink'),
                    },
                ]}
                onChange={(e, onChange) => {
                    const isInternal = e.target.value === 'true';

                    onChange(isInternal);

                    if (isInternal) {
                        methods.setValue(
                            'type',
                            initialValues.isInternalLink
                                ? initialValues.type
                                : ELEMENT_TYPES.INTERNAL_CONTENT_LINK,
                        );
                    } else {
                        methods.setValue('type', ELEMENT_TYPES.EXTERNAL_LINK);
                    }
                }}
            />

            <Spacer v md />

            <TextInputField name="text" autoComplete="off" label="Link Text" required />

            <Spacer v md />

            {formValues.type === ELEMENT_TYPES.EXTERNAL_LINK ? (
                <UrlField
                    inputRef={linkFieldRef}
                    name="url"
                    placeholder={t('editor.insert.link.modal.link.placeholder')}
                    autoComplete="off"
                    label="Link URL"
                    required
                />
            ) : (
                <>
                    {renderInternalLinkContentField()}

                    <Spacer v md />

                    <SelectTargetModal
                        type={formValues.type}
                        metadataId={'metadataId' in formValues ? formValues.metadataId : null}
                        categoryId={'categoryId' in formValues ? formValues.categoryId : null}
                        tagId={'tagId' in formValues ? formValues.tagId : null}
                        authorId={'authorId' in formValues ? formValues.authorId : null}
                        onSubmit={(values) => {
                            methods.setValue('type', values.type);

                            if (values.type === ELEMENT_TYPES.INTERNAL_CONTENT_LINK) {
                                methods.setValue('metadataId', values.metadataId);
                            }

                            if (values.type === ELEMENT_TYPES.CATEGORY_LINK && values.categoryId) {
                                methods.setValue('categoryId', values.categoryId);
                            }

                            if (values.type === ELEMENT_TYPES.TAG_LINK && values.tagId) {
                                methods.setValue('tagId', values.tagId);
                            }

                            if (values.type === ELEMENT_TYPES.AUTHOR_LINK && values.authorId) {
                                methods.setValue('authorId', values.authorId);
                            }
                        }}
                    />
                </>
            )}

            <Spacer v md />

            <ButtonGroup>
                <CancelButton isAlwaysVisible isAlwaysActive onClick={onCancel} />
                <SubmitButton />
            </ButtonGroup>
        </ReactHookForm>
    );
};

export default LinkForm;
