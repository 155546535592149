import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { writeLastRoutePathToStorage } from './utils';

const useLastRouteTracker = () => {
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname + location.search;

        if (!location.pathname.includes('/login')) {
            writeLastRoutePathToStorage(currentPath);
        }
    }, [location]);
};

export default useLastRouteTracker;
