import { type UseQueryOptions } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type AuthRouter, useAuthClient } from '@@api/services/auth/client';
import { type RequestQuery } from '@@api/types';
import { defaultQueryPlaceholderData } from '@@api/utils/defaultPlaceholderData';

import { USER_SOURCES } from './constants';

export const TAB_NAME = {
    ALL: 'all',
    EXTERNAL: USER_SOURCES.EXTERNAL,
    INTERNAL: USER_SOURCES.TAMEDIA,
} as const;

type TabName = (typeof TAB_NAME)[keyof typeof TAB_NAME];

const useUserTabs = (params: RequestQuery<AuthRouter['users']['getAll']>) => {
    const { t } = useTranslation();

    const reactQueryOptions = {
        placeholderData: defaultQueryPlaceholderData(params.size ?? 10),
    } satisfies Partial<UseQueryOptions>;

    const { client: authClient, queryKeys: authKeys } = useAuthClient();

    const allUsers = authClient.users.getAll.useQuery({
        queryKey: authKeys.users.getAll({ query: params }),
        queryData: { query: params },
        ...reactQueryOptions,
    });

    const internalUsersParams = { ...params, userSource: USER_SOURCES.TAMEDIA };
    const internalUsers = authClient.users.getAll.useQuery({
        queryKey: authKeys.users.getAll({ query: internalUsersParams }),
        queryData: { query: internalUsersParams },
        ...reactQueryOptions,
    });

    const externalUsersParams = { ...params, userSource: USER_SOURCES.EXTERNAL };
    const externalUsers = authClient.users.getAll.useQuery({
        queryKey: authKeys.users.getAll({ query: externalUsersParams }),
        queryData: { query: externalUsersParams },
        ...reactQueryOptions,
    });

    const [selectedTab, setSelectedTab] = useState<TabName>(TAB_NAME.ALL);

    useEffect(() => {
        if (params.q) {
            setSelectedTab(TAB_NAME.ALL);
        }
    }, [params.q, setSelectedTab]);

    const usersMap = {
        [TAB_NAME.ALL]: allUsers.data?.body,
        [TAB_NAME.INTERNAL]: internalUsers.data?.body,
        [TAB_NAME.EXTERNAL]: externalUsers.data?.body,
    };

    const users = {
        ...usersMap[selectedTab],
        firstPageNumber: 1,
    };

    const tabs = [
        {
            label: t('user.list.tab.all'),
            value: TAB_NAME.ALL,
            badgeContent:
                allUsers.isPlaceholderData || !allUsers.data
                    ? '?'
                    : String(allUsers.data.body.totalElements),
        },
        {
            label: t('user.list.tab.internal'),
            value: TAB_NAME.INTERNAL,
            badgeContent:
                internalUsers.isPlaceholderData || !internalUsers.data
                    ? '?'
                    : String(internalUsers.data.body.totalElements),
        },
        {
            label: t('user.list.tab.external'),
            value: TAB_NAME.EXTERNAL,
            badgeContent:
                externalUsers.isPlaceholderData || !externalUsers.data
                    ? '?'
                    : String(externalUsers.data.body.totalElements),
        },
    ];

    return {
        selectedTab,
        setSelectedTab,
        tabs,
        users,
    } as const;
};

export default useUserTabs;
