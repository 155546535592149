import { omit } from 'lodash-es';
import { type Location, type NodeEntry, type Path, Transforms } from 'slate';

import { type MetadataRouter } from '@@api/services/metadata/client';
import { getQueryParams } from '@@api/utils/getQueryParams';
import { MARKS } from '@@editor/constants';
import { Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { type InsertElementOptions, type LinkData } from '@@editor/helpers/Editor';
import { ANY_LINK, ELEMENT_TYPES } from '@@editor/helpers/Element';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { type PluginOptions } from '@@editor/typings/UnityPlugins';
import getIsUrl from '@@form/utils/validators/customUrl';
import getUrlsFromDataTransfer from '@@utils/dataTransfer/getUrlsFromDataTransfer';
import extractMetadataIdFromUrl from '@@utils/extractMetadataIdFromUrl';

import Modal from './Modal';
import RenderedLink from './RenderedLink';
import { type LinkPluginOptions } from './types';

const isLinkActive = (editor: Editor): boolean => {
    const inlines = Array.from(
        Editor.inlines(editor, {
            types: ANY_LINK,
        }),
    );

    return inlines.length > 0;
};

const isUnderlined = (editor: Editor): boolean => {
    const [match] = Editor.nodes(editor, {
        match: (node) => node[MARKS.UNDERLINED],
    });

    return Boolean(match);
};

const unwrapLink = (editor: Editor): void => {
    Transforms.unwrapNodes(editor, {
        match: (node: Node) => Element.isLinkElement(node),
    });
};

const wrapLink = (editor: Editor, link: LinkData): void => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    if (isUnderlined(editor)) {
        Editor.removeMark(editor, MARKS.UNDERLINED);
    }

    if (typeof link.text === 'string') {
        Transforms.insertText(editor, link.text);

        Transforms.move(editor, {
            distance: link.text.length,
            unit: 'character',
            reverse: true,
            edge: 'anchor',
        });
    }

    const createLinkElement = () => {
        if (link.type === ELEMENT_TYPES.INTERNAL_CONTENT_LINK) {
            return {
                type: ELEMENT_TYPES.INTERNAL_CONTENT_LINK,
                data: { metadataId: link.metadataId },
                children: [{ text: '' }],
            };
        }

        if (link.type === ELEMENT_TYPES.TAG_LINK) {
            return {
                type: ELEMENT_TYPES.TAG_LINK,
                data: { tagId: link.tagId },
                children: [{ text: '' }],
            };
        }

        if (link.type === ELEMENT_TYPES.CATEGORY_LINK) {
            return {
                type: ELEMENT_TYPES.CATEGORY_LINK,
                data: { categoryId: link.categoryId },
                children: [{ text: '' }],
            };
        }

        if (link.type === ELEMENT_TYPES.AUTHOR_LINK) {
            return {
                type: ELEMENT_TYPES.AUTHOR_LINK,
                data: { authorId: link.authorId },
                children: [{ text: '' }],
            };
        }

        if (link.type === ELEMENT_TYPES.EXTERNAL_LINK) {
            return {
                type: ELEMENT_TYPES.EXTERNAL_LINK,
                data: { href: link.url },
                children: [{ text: '' }],
            };
        }
    };

    Transforms.wrapNodes(editor, createLinkElement(), { split: true });
};

type RemoveLinkOptions = {
    at?: Location;
};

const removeLink = (editor: Editor, options: RemoveLinkOptions = {}): void => {
    const selection = options.at || editor.selection;

    if (selection) {
        Transforms.select(editor, selection);

        unwrapLink(editor);
    }
};

type InsertLinkOptions = RemoveLinkOptions;

const insertLink = (editor: Editor, data: LinkData, options: InsertLinkOptions = {}): void => {
    const selection = options.at || editor.selection;

    if (selection) {
        Transforms.select(editor, selection);
        wrapLink(editor, data);
    }
};

export const withLink = (editor, options: PluginOptions & LinkPluginOptions): Editor => {
    const { insertData, normalizeNode } = editor;

    const { fetchMetadata, shouldNormalizeMarks = true } = options;

    return Object.assign(editor, {
        renderEditor: renderEditor(editor, Modal, options),
        renderElement: renderElement(
            editor,
            [
                [ELEMENT_TYPES.INTERNAL_CONTENT_LINK, RenderedLink],
                [ELEMENT_TYPES.EXTERNAL_LINK, RenderedLink],
                [ELEMENT_TYPES.TAG_LINK, RenderedLink],
                [ELEMENT_TYPES.CATEGORY_LINK, RenderedLink],
                [ELEMENT_TYPES.AUTHOR_LINK, RenderedLink],
            ],
            options,
        ),
        insertData: async (data: DataTransfer, options: InsertElementOptions) => {
            const text = data.getData('text/plain');

            const url = getUrlsFromDataTransfer(data)?.[0] ?? text;

            if (url && !getIsUrl({ allowInsecure: true, allowMailto: true })(url)) {
                const { selection } = editor;
                const selectedText = Editor.string(editor, selection);

                const metadataId = extractMetadataIdFromUrl(url);

                const text = selectedText || url;

                if (metadataId) {
                    const params = getQueryParams<MetadataRouter['metadata']['getList']>({
                        ids: metadataId,
                        size: 1,
                    });

                    await fetchMetadata({ params }).then(({ body }) => {
                        const [metadata] = body.content || [];

                        if (metadata) {
                            const data: LinkData = {
                                metadataId: metadata.id,
                                text,
                                type: ELEMENT_TYPES.INTERNAL_CONTENT_LINK,
                            };

                            editor.insertLink(data, { at: selection });
                        } else {
                            const data: LinkData = {
                                url,
                                text,
                                type: ELEMENT_TYPES.EXTERNAL_LINK,
                            };

                            editor.insertLink(data, { at: selection });
                        }
                    });
                } else {
                    const data: LinkData = {
                        url,
                        text,
                        type: ELEMENT_TYPES.EXTERNAL_LINK,
                    };

                    editor.insertLink(data, { at: selection });
                }
            } else {
                // If no url was found, we have to pass the data further down the plugin chain. Maybe another
                // plugin knows what to do with this data
                insertData(data, options);
            }
        },
        insertLink: (data: LinkData, options: InsertLinkOptions) =>
            insertLink(editor, data, options),
        removeLink: (options: RemoveLinkOptions) => removeLink(editor, options),
        showLinkModal: () => {
            editor.setData({
                isLinkModalVisible: {
                    selection: editor.selection,
                },
            });
        },
        hideLinkModal: (restoreSelection?: boolean) => {
            const { selection } = editor.getDataIn(['isLinkModalVisible']);

            editor.setData({
                isLinkModalVisible: false,
            });

            requestAnimationFrame(() => {
                if (restoreSelection) {
                    Transforms.select(editor, selection);
                }

                ReactEditor.focus(editor);
            });
        },
        normalizeNode: (nodeEntry: NodeEntry) => {
            const [node, nodePath] = nodeEntry;

            if (Element.isLinkElement(node)) {
                const nodeString = Node.string(node);

                const leadingSpaces = nodeString.length - nodeString.trimStart().length;
                const trailingSpaces = nodeString.length - nodeString.trimEnd().length;

                if (nodeString.trim() === '') {
                    Transforms.unwrapNodes(editor, {
                        at: nodePath,
                        match: Element.isLinkElement,
                    });

                    return;
                }

                if (trailingSpaces) {
                    const focus = Editor.end(editor, nodePath);

                    const anchor = Editor.before(editor, focus, {
                        unit: 'character',
                        distance: trailingSpaces,
                    });

                    if (anchor) {
                        Transforms.splitNodes(editor, {
                            at: anchor,
                            match: Element.isLinkElement,
                        });
                    }

                    return;
                }

                if (leadingSpaces) {
                    const focus = Editor.start(editor, nodePath);

                    const anchor = Editor.after(editor, focus, {
                        unit: 'character',
                        distance: leadingSpaces,
                    });

                    if (anchor) {
                        Transforms.splitNodes(editor, {
                            at: anchor,
                            match: Element.isLinkElement,
                        });
                    }

                    return;
                }
            }

            if (shouldNormalizeMarks) {
                if (Element.isLinkElement(node)) {
                    // Since native slate functions for checking of node throw an error if there is none
                    // And mergeNodes sometimes try to perform on nonexisting node, this is used to prevent error
                    const nodeExists = (editor: Editor, path: Path) => {
                        try {
                            Editor.node(editor, path);

                            return true;
                        } catch (error) {
                            return false;
                        }
                    };

                    // Link element should only have one child, however mergeNodes by slate sometimes removes attributes prop
                    // So we mergeNodes while also keeping track of attributes, and apply them at the end
                    let attributes = {};

                    for (const [child, path] of Node.children(editor, nodePath)) {
                        attributes = { ...attributes, ...omit(child, ['text']) };

                        if (nodeExists(editor, path)) {
                            Transforms.mergeNodes(editor, { at: path });
                        }
                    }
                    Transforms.setNodes(editor, attributes, { at: [...nodePath, 0] });

                    return;
                }
            }

            normalizeNode(nodeEntry);
        },
    });
};

export default withLink;
