import { Transforms } from 'slate';

import { type Editor } from '@@editor/helpers';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import EmbeddedComponentForm from './components/EmbeddedComponentForm';
import EditorWithEmbedModal from '../embed/components/EditorWithEmbedModal';
import { generateEmbedBlock as generateEmbedSnippet } from '../embed/embeddedSnippet/plugin';
import { generateLayoutBlocks as generateSnippetContent } from '../snippet/plugin';

const withEmbeddedComponent = (editor: Editor, options) =>
    Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formProps={{ editor, formName: 'EmbeddedComponentForm' }}
                    formComponent={EmbeddedComponentForm}
                    generateEmbedBlock={() => {}}
                    type={PLUGIN_NAMES.EMBEDDED_COMPONENT}
                />
            ),
            options,
        ),
        insertEmbedComponent: (formData, type, options) => {
            generateEmbedSnippet(editor, formData.embed, options);
        },
        insertLayoutComponent: (formData, type, options) => {
            if (options.replace) {
                Transforms.removeNodes(editor, { at: options.path });
                generateSnippetContent(editor, formData, { at: options.at });
                Transforms.select(editor, options.at);
                Transforms.collapse(editor);
            } else {
                generateSnippetContent(editor, formData, { at: options.at });
            }
        },
    });

export default withEmbeddedComponent;
