import { Skeleton } from '@mui/material';
import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react';

type ChildrenProps = { isLoading: boolean; onLoad: VoidFunction; onError: VoidFunction };

type Props = {
    children: (props: ChildrenProps) => ReactNode;
    disabled?: boolean;
};

type UseEmbedComponentLoaderProps = {
    disabled?: boolean;
};

const useEmbedComponentLoader = ({ disabled }: UseEmbedComponentLoaderProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const isMounted = useRef<boolean | null>(null);
    const isLoadedBeforeMount = useRef(false);
    const isErrorBeforeMount = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        // It is possible, that `onLoad` is fired before the component is mounted. In this case we have to call
        // the load handler manually on mount.
        if (isLoadedBeforeMount.current) {
            handleOnLoad();
        }

        // It is possible, that `onError` is fired before the component is mounted. In this case we have to call
        // the error handler manually on mount.
        if (isErrorBeforeMount.current) {
            handleOnError();
        }

        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleOnLoad = useCallback(() => {
        if (isMounted.current !== false) {
            setIsLoading(false);
        }

        if (!isMounted.current) {
            isLoadedBeforeMount.current = true;
        }
    }, []);

    const handleOnError = useCallback(() => {
        if (isMounted.current !== false) {
            setIsLoading(false);
        }

        if (isMounted.current) {
            isErrorBeforeMount.current = true;
        }
    }, []);

    return {
        isLoading: disabled ? false : isLoading,
        onLoad: handleOnLoad,
        onError: handleOnError,
    };
};

const EmbedComponentLoader = ({ children, disabled }: Props) => {
    const {
        isLoading,
        onLoad: handleOnLoad,
        onError: handleOnError,
    } = useEmbedComponentLoader({ disabled });

    return (
        <Skeleton
            role={isLoading ? 'progressbar' : undefined}
            variant="rectangular"
            animation={isLoading ? 'wave' : false}
            sx={{
                '& > *': {
                    visibility: isLoading ? 'hidden' : 'visible',
                },
            }}
            width="100%"
            height="100%"
        >
            {children({ isLoading, onLoad: handleOnLoad, onError: handleOnError })}
        </Skeleton>
    );
};

export default EmbedComponentLoader;
