import { Fade, Popper, styled } from '@mui/material';
import { type HTMLAttributes, type ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadOnly } from 'slate-react';

import { useMetadataClient } from '@@api/services/metadata/client';
import { type Metadata } from '@@api/services/metadata/schemas';
import config from '@@config';
import { type Editor, ReactEditor } from '@@editor/helpers';
import { Element, type LinkElement } from '@@editor/helpers/Element';
import { getPluginData } from '@@editor/plugins/spellChecker/utils/data';

import LinkDetails from './LinkDetails';

const buildInternalLink = ({ resourceName, resourceId }: Metadata): string => {
    switch (resourceName) {
        case 'links':
        case 'videos':
        case 'embeds':
            return `/externalContent/${resourceName}/${resourceId}`;
        default:
            return `/${resourceName}/${resourceId}`;
    }
};

// MUI Link component is not used here because if selected comment creation is not working

const Link = styled('a')(({ theme }) => ({
    color: theme.palette.secondary.dark,
    '&:hover': {
        color: theme.palette.secondary.main,
    },
    textDecoration: 'underline',
    textUnderlineOffset: theme.spacing(1),
}));

type Props = {
    element: LinkElement;
    attributes: HTMLAttributes<HTMLAnchorElement>;
    children?: ReactNode;
    editor: Editor;
};

const POPPER_ID = 'link-popper';

const RenderedLink = (props: Props) => {
    const { t } = useTranslation();
    const { attributes, children, element, editor } = props;
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();
    const anchorEl = useRef<HTMLAnchorElement>(null);

    const [popperOpen, setPopperOpen] = useState(false);

    const isElementSelected = ReactEditor.isElementSelected(editor, element);

    useEffect(() => {
        // Update popper state only when anchorEl is set and the element is selected
        if (anchorEl.current && isElementSelected) {
            setPopperOpen(true);
        } else {
            setPopperOpen(false);
        }
    }, [isElementSelected]);

    const href = Element.isExternalLinkElement(element) ? element.data.href : '';
    const metadataId = Element.isInternalLinkElement(element) ? element.data.metadataId : null;
    const categoryId = Element.isCategoryLinkElement(element) ? element.data.categoryId : null;
    const tagId = Element.isTagLinkElement(element) ? element.data.tagId : null;
    const authorId = Element.isAuthorLinkElement(element) ? element.data.authorId : null;

    const params = { id: metadataId! };

    const { data: metadata } = metadataClient.metadata.get.useQuery({
        queryKey: metadataKeys.metadata.get({ params, query: {} }),
        queryData: { params },
        enabled: Boolean(metadataId),
    });

    const data = metadata?.body;

    const isSpellCheckerEnabled = getPluginData(editor, 'isEnabled');
    const isReadOnly = useReadOnly();

    const getLink = (): { href: string; title: string } => {
        if (Element.isInternalLinkElement(element)) {
            return {
                href: data ? buildInternalLink(data) : '',
                title: t('link.internalLink.title'),
            };
        }

        if (Element.isCategoryLinkElement(element)) {
            return {
                href: `${config.navigation.categories.uri}/${categoryId}`,
                title: t('link.categoryLink.title'),
            };
        }

        if (Element.isTagLinkElement(element)) {
            return {
                href: `${config.navigation.tags.uri}/${tagId}`,
                title: t('link.tagLink.title'),
            };
        }

        if (Element.isAuthorLinkElement(element)) {
            return {
                href: `${config.navigation.users.uri}/${authorId}`,
                title: t('link.authorLink.title'),
            };
        }

        if (Element.isExternalLinkElement(element)) {
            return {
                href,
                title: t('link.externalLink.title'),
            };
        }

        const exhaustiveCheck: never = element;
        throw new Error(`Uncovered link type ${exhaustiveCheck}`);
    };

    const link = getLink();

    const renderLink = (props = {}) => (
        <Link
            href={link.href}
            target="_blank"
            title={`${link.title}: ${link.href}`}
            {...attributes}
            {...props}
        >
            {children}
        </Link>
    );

    return isReadOnly || isSpellCheckerEnabled ? (
        renderLink()
    ) : (
        <span>
            {renderLink({ 'aria-describedby': POPPER_ID, ref: anchorEl })}

            <Popper
                transition
                id={POPPER_ID}
                open={popperOpen}
                anchorEl={anchorEl.current}
                placement="bottom-start"
                sx={(theme) => ({
                    zIndex: theme.zIndex.layer1,
                })}
                disablePortal
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                        <span>
                            <LinkDetails element={element} editor={editor} />
                        </span>
                    </Fade>
                )}
            </Popper>
        </span>
    );
};

export default RenderedLink;
