import ReactGA from 'react-ga4';
import { type UaEventOptions } from 'react-ga4/types/ga4';
import { type Location } from 'react-router';

import config from '@@config';

export const initializeGoogleAnalytics = () => {
    if (config.googleAnalyticsTrackingId && !config.isVisualTest) {
        ReactGA.initialize([{ trackingId: config.googleAnalyticsTrackingId }]);
    }
};

export const logPageView = (location: Location) => {
    if (config.googleAnalyticsTrackingId && !config.isVisualTest) {
        const page = location.pathname || window.location.pathname;
        const pageWithoutTrailingSlashes = page.endsWith('/') ? page.slice(0, -1) : page;

        ReactGA.send({ hitType: 'pageview', page: pageWithoutTrailingSlashes });
    }
};

export const logCustomEvent = (optionsOrName: string | UaEventOptions, params: any) => {
    if (config.googleAnalyticsTrackingId && !config.isVisualTest) {
        ReactGA.event(optionsOrName, params);
    }
};
