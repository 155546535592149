import { CircularProgress, type CircularProgressProps, styled } from '@mui/material';
import { Children, type ReactNode } from 'react';
import { createPortal } from 'react-dom';

export type LoaderProps = {
    isLoading?: boolean;
    keepChildrenInDom?: boolean;
    usePortal?: boolean;
    children?: ReactNode | ReactNode[];
    className?: string;
    size?: CircularProgressProps['size'];
};

const ParentWrapper = styled('div')<{ $isLoading: boolean }>(({ $isLoading }) => ({
    width: '100%',
    '& > *:not(:first-child)': {
        // The promise of "createTweet" will never be fulfilled if we use "display: none"
        // instead of "visibility: hidden". Unfortunately visibility will introduce some flickering
        // for loading youtube embeds.
        ...($isLoading && { visibility: 'hidden' }),
    },
}));

const Wrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
});

const Loader = ({
    className,
    keepChildrenInDom = false,
    usePortal,
    isLoading = true,
    children,
    size,
}: LoaderProps) => {
    const renderLoader = () => (
        <Wrapper className={className}>
            <CircularProgress size={size} />
        </Wrapper>
    );

    if (keepChildrenInDom) {
        if (usePortal) {
            return isLoading ? (
                <>
                    {renderLoader()}

                    {createPortal(
                        <div
                            style={{
                                position: 'absolute',
                                top: -99999,
                                left: -99999,

                                /* The promise of "createTweet" will never be fulfilled if we use "display: none"
                                 * instead of "visibility: hidden". Unfortunately visibility will introduce some flickering
                                 * for loading youtube embeds.
                                 */
                                visibility: 'hidden',
                            }}
                        >
                            {children}
                        </div>,
                        document.body,
                    )}
                </>
            ) : (
                children
            );
        }

        return (
            <ParentWrapper $isLoading={isLoading} className={className}>
                {isLoading && renderLoader()}
                {children}
            </ParentWrapper>
        );
    } else if (isLoading) {
        return renderLoader();
    }

    if (Children.count(children) > 0) {
        return <>{children}</>;
    }

    return null;
};

export default Loader;
