import { Stack, styled } from '@mui/material';
import React from 'react';

import { useMetadataClient } from '@@api/services/metadata/client';
import { type Metadata } from '@@api/services/metadata/schemas';
import Loader from '@@components/Loader';
import ContentDetails from '@@containers/ContentItem/ContentDetails';
import { getDefaultTeaserVariant } from '@@containers/Teaser/utils';

const LoaderWrapper = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const StyledContentDetails = styled(ContentDetails)({
    padding: 0,
});

type Props = {
    metadataId: Metadata['id'];
};

const ContentPreview: React.FC<Props> = ({ metadataId }) => {
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();
    const params = { id: metadataId };

    const { data: metadataData, isLoading: isMetadataLoading } =
        metadataClient.metadata.get.useQuery({
            queryKey: metadataKeys.metadata.get({ params, query: {} }),
            queryData: { params },
            enabled: Boolean(metadataId),
        });

    const metadata = metadataData?.body;

    if (isMetadataLoading) {
        return (
            <LoaderWrapper justifyContent="center" alignItems="center">
                <Loader />
            </LoaderWrapper>
        );
    }

    if (metadata) {
        const entity = {
            ...metadata,
            ...getDefaultTeaserVariant(metadata.teasers),
        };

        return <StyledContentDetails entity={entity} />;
    }
};

export default ContentPreview;
