import { styled } from '@mui/material';
import { get } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';

import PreviewImage from '@@containers/PreviewImage';
import { type Editor } from '@@editor/helpers';
import { type EmbedElement, type ImageElement } from '@@editor/helpers/Element';
import { MAX_HEIGHT, MAX_WIDTH } from '@@editor/plugins/serializable/constants';
import { isFile } from '@@scripts/utils';

import OverlayIcon from './OverlayIcon';

const usePreviewSrc = (data: EmbedElement['data']) => {
    // We cannot work with react states here, because it would be too slow. We need the previewSrc on first render,
    // in order to avoid flickering.
    const previewSrc = useRef<string>();
    const previewSrcId = useRef<string>();
    const prevOriginalSrc = useRef<any>();
    const objectUrls = useMemo<string[]>(() => [], []);

    if (previewSrcId.current !== data.loadingStatusId) {
        previewSrc.current = undefined;
        previewSrcId.current = data.loadingStatusId;
    }

    if (prevOriginalSrc.current !== data.originalSrc) {
        prevOriginalSrc.current = data.originalSrc;

        if (isFile(data.originalSrc)) {
            previewSrc.current = URL.createObjectURL(data.originalSrc);
            previewSrcId.current = data.loadingStatusId;

            objectUrls.push(previewSrc.current);
        }
    }

    useEffect(
        () => () => {
            objectUrls.forEach((url) => {
                // This step is super important to avoid memory leaks
                URL.revokeObjectURL(url);
            });
        },
        [objectUrls],
    );

    return previewSrc.current;
};

const getImageSrc = (data) =>
    // have the original url, which could be an external url, as fallback
    get(data, 'embed.url') || get(data, 'src');

export const getImageTransformationArguments = (element: ImageElement) => {
    const src = getImageSrc(element.data);

    const transformations = {
        ...element.data.embed,
        mimetype: element.data.mimetype,
        maxWidth: MAX_WIDTH,
        maxHeight: MAX_HEIGHT,
    };

    return [src, transformations];
};

const Wrapper = styled('div')({
    position: 'relative',
    width: '100%',
});

const LimitedHeightPreviewImage = styled(PreviewImage)({
    maxHeight: `${MAX_HEIGHT}px`,
    borderRadius: 0,
    width: '100%',
});

type Props = {
    editor: Editor;
    element: ImageElement;
};

export default ({ element }: Props) => {
    const { data } = element;
    const [src, transformations] = getImageTransformationArguments(element);
    const previewSrc = usePreviewSrc(data);
    const overlayIcon = data.embed?.overlayIcon;

    return (
        <Wrapper>
            {overlayIcon && <OverlayIcon {...overlayIcon} />}

            <LimitedHeightPreviewImage
                transformations={transformations}
                src={src}
                previewSrc={previewSrc}
                alt={data.embed?.name ?? undefined}
                skeleton
            />
        </Wrapper>
    );
};
