import { styled } from '@mui/material';
import { get } from 'lodash-es';
import { type ReactNode } from 'react';

import { type EmbedElement } from '@@editor/helpers/Element';
import isEmptyState from '@@editor/utils/isEmptyState';
import { StyledCaptionEditor } from '@@form/components/RichTextEditor/CaptionEditor';

const CaptionTitle = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.fixed.editor.embed.caption.font,
    fontWeight: 'bold',
}));

const Credit = styled('span')(({ theme }) => ({
    ...theme.fixed.editor.embed.credit.font,
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
}));

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.fixed.editor.embed.backgroundColor,
    padding: theme.spacing(2),
}));

type Props = {
    children: ReactNode | null;
    element: EmbedElement;
    withCaption?: boolean;
};

export const ElementFooter = (props: Props) => {
    const { children, element, withCaption } = props;

    const embed = get(element, 'data.embed', {});
    const { caption, captionTitle, credit } = embed;

    const hasCaptionTitle = withCaption !== false && Boolean(captionTitle);
    const hasCaption = withCaption !== false && Boolean(caption) && !isEmptyState(caption);

    if (!hasCaptionTitle && !hasCaption && !credit) {
        return <>{children}</>;
    }

    return (
        <Wrapper>
            {hasCaptionTitle && <CaptionTitle>{captionTitle}</CaptionTitle>}

            {hasCaption && (
                <StyledCaptionEditor
                    value={caption}
                    readOnly
                    hideStickyToolbar
                    hideFloatingToolbar
                />
            )}

            {credit && <Credit>{credit}</Credit>}

            {children}
        </Wrapper>
    );
};

export default ElementFooter;
