import { merge } from 'lodash-es';
import { type Path, type PathRef, Transforms } from 'slate';

import { type Editor, Node } from '@@editor/helpers';
import { type EmbedBaseData } from '@@editor/helpers/Element';

// If the user removes the node from the document while the file is being uploaded, we can not
// make any changes to the document anymore. This means the upload should be cancelled.
export const hasAccessToNode = (
    editor: Editor,
    pathRef: PathRef,
    data: EmbedBaseData,
): pathRef is Override<PathRef, { current: Path }> =>
    pathRef.current != null &&
    Node.get(editor, pathRef.current).data.loadingStatusId === data.loadingStatusId;

export const updateFileNodeData = (editor: Editor, pathRef: PathRef, data: EmbedBaseData) => {
    // We have to check if this path still exists, since the user might have
    // deleted the node in the meantime.
    if (hasAccessToNode(editor, pathRef, data)) {
        const node = Node.get(editor, pathRef.current);

        if (node) {
            const nextNode = {
                ...node,
                data: merge({}, node.data, data),
            };

            Transforms.setNodes(editor, nextNode, { at: pathRef.current });
        }
    }
};
