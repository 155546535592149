import { ThemeProvider } from '@mui/material';

import { Element } from '@@editor/helpers';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import { PluginsWrapper } from '../../components/PluginsWrapper';
import EmbedWrapper from '../../embed/components/EmbedWrapper';
import { editorPlaceholderTheme } from '../../styles';

export const TYPE = PLUGIN_NAMES.INTERVIEW;
export const NODE_TYPE = ELEMENT_TYPES.INTERVIEW_SEGMENT;
export const INLINE_EDITABLE_CHILDREN_TYPES = [
    ELEMENT_TYPES.INTERVIEW_SEGMENT_QUESTION,
    ELEMENT_TYPES.INTERVIEW_SEGMENT_ANSWER,
];

const EmptyComponent = ({ children }) => <PluginsWrapper>{children}</PluginsWrapper>;

const InterviewSegmentNode = (props) => {
    const { element } = props;

    const generateTheme = (theme) =>
        Element.isTemplateElement(element) ? editorPlaceholderTheme(theme) : theme;

    return (
        <ThemeProvider theme={generateTheme}>
            <EmbedWrapper
                {...props}
                widthType="small"
                type={TYPE}
                contentEditable
                component={EmptyComponent}
                placeholderComponent={EmptyComponent}
                toolbarConfig={{
                    infos: {
                        iconName: PLUGIN_ICON_NAMES[TYPE],
                        title: 'Interview',
                    },
                    actions: ['delete'],
                }}
            />
        </ThemeProvider>
    );
};

export default InterviewSegmentNode;
