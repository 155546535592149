import { type RouteObject } from 'react-router';

export const routes: RouteObject[] = [
    {
        path: 'slideshow/:id',
        lazy: async () => {
            const { SlideshowEmbed } = await import('@@routes/embed/SlideshowEmbed');

            return { Component: SlideshowEmbed };
        },
    },
];
