import { alpha, styled, type Theme } from '@mui/material';
import { type CSSProperties, forwardRef, type PropsWithChildren } from 'react';

import Icon from '@@components/Icon';

export const HorizontalLineType = {
    ADD_FILE: 'ADD_FILE',
} as const;

export type HorizontalLineType = ValueOf<typeof HorizontalLineType>;

type GetBackgroundProps = {
    $withIcon?: boolean;
    theme: Theme;
};

const getBackground = ({ $withIcon, theme }: GetBackgroundProps) => {
    const color = alpha(theme.palette.secondary.main, theme.opacityFactors.low);
    const padding = theme.spacing(3);

    if ($withIcon) {
        // The `-2px` is needed to make the icon look nicely centered between the lila lines
        return `linear-gradient(
            90deg,
            ${color} 0 calc(50% - ${padding} - 2px),
            transparent calc(50% - ${padding} - 2px) calc(50% + ${padding}),
            ${color} calc(50% + ${padding}) 100%
        )`;
    }

    return color;
};

const StyledHorizontalLine = styled('div')<{ $withIcon?: boolean }>(({ $withIcon, theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.fixed.editor.floatingToolbar.separatorHeight,
    background: getBackground({ $withIcon, theme }),
    borderRadius: theme.fixed.editor.elementWrapper.borderRadius,
    width: '100%',
    maxWidth: theme.fixed.editor.textElement.width,
    pointerEvents: 'none',

    '& > *': {
        pointerEvents: 'auto',
    },
}));

const HorizontalLineIcon = styled(Icon)(({ theme }) => ({
    color: theme.fixed.editor.elementWrapper.backgroundColor,
}));

type Props = {
    className?: string;
    contentEditable?: boolean;
    style?: CSSProperties;
    type?: HorizontalLineType;
};

const typeToIconName = {
    [HorizontalLineType.ADD_FILE]: 'file-circle-plus-regular',
};

const HorizontalLine = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
    ({ children, type, ...rest }, ref) => {
        const iconName = type && typeToIconName[type];

        return (
            <StyledHorizontalLine {...rest} ref={ref} $withIcon={Boolean(iconName)}>
                {iconName ? <HorizontalLineIcon name={iconName} size="large" /> : children}
            </StyledHorizontalLine>
        );
    },
);

export default HorizontalLine;
