import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import { PLUGIN_NAMES, type PluginList, type PluginName } from '@@editor/typings/UnityPlugins';

export const DEFAULT_FLOATINGTOOLBAR_BUTTONS: PluginName[] = [
    PLUGIN_NAMES.PARAGRAPH,
    PLUGIN_NAMES.EMBEDDED_COMPONENT,
    PLUGIN_NAMES.VIDEOCMS,
    PLUGIN_NAMES.EMBEDDED_CONTENT,
    PLUGIN_NAMES.IMAGE,
    PLUGIN_NAMES.SLIDESHOW,
    PLUGIN_NAMES.INFOBOX,
    PLUGIN_NAMES.INTERVIEW,
    PLUGIN_NAMES.IMPORT_INTERVIEW,
    PLUGIN_NAMES.DYNAMIC_TEASER,
    PLUGIN_NAMES.POLL,
    PLUGIN_NAMES.FACEBOOK,
    PLUGIN_NAMES.TWITTER,
    PLUGIN_NAMES.TIKTOK,
    PLUGIN_NAMES.THREADS,
    PLUGIN_NAMES.INSTAGRAM,
    PLUGIN_NAMES.YOUTUBE,
    PLUGIN_NAMES.QUOTE,
    PLUGIN_NAMES.ZATTOO,
    PLUGIN_NAMES.SEPARATOR,
    PLUGIN_NAMES.FRONTEND_COMPONENT,
    PLUGIN_NAMES.SUMMARY,
    PLUGIN_NAMES.TICKER_SUMMARY,
];

export const DEFAULT_STICKYTOOLBAR_BUTTONS: PluginList[] = [
    PLUGIN_NAMES.PARAGRAPH,
    PLUGIN_NAMES.BOLD,
    PLUGIN_NAMES.ITALIC,
    PLUGIN_NAMES.UNDERLINED,
    PLUGIN_NAMES.SUBSCRIPT,
    PLUGIN_NAMES.SUPERSCRIPT,
    PLUGIN_CONFIG_TEMPLATES.specialCharacters,
    PLUGIN_NAMES.LINK,
    PLUGIN_CONFIG_TEMPLATES.softHyphen,
    PLUGIN_NAMES.LIST,
    PLUGIN_NAMES.COMMENT,
];
