import { Stack, styled } from '@mui/material';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { type Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type EmbedElement } from '@@editor/helpers/Element';

import { getInputPlaceholderStyle } from '../../../styles';

type StyledQuoteCaptionProps = {
    $templateElement: boolean;
    $placeholderText: string;
    $readOnly: boolean;
};

export const StyledQuoteCaption = styled('div')<StyledQuoteCaptionProps>(
    ({ $templateElement, $placeholderText, $readOnly, theme }) => ({
        ...theme.fixed.editor.quote.caption.font,
        paddingRight: `calc(2 * ${theme.spacing(4)})`,
        textAlign: $templateElement ? 'right' : 'left',
        ...($templateElement && {
            ...getInputPlaceholderStyle({
                theme,
                placeholderText: $placeholderText,
                readOnly: $readOnly,
            }),
            '&&::before': {
                right: `calc(2 * ${theme.spacing(4)})`,
            },
        }),

        flexGrow: $templateElement ? 1 : 0,
    }),
);

export const Dash = styled('span')(({ theme }) => ({
    ...theme.fixed.editor.quote.dash.font,
    userSelect: 'none',
}));

type Props = {
    attributes: ElementAttributes;
    children: ReactNode;
    editor: Editor;
    element: EmbedElement;
};

const QuoteCaption = (props: Props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);
    const readOnly = ReactEditor.isReadOnly(editor);
    const isTemplateElement =
        Element.isTemplateElement(parentNode) || Element.isEmptyQuoteCaptionElement(element);

    return (
        <>
            <Stack direction="row" justifyContent="flex-end">
                {!isTemplateElement && <Dash contentEditable={false}>{'- '}</Dash>}
                <StyledQuoteCaption
                    {...attributes}
                    $templateElement={isTemplateElement}
                    $placeholderText={`- ${t('editor.plugin.quote.authorPlaceholderText')}`}
                    $readOnly={readOnly}
                >
                    {children}
                </StyledQuoteCaption>
            </Stack>
        </>
    );
};

export default QuoteCaption;
