import { type FilerepoRouter, useFilerepoClient } from '@@api/services/filerepo/client';
import { type FilerepoFile } from '@@api/services/metadata/schemas';
import { getQueryParams } from '@@api/utils/getQueryParams';
import { type IImageTransformationsOutput } from '@@scripts/utils/buildImageTransformationUrl';

export const useFileHead = () => {
    const { client: filerepoClient, queryKeys: filerepoKeys } = useFilerepoClient();
    const queryClient = filerepoClient.useQueryClient();

    return (id: FilerepoFile['id'], queryParams: IImageTransformationsOutput) => {
        const params = { id };
        const query = getQueryParams<FilerepoRouter['files']['head']>(queryParams);

        return queryClient.files.head.fetchQuery({
            queryKey: filerepoKeys.files.head({ params, query }),
            queryData: { params, query },
            staleTime: 0,
        });
    };
};

export type FileHead = ReturnType<typeof useFileHead>;

export default useFileHead;
