import { type Editor, type Node, ReactEditor } from '@@editor/helpers';
import {
    Element,
    ELEMENT_TYPES,
    type EmbedElement,
    type ImageElement,
    type VideocmsElement,
} from '@@editor/helpers/Element';
import getElvisIdByImage from '@@editor/selectors/getElvisIdByImage';
import getVideocmsPreviewImage from '@@editor/selectors/getVideocmsPreviewImage';
import { type PluginOptions } from '@@editor/typings/UnityPlugins';
import makeState from '@@editor/utils/makeState';
import buildImageTransformationUrl, {
    getImageTransformationQueryParams,
} from '@@scripts/utils/buildImageTransformationUrl';

import { uploadFileHelper } from './uploadFileHelper';
import { getImageTransformationArguments } from '../serializable/embed/image/components/PreviewImage';
import { getImageTransformationArguments as getVideocmsPreviewImageTransformationArguments } from '../serializable/embed/videocms/components/VideoCmsPreview';

/*
 * caption gets serialized as it is not serialized on previewImage and maybe on other places as well.
 */

export const withFileUpload = (
    editor: Editor,
    options: RequiredBy<PluginOptions, 'fileHead' | 'uploadFile'>,
): Editor => {
    const { onAfterUpdateElement, onAfterInsertElement } = editor;
    const uploadsInProgress = new Set<string>();

    const { upload: uploadVideoCms, cancelUpload: cancelUploadVideoCms } = uploadFileHelper({
        fileHead: options.fileHead,
        uploadFile: options.uploadFile,
        updateEmbedData: (payload) => ({
            previewImage: {
                url: payload.src,
                elvisId: payload.id,
                caption: makeState(payload.title),
            },
        }),
        buildThumbnailUrl: (block: EmbedElement) => {
            const [baseUrl, transformations] = getVideocmsPreviewImageTransformationArguments(
                block as VideocmsElement,
            );

            const url = buildImageTransformationUrl(baseUrl, transformations);
            const queryParams = getImageTransformationQueryParams(baseUrl, transformations);

            return {
                baseUrl,
                queryParams,
                url,
            };
        },
        updateSource: false,
    });

    const { upload: uploadImage, cancelUpload: cancelUploadImage } = uploadFileHelper({
        fileHead: options.fileHead,
        uploadFile: options.uploadFile,
        updateEmbedData: ({
            src,
            id,
            caption,
            name,
            nameSource,
            credit,
            naturalWidth,
            naturalHeight,
        }) => ({
            url: src,
            elvisId: id,
            caption: makeState(caption),
            name,
            nameSource,
            credit,
            naturalWidth,
            naturalHeight,
        }),
        buildThumbnailUrl: (block: EmbedElement) => {
            const [baseUrl, transformations] = getImageTransformationArguments(
                block as ImageElement,
            );

            const url = buildImageTransformationUrl(baseUrl, transformations);
            const queryParams = getImageTransformationQueryParams(baseUrl, transformations);

            return {
                baseUrl,
                queryParams,
                url,
            };
        },
    });

    const uploadFileByNode = (editor: Editor, node: Node, previousNode?: Node) => {
        const key = ReactEditor.findKey(editor, node);

        switch (node.type) {
            case ELEMENT_TYPES.VIDEOCMS: {
                const previewImage = getVideocmsPreviewImage(node);

                if (
                    !previewImage?.elvisId &&
                    previewImage?.url &&
                    // Do not start upload again, if user pressed ctrl+z
                    editor.history.redos.length === 0
                ) {
                    if (!uploadsInProgress.has(key.id)) {
                        uploadsInProgress.add(key.id);

                        uploadVideoCms(editor, node, previewImage.url)
                            .then(() => {
                                uploadsInProgress.delete(key.id);
                            })
                            .catch((e) => {
                                console.error(e);

                                uploadsInProgress.delete(key.id);
                            });
                    }
                }

                break;
            }

            case ELEMENT_TYPES.IMAGE:
                if (
                    !getElvisIdByImage(node) &&
                    node.data.originalSrc &&
                    // Do not start upload again, if user pressed ctrl+z
                    editor.history.redos.length === 0
                ) {
                    if (!uploadsInProgress.has(key.id)) {
                        uploadsInProgress.add(key.id);

                        uploadImage(editor, node, node.data.originalSrc, previousNode)
                            .then(() => {
                                uploadsInProgress.delete(key.id);
                            })
                            .catch((e) => {
                                console.error(e);

                                uploadsInProgress.delete(key.id);
                            });
                    }
                }

                break;
        }
    };

    return Object.assign(editor, {
        onAfterUpdateElement: (node: Node, previousNode?: Node) => {
            uploadFileByNode(editor, node, previousNode);

            onAfterUpdateElement(node, previousNode);
        },
        onAfterInsertElement: (node: Node) => {
            uploadFileByNode(editor, node);

            onAfterInsertElement(node);
        },
        onAfterRemoveElement: (node: Node) => {
            if (Element.isImageElement(node)) {
                cancelUploadImage(editor, node);
            } else if (node.type === ELEMENT_TYPES.VIDEOCMS) {
                cancelUploadVideoCms(editor, node);
            }
        },
    });
};

export default withFileUpload;
