import { type RouteObject } from 'react-router';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { MetadataHistoryRoute } = await import('@@routes/metadataHistory');

            return { Component: MetadataHistoryRoute };
        },
    },
];
