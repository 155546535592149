import { alpha, styled, type Theme } from '@mui/material';
import { type RenderLeafProps } from 'slate-react';

import { type SpellAdviceType } from '@@api/services/spellChecker/schemas';
import { type Editor } from '@@editor/helpers';

import { type EditorSpellAdvice } from '../types';
import { isSpellAdviceSelected } from '../utils/spellAdvices';

type Props = RenderLeafProps & {
    editor: Editor;
    leaf: RenderLeafProps['leaf'] & {
        spellAdvice: EditorSpellAdvice;
    };
};

const getColor = (palette: Theme['palette'], type: SpellAdviceType) =>
    ({
        SPELLING: palette.error.main,
        GRAMMATICAL: palette.success.main,
        TYPOGRAPHICAL: palette.secondary.main,
        OTHER: palette.warning.main,
    })[type];

const StyledSpellAdviceLeaf = styled('span')<{ $isActive: boolean; $type: SpellAdviceType }>(
    ({ $isActive, $type, theme }) => ({
        textDecoration: 'underline',
        textDecorationColor: getColor(theme.palette, $type),
        textDecorationThickness: theme.borders[1],
        textDecorationStyle: 'wavy',
        textDecorationSkipInk: 'none',
        textUnderlineOffset: '3px',
        backgroundColor: $isActive
            ? alpha(getColor(theme.palette, $type), theme.opacityFactors.low)
            : undefined,
    }),
);

const SpellAdviceLeaf = (props: Props) => {
    const {
        attributes,
        children,
        editor,
        leaf: { spellAdvice },
    } = props;

    const isSelected = isSpellAdviceSelected(editor, spellAdvice);

    return (
        <StyledSpellAdviceLeaf
            {...attributes}
            $isActive={isSelected}
            $type={spellAdvice.type}
            aria-haspopup="dialog"
            role="mark"
        >
            {children}
        </StyledSpellAdviceLeaf>
    );
};

export default SpellAdviceLeaf;
