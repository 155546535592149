import { type ReactNode } from 'react';

import { HOTKEYS } from '@@editor/constants';
import { Editor } from '@@editor/helpers';
import handleHotkey from '@@editor/plugins/utils/handleHotkey';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';
import { isPluginInvalid } from '@@editor/toolbars/utils/getToolbarConfig';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

const MARK = 'superscript';

type Props = {
    attributes: UnknownObject;
    children: ReactNode;
};

const SuperscriptMark = (props: Props) => <sup {...props.attributes}>{props.children}</sup>;

export const withSuperscript = (editor: Editor) => {
    const { toggleMark } = editor;

    return Object.assign(editor, {
        renderLeaf: renderLeaf(editor, [[MARK, SuperscriptMark]]),
        handleHotkey: handleHotkey(editor, [
            [
                HOTKEYS.SUPERSCRIPT,
                (editor: Editor) => {
                    if (!isPluginInvalid(editor, PLUGIN_NAMES.SUPERSCRIPT)) {
                        editor.toggleMark(MARK);
                    }
                },
            ],
        ]),
        toggleMark: (mark, single) => {
            // If user applies any other mark, remove this one (subscript) first
            if (Editor.isMarkActive(editor, MARK) && mark !== MARK) {
                editor.removeMark(MARK);
            }

            // Toggle mark. If it is subscript, remove any other first
            toggleMark(mark, mark === MARK ? true : single);
        },
    });
};

export default withSuperscript;
