import { type Theme } from '@mui/material';
import { merge } from 'lodash-es';

const theme = (parentTheme: Theme) =>
    merge({}, parentTheme, {
        fixed: {
            editor: {
                padding: `${parentTheme.spacing(2)} ${parentTheme.spacing(1)}`,
                textElement: {
                    width: '686px',
                },
                stickyToolbar: {
                    crossheadButton: {
                        font: parentTheme.typography.editorSecondarySmall,
                    },
                    footerButton: {
                        font: parentTheme.typography.editorSubtitleXsmall,
                    },
                },
                embed: {
                    caption: {
                        font: parentTheme.typography.editorSubtitleSmall,
                    },
                    credit: {
                        font: parentTheme.typography.editorSubtitleXsmall,
                    },
                },
                dynamicTeaser: {
                    titleHeader: {
                        font: parentTheme.typography.editorPrimarySmall,
                    },
                    title: {
                        font: parentTheme.typography.editorSecondaryMedium,
                    },
                },
                infobox: {
                    title: {
                        font: parentTheme.typography.editorSubtitleLarge,
                    },
                    content: {
                        font: parentTheme.typography.editorSecondaryMedium,
                    },
                },
                interviewSegment: {
                    question: {
                        font: parentTheme.typography.editorSubtitleLarge,
                    },
                    answer: {
                        font: parentTheme.typography.editorPrimaryMedium,
                    },
                },
                list: {
                    font: parentTheme.typography.editorPrimaryLarge,
                },
                paragraph: {
                    font: parentTheme.typography.editorPrimaryLarge,
                    crosshead: {
                        font: parentTheme.typography.editorSecondaryLarge,
                    },
                    footer: {
                        font: parentTheme.typography.editorSubtitleSmall,
                    },
                    lead: {
                        font: parentTheme.typography.editorPrimaryXlarge,
                    },
                    subsection: {
                        font: parentTheme.typography.editorSecondaryMedium,
                    },
                    template: {
                        font: parentTheme.typography.editorPrimaryLarge,
                    },
                    title: {
                        font: parentTheme.typography.editorSecondaryXlarge,
                    },
                    titleHeader: {
                        font: parentTheme.typography.editorSubtitleSmall,
                    },
                },
                poll: {
                    question: {
                        font: parentTheme.typography.editorSubtitleLarge,
                    },
                    answer: {
                        font: parentTheme.typography.editorSubtitleMedium,
                    },
                },
                quote: {
                    caption: {
                        font: parentTheme.typography.editorPrimaryXlarge,
                    },
                    dash: {
                        font: parentTheme.typography.editorPrimaryXlarge,
                    },
                    text: {
                        font: parentTheme.typography.editorSecondaryXlarge,
                    },
                },
                separator: {
                    width: `calc(100% - 2 * ${parentTheme.fixed.editor.elementWrapper.width})`,
                },
                slideshow: {
                    caption: {
                        font: parentTheme.typography.editorSubtitleSmall,
                    },
                    credit: {
                        font: parentTheme.typography.editorSubtitleXsmall,
                    },
                },
                summary: {
                    font: parentTheme.typography.editorPrimaryLarge,
                },
            },
        },
    });

export default theme;
