import { useTheme } from '@mui/material';
import { useFocused, useSlateStatic } from 'slate-react';

import { type Editor } from '@@editor/helpers';

import { DEFAULT_STICKYTOOLBAR_BUTTONS } from '../constants';
import LargeToolbar from './LargeToolbar';
import Toolbar from './Toolbar';
import { getToolbarConfig } from '../utils/getToolbarConfig';

type Props = {
    activePlugins?: Editor['stickyToolbarButtons'];
    size?: 'medium' | 'large';
    readOnly?: boolean;
    targetTextLength?: number | null;
};

export const StickyToolbar = ({
    activePlugins = DEFAULT_STICKYTOOLBAR_BUTTONS,
    size = 'medium',
    readOnly,
    targetTextLength,
}: Props) => {
    const theme = useTheme();
    const editor = useSlateStatic();
    const isFocused = useFocused();
    const buttonsConfig = getToolbarConfig(editor, activePlugins, theme);

    if (size === 'large') {
        return (
            <LargeToolbar
                buttonsConfig={buttonsConfig}
                disabled={editor.disabled || !isFocused}
                readOnly={readOnly}
                targetTextLength={targetTextLength}
            />
        );
    }

    return <Toolbar buttonsConfig={buttonsConfig} disabled={editor.disabled} />;
};
