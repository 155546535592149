import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useAuthClient } from '@@api/services/auth/client';
import { getUserId } from '@@auth/authSlice';
import { PATCH_CONTENT_TYPES } from '@@constants/http';
import ContentLocaleRadioGroup from '@@containers/ContentLocaleRadioGroup';
import useSavedFiltersSearchParams from '@@containers/Search/useSavedFiltersSearchParams';
import usePrevious from '@@hooks/usePrevious';
import { type ContentLanguage } from '@@lib/i18n/constants';
import { getContentLocaleSetting, setSetting } from '@@settings/settingsSlice';
import { useDispatch, useSelector } from '@@store/hooks';

import { SEARCH_NAMESPACES } from './types';

export type ContentLocaleRadioGroupSearchProps = {
    filters?: URLSearchParams;
};

const ContentLocaleRadioGroupSearch = ({ filters }: ContentLocaleRadioGroupSearchProps) => {
    const { client: authClient } = useAuthClient();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const userId = useSelector(getUserId) as string;
    const contentLocale = useSelector(getContentLocaleSetting);
    const prevContentLocale = usePrevious(contentLocale);
    const savedFilters = useSavedFiltersSearchParams(SEARCH_NAMESPACES.DEFAULT);
    const searchFilters = filters ?? savedFilters;

    const { mutate } = authClient.users.preference.patch.useMutation({
        onSuccess: (data) => {
            const { body } = data;

            dispatch(setSetting({ path: 'contentLocale', value: body.contentLocale }));

            queryClient.invalidateQueries();
        },
    });

    const handleChangeContentLocale = (value: ContentLanguage) => {
        if (value) {
            mutate({
                params: { id: userId },
                body: { contentLocale: value },
                headers: { 'content-type': PATCH_CONTENT_TYPES.MERGE_PATCH },
            });
        }
    };

    useEffect(() => {
        if (prevContentLocale && contentLocale !== prevContentLocale) {
            navigate(`${location.pathname}?${searchFilters.toString()}`);
        }
    }, [contentLocale, prevContentLocale, location.pathname, navigate, searchFilters]);

    return (
        <ContentLocaleRadioGroup
            value={contentLocale}
            onChange={(event, value) => handleChangeContentLocale(value)}
        />
    );
};

export default ContentLocaleRadioGroupSearch;
