import { createTheme, IconButton, styled, type Theme, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transforms } from 'slate';

import Icon from '@@components/Icon';
import { ContentItemButtonGroup } from '@@containers/ContentItem/styles';
import { type Editor, ReactEditor } from '@@editor/helpers';
import { Element, type LinkElement } from '@@editor/helpers/Element';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import toText from '@@editor/utils/toText';

import AuthorPreview from './LinkPreview/AuthorPreview';
import CategoryPreview from './LinkPreview/CategoryPreview';
import ContentPreview from './LinkPreview/ContentPreview';
import ExternalLinkPreview from './LinkPreview/ExternalLinkPreview';
import TagPreview from './LinkPreview/TagPreview';

const ButtonsWrapper = styled(ContentItemButtonGroup)(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    right: theme.spacing(2),
    bottom: theme.spacing(1),
}));

const LinkDetailsWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary['600'],
    width: theme.fixed.contentItem.popper.width,
    textAlign: 'left',
}));

const generateTheme = (theme: Theme) =>
    createTheme(theme, {
        palette: {
            text: {
                primary: theme.palette.common.white,
                secondary: theme.palette.primary['300'],
            },
        },
        fixed: {
            contentItem: {
                buttonGroup: {
                    backgroundColor: theme.palette.primary['600'],
                    borderColor: theme.palette.primary['700'],
                },
            },
        },
    });

type Props = {
    element: LinkElement;
    editor: Editor;
};

const LinkDetails: React.FC<Props> = ({ element, editor }) => {
    const { t } = useTranslation();

    const onEdit = () => {
        const linkText = toText(element);

        const path = ReactEditor.findPath(editor, element);

        const range = {
            anchor: { path: [...path, 0], offset: linkText.length },
            focus: { path: [...path, 0], offset: 0 },
        };

        Transforms.select(editor, range);

        editor.showLinkModal();
    };

    const onUnlink = () => {
        editor.removeLink();
    };

    const renderLinkPreview = (element: LinkElement) => {
        if (Element.isInternalLinkElement(element)) {
            return <ContentPreview metadataId={element.data.metadataId} />;
        }

        if (Element.isExternalLinkElement(element)) {
            return <ExternalLinkPreview url={element.data.href} />;
        }

        if (Element.isCategoryLinkElement(element)) {
            return <CategoryPreview id={element.data.categoryId} />;
        }

        if (Element.isTagLinkElement(element)) {
            return <TagPreview id={element.data.tagId} />;
        }

        if (Element.isAuthorLinkElement(element)) {
            return <AuthorPreview id={element.data.authorId} />;
        }
    };

    return (
        <ThemeProvider theme={generateTheme}>
            <LinkDetailsWrapper contentEditable={false}>
                {renderLinkPreview(element)}

                <ButtonsWrapper>
                    <IconButton size="small" title={t('contentitem.editButton')} onClick={onEdit}>
                        <Icon name="pen-regular" size="small" color="white" />
                    </IconButton>

                    <IconButton size="small" title={t('editor.remove.link')} onClick={onUnlink}>
                        <Icon
                            name={PLUGIN_ICON_NAMES[PLUGIN_NAMES.LINK][1]}
                            size="small"
                            color="white"
                        />
                    </IconButton>
                </ButtonsWrapper>
            </LinkDetailsWrapper>
        </ThemeProvider>
    );
};

export default LinkDetails;
