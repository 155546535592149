import { useTranslation } from 'react-i18next';

import { useMetadataClient } from '@@api/services/metadata/client';
import { type Tag } from '@@api/services/metadata/schemas';

import BasicLinkPreview from './BasicLinkPreview';

type Props = {
    id: Tag['id'];
};

const TagPreview: React.FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();
    const params = { id };

    const queryKey = metadataKeys.tags.get({ params });

    const { data, isLoading } = metadataClient.tags.get.useQuery({
        queryKey,
        queryData: { params },
        enabled: Boolean(id),
    });

    if (!id) {
        return null;
    }

    const tag = data?.body;

    return (
        <BasicLinkPreview
            isLoading={isLoading}
            title={tag?.name}
            iconName="tags"
            label={t('link.tagLink.title')}
        />
    );
};

export default TagPreview;
