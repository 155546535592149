import { styled } from '@mui/material';
import { type CSSProperties, forwardRef, type PropsWithChildren } from 'react';

import { type Editor, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type FooterElement } from '@@editor/helpers/Element';

export const FooterWrapper = styled('div')(({ theme }) => ({
    ...theme.fixed.editor.paragraph.footer.font,
}));

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element?: FooterElement;
    style?: CSSProperties;
}>;

const Footer = forwardRef<HTMLElement, Props>(({ attributes, children, editor, style }, ref) => {
    const readOnly = ReactEditor.isReadOnly(editor);

    return <FooterWrapper {...{ ref, style, ...attributes, readOnly }}>{children}</FooterWrapper>;
});

export default Footer;
